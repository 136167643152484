import React from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {
	LifeSkillPageContainer,
	LifeSkillsTitle,
	LifeSkillsWrapperElements,
	LifeSkillsWrapperElementsAddOne,
	StyledGrid
} from './LifeSkill.style';
import {Loader, Search} from '../../components';
import {LifeSkillDataMock} from '../../test/mock/lifeSkillDataMock';
import {PencilIcon} from '../../icons';


export const LifeSkill=()=>{
	const history = useHistory();

	const request = () => {
		let aux;
		aux = {
			variables: { limit: 1000 },
			nextFetchPolicy: 'network-only'
		};
		return useQuery(query('lifeSkillsFindMany'), aux);
	};

	const { loading, error, data } = request();

	const sortedData = data ? data.LifeSkillFindMany : LifeSkillDataMock;

	if (loading) return <div><Loader/></div>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	return (
		<LifeSkillPageContainer className={'m-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<LifeSkillsTitle className={'font-sans'}>Life Skills</LifeSkillsTitle>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<LifeSkillsWrapperElementsAddOne
					className={'flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer'}
					onClick={() => history.push('/lifeSkills/create')}>
					<div className={'flex gap-3 align-center justify-center'}>
						<span className={'font-sans text-base f-600 text-white'}>Add Life Skill</span>
					</div>
					<div className={'flex gap-3 align-center justify-center'}>
						<div className={'flex align-center justify-center'}>
							<span className={'font-sans f-600'}>+</span>
						</div>
					</div>
				</LifeSkillsWrapperElementsAddOne>
				{sortedData.map(({_id, label, icon, description}) => (
					<LifeSkillsWrapperElements
						key={_id}
						className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
						onClick={() => history.push(`/lifeSkills/${_id}`)}>
						<div className={'flex justify-start'}>
							<div className={'grid'}>
								<span className={'font-sans text-base f-600 text-white'}>{label}</span>
								<p className={'font-mono text-sm truncate overflow-ellipsis'}
								   style={{color: 'rgba(174, 185, 225, 1)'}}
								>{description ? description : 'No description yet'}</p>
							</div>
						</div>
						<div
							className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<PencilIcon className={'ml-auto'}/>
						</div>
					</LifeSkillsWrapperElements>
				))}
			</StyledGrid>
		</LifeSkillPageContainer>
	);
};