import PropTypes from 'prop-types';
import {ImageIcon} from '../../../../icons';
import Input from '../../../../components/ui/Input';
import {CKEditorInput, TagsSelector, TextArea} from '../../../../components';
import {CurriculumFindMany} from '../../../../hoc/CurriculumFindMany';
import Select from '../../../../components/ui/Select';
import {LifeSkillFindMany, SkillSetFindMany, ThemesFindMany} from '../../../../hoc';
import {lessonShape} from '../../types/LessonInfoTypes';

export const  InfoRender = ({
	formState, isSubmitLoading, handleSubmit, getImageSrc, isChanged,
	setFormState, handleFileChange, handleChange,
	handleThemesChange, selectedLifeSkills, handleSkillSetsChange,
	selectedThemes, selectedSkillSets, handleLifeSkillsChange, isLoading,
	handleArrayChange, handleAddArrayItem, handleRemoveArrayItem
}) => {
	return (
		<form className={'w-100 flex direction-row justify-start align-center gap-7 p-5 rounded-lg'}
			  onSubmit={handleSubmit}
			  style={{backgroundColor: 'rgba(11, 23, 57, 1)',
				  border:'1px solid rgba(52, 59, 79, 1)'}}>
			<div className={'w-100'}>
				<div className={'grid grid-cols-2 gap-2'}>
					<div className={'grow flex justify-center'}>
						<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
							<label htmlFor='file-upload' className={'w-45 h-45 cursor-pointer flex align-center justify-center direction-column'}>
								<div
									className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
									<div className={'flex justify-center align-center w-44 h-44'}>
										{getImageSrc() ? (
											<img src={getImageSrc()} alt='Preview'
												 className={'rounded-full w-44 h-44 object-cover'}/>
										) : (
											<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
										)}
									</div>
								</div>
								<span
									className={'font-sans mt-3 ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}
								>Upload Image</span>
								<input
									id='file-upload'
									type='file'
									accept='image/*'
									onChange={handleFileChange}
									className={'hidden'}
								/>
							</label>
						</div>

					</div>
					<div className={'grow'}>
						<Input
							label={'Title'}
							type='text'
							name='title'
							value={formState.title}
							onChange={handleChange}
						/>

						<TextArea
							name='description'
							label={'Description'}
							value={formState.description}
							onChange={handleChange}
						/>

					</div>

					<CurriculumFindMany>
						{curriculum => (
							<Select
								label={'Curriculum'}
								name='curriculum'
								value={formState.curriculum}
								onChange={handleChange}
								options={curriculum.map((c)=> ({label:c.title,value:c._id}) )}
							/>
						)}
					</CurriculumFindMany>

					<Input
						label={'Order'}
						type='number'
						name='order'
						value={formState.order}
						onChange={handleChange}
						min='0'
					/>

					<Input
						label={'time'}
						type='number'
						name='episodeTime'
						value={formState.episodeTime}
						onChange={handleChange}
						min='0'
					/>

					<Input
						label={'Quiz Time'}
						type='number'
						name='quizTime'
						value={formState.quizTime}
						onChange={handleChange}
						min='0'
					/>
					<Input
						label={'Journal Time'}
						type='number'
						name='journalTime'
						value={formState.journalTime}
						onChange={handleChange}
						min='0'
					/>

					<Input
						label={'Class Activity Time'}
						type='number'
						name='classActivityTime'
						value={formState.classActivityTime}
						onChange={handleChange}
						min='0'
					/>

					<Input
						label ='Tap Library Time'
						type='number'
						name='tapLibraryTime'
						value={formState.tapLibraryTime}
						onChange={handleChange}
						min='0'
					/>

					<Input
						type='number'
						label={'Extend Time'}
						name='extendTime'
						value={formState.extendTime}
						onChange={handleChange}
						min='0'
					/>

					<Input
						label ='Lesson Length'
						type='text'
						name='lessonLength'
						value={formState.lessonLength}
						onChange={handleChange}
					/>

					<TextArea
						label ='Overview'
						name='overview'
						value={formState.overview}
						onChange={handleChange}
					/>

					<TextArea
						name='extraActivities'
						label='Extra Activities'
						value={formState.extraActivities}
						onChange={handleChange}
					/>

					<TextArea
						label = 'Big Idea'
						name='bigIdea'
						value={formState.bigIdea}
						onChange={handleChange}
					/>
				</div>
				<div className={'w-100 mb-3'}>
					<TextArea
						label = 'Learning Goal'
						type='text'
						name='learningGoal'
						value={formState.learningGoal}
						onChange={handleChange}
					/>
				</div>

				{['discussion', 'extend', 'reflection', 'activity'].map((arrayName) => (
					<div key={arrayName} className={'w-100'}>
						<span className={'font-mono f-600 text-text-light dark:text-text-dark mb-3'}>{arrayName.toUpperCase()}</span>
						{(formState[arrayName].length === 0 ? [{ title: '', description: '' }] : formState[arrayName]).map((item, index) => (
							<div key={index} className={'mb-4'}>
								<Input
									label={arrayName.charAt(0).toUpperCase() + arrayName.slice(1) + ' Title'}
									type='text'
									value={item.title}
									onChange={(e) => handleArrayChange(arrayName, index, 'title', e.target.value)}
									className={'font-mono mb-3 w-100 p-5 text-text-light dark:text-text-dark rounded-lg'}
								/>
								{arrayName === 'reflection' ? (
									<CKEditorInput
										label={arrayName.charAt(0).toUpperCase() + arrayName.slice(1) + ' Description'}
										value={item.description || ''}
										name={`reflection-${index}`}
										onChange={(data) => handleArrayChange(arrayName, index, 'description', data.target.value)}
									/>
								) : (
									<TextArea
										label={arrayName.charAt(0).toUpperCase() + arrayName.slice(1) + ' Description'}
										value={item.description || ''}
										onChange={(e) => handleArrayChange(arrayName, index, 'description', e.target.value)}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark rounded-lg'}
									/>
								)}
								<div className={'flex gap-2'}>
									<button
										type='button'
										onClick={() => handleRemoveArrayItem(arrayName, index)}
										className={'font-mono bg-red-500 text-white rounded-full px-2'}
										style={{width: '1.6rem', height: '1.6rem'}}
									>
										X
									</button>
									{index === formState[arrayName].length - 1 && (
										<button
											type='button'
											onClick={() => handleAddArrayItem(arrayName)}
											className={'font-mono px-4 py-2 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}
										>
											Add {arrayName.charAt(0).toUpperCase() + arrayName.slice(1)}
										</button>
									)}
								</div>
							</div>
						))}
					</div>
				))}

				<TagsSelector
					selectedTags={selectedLifeSkills}
					setSelectedTags={handleLifeSkillsChange}
					HOC={LifeSkillFindMany}
					label='Life Skills'
				/>
				<TagsSelector
					selectedTags={selectedSkillSets}
					setSelectedTags={handleSkillSetsChange}
					HOC={SkillSetFindMany}
					label='Skill Sets'
				/>
				<TagsSelector
					selectedTags={selectedThemes}
					setSelectedTags={handleThemesChange}
					HOC={ThemesFindMany}
					label='Themes'
				/>
				<div className={'flex align-middle justify-center center'}>
					<button
						onClick={handleSubmit}
						className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
							!isChanged || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
						disabled={!isChanged || isLoading}
					>
						{isSubmitLoading ? 'Loading...' : 'Update'}
					</button>
				</div>
			</div>
		</form>
	);
};

InfoRender.propTypes = {
	formState: lessonShape,
	isSubmitLoading: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	getImageSrc: PropTypes.func.isRequired,
	isChanged: PropTypes.bool.isRequired,
	setFormState: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleThemesChange: PropTypes.func.isRequired,
	handleLifeSkillsChange: PropTypes.func.isRequired,
	handleSkillSetsChange: PropTypes.func.isRequired,
	selectedLifeSkills: PropTypes.array.isRequired,
	selectedThemes: PropTypes.array.isRequired,
	selectedSkillSets: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	handleArrayChange: PropTypes.func.isRequired,
	handleAddArrayItem: PropTypes.func.isRequired,
	handleRemoveArrayItem: PropTypes.func.isRequired
};