import PropTypes from 'prop-types';
import Button from '../../components/ui/Button';
import React from 'react';
import {
	StyledTableRow,
	UserCounterTitleTable,
	UserPageWrapperElements,
	UserTableMainWrapper
} from '../Users/Users.style';
import {format} from 'date-fns';
import Modal from '../../components/ui/Modal/Modal';
import {ReportsFormLoad} from './forms/ReportsForm.load';
import {SelectDropDown} from '../../components';
import {ArrowIndicatorIcon} from '../../icons';

export const ReportsRender = ({
								  reportsData,
								  skip,
								  limit,
								  totalReports,
								  handleEditClick,
								  handleCreateClick,
								  setSort,
								  sort,
								  showCreateReportForm,
								  setShowCreateReportForm,
								  showUpdateReportForm,
								  setShowUpdateReportForm,
								  currentReport,
	setLimit, onNextPage, onPreviousPage, currentPage, totalPages, refetch
							  }) => {
	return(
		<div className='w-full h-full m-2'>
			{showUpdateReportForm && (
				<Modal
					isOpen={showUpdateReportForm}
					setIsOpen={setShowUpdateReportForm}
				>
					<ReportsFormLoad
						onSuccess={() => setShowUpdateReportForm(false)}
						currentReport={currentReport} refetch={refetch}
					/>
				</Modal>
			)}
			{showCreateReportForm && (
				<Modal
					isOpen={showCreateReportForm}
					setIsOpen={setShowCreateReportForm}
				>
					<ReportsFormLoad onSuccess={() => setShowCreateReportForm(false)} refetch={refetch}/>
				</Modal>
			)}
			<div className='w-full flex justify-between items-center p-1 mb-3 gap-6'>
				<h1 className='font-sans text-white f-500 text-2xl text-start'>Training Reports</h1>
				<Button
					type={'button'}
					className={'font-bold text-sm border-2 bg-brightLilac-default'}
					onClick={handleCreateClick}
				> Add a new Report +</Button>
			</div>
			<div className='flex justify-between items-center mb-6 p-2.5 pr-4 pl-4'>
				<UserCounterTitleTable className={'font-mono'}>
					<span>{skip + 1} - {Math.min(skip + limit, reportsData.length) + skip} </span>
					<span>of {totalReports}</span>
				</UserCounterTitleTable>
				<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
					<div
						className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
						<span className={'font-sans mb-1'}>Sort by Date</span>
						<select
							className={'font-sans f-500 border rounded ml-4 p-2'}
							style={{
								backgroundColor: 'rgba(10, 19, 48, 1)',
								border: '1px solid rgba(11, 23, 57, 1)',
							}}
							value={sort}
							onChange={(e) => setSort(e.target.value)}
						>
							<option value='DATE_ASC'>Oldest First</option>
							<option value='DATE_DESC'>Newest First</option>
						</select>
					</div>
				</div>
			</div>
			<UserPageWrapperElements>
				{reportsData.length > 0 ? (
					<div className='overflow-auto rounded-lg shadow-md'>
						<UserTableMainWrapper className='w-full table-auto border-collapse border border-gray-800'>
							<thead>
								<tr>
									<th className='left font-mono main-color-background p-2 t-white'>Body</th>
									<th className='left font-mono main-color-background p-2 t-white'>Scale</th>
									<th className='left font-mono main-color-background p-2 t-white'>Created At</th>
									<th className='left font-mono main-color-background p-2 t-white'>Updated At</th>
								</tr>
							</thead>
							<tbody>
								{reportsData.map((reports) => (
									<StyledTableRow index={reports._id}
										key={reports._id} className={'cursor-pointer'}
										onClick={() => handleEditClick(reports)}>
										<td className='justify-start items-center text-start p-4 overflow-ellipsis'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{reports?.body ? reports.body : 'No body yet'}
											</span>
										</td>
										<td className='justify-start items-center text-center p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{reports?.scale ? reports.scale : '0'}
											</span>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{reports.createdAt ? format(new Date(reports.createdAt), 'dd/MM/yyyy') : 'Date not set'}
											</span>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{reports.updatedAt ? format(new Date(reports.updatedAt), 'dd/MM/yyyy') : 'Date not set'}
											</span>
										</td>
									</StyledTableRow>
								))}
							</tbody>
						</UserTableMainWrapper>
					</div>
				) : (
					<div className='flex justify-center items-center text-white font-mono text-lg p-4'>
						No Schools Codes Found.
					</div>
				)}
			</UserPageWrapperElements>
			<div className={'flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'}>
				<div className={'font-mono'}>
					<UserCounterTitleTable className={'font-mono'}>
						<span>{skip + 1} - {Math.min(skip + limit, reportsData.length) + skip} </span>
						<span>of {totalReports}</span>
					</UserCounterTitleTable>
					<SelectDropDown minValue={limit} onChange={setLimit}/>
				</div>
				<div className={'flex items-center gap-4'}>
					<button
						className={`flex gap-2 py-2 rounded text-[0.625rem] font-medium px-2 ${
							skip === 0 ? 'bg-gray-700 cursor-not-allowed' : 'bg-brightLilac-default hover:bg-brightLilac-hover'
						}`}
						onClick={onPreviousPage}
						disabled={skip === 0}
					>
						<ArrowIndicatorIcon className={'transform rotate-180'}/> Previous
					</button>
					<span className='text-white'>
						Page {currentPage} of {totalPages}
					</span>
					<button
						className={`flex gap-2 py-2 rounded text-[0.625rem] font-medium px-2 ${
							skip + limit >= totalReports ? 'bg-gray-700 cursor-not-allowed' : 'bg-brightLilac-default hover:bg-brightLilac-hover'
						}`}
						onClick={onNextPage}
						disabled={skip + limit >= totalReports}
					>
						Next <ArrowIndicatorIcon/>
					</button>
				</div>
			</div>
		</div>
	);
};

ReportsRender.propTypes = {
	reportsData: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			body: PropTypes.string,
			scale: PropTypes.number,
			deleted: PropTypes.bool,
			createdAt: PropTypes.string,
			updatedAt: PropTypes.string,
		})
	).isRequired,
	totalReports: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired,
	handleEditClick: PropTypes.func.isRequired,
	handleCreateClick: PropTypes.func.isRequired,
	setSort: PropTypes.func.isRequired,
	sort: PropTypes.string.isRequired,
	showCreateReportForm: PropTypes.bool.isRequired,
	setShowCreateReportForm: PropTypes.func.isRequired,
	showUpdateReportForm: PropTypes.bool.isRequired,
	setShowUpdateReportForm: PropTypes.func.isRequired,
	currentReport: PropTypes.shape({
		_id: PropTypes.string,
		body: PropTypes.string,
		scale: PropTypes.number,
		deleted: PropTypes.bool,
		createdAt: PropTypes.string,
		updatedAt: PropTypes.string,
	}),
	setLimit: PropTypes.func.isRequired,
	onNextPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	totalPages: PropTypes.number,
	currentPage: PropTypes.number,
	refetch: PropTypes.func.isRequired,
};