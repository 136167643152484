import {CurriculumFindMany} from '../../../hoc/CurriculumFindMany';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

export const FindCurriculumsInSelector = ({ selectedCurriculums, onAdd, onRemove }) => {
	const [localSelectedCurriculums, setLocalSelectedCurriculums] = useState(selectedCurriculums);

	useEffect(() => {
		setLocalSelectedCurriculums(selectedCurriculums);
	}, [selectedCurriculums]);

	const handleAddCurriculum = (curriculumId) => {
		setLocalSelectedCurriculums((prev) => [...prev, curriculumId]);
		onAdd(curriculumId);
	};

	const handleRemoveCurriculum = (curriculumId) => {
		setLocalSelectedCurriculums((prev) => prev.filter((id) => id !== curriculumId));
		onRemove(curriculumId);
	};

	return (
		<CurriculumFindMany>
			{(curriculums) => (
				<div className='flex flex-col w-full'>
					<h4 className='font-sans text-text-light dark:text-text-dark mb-2 mt-5 text-base font-medium'>
						Available Courses
					</h4>
					<div className='flex flex-wrap gap-2'>
						{curriculums.map((curriculum) => {
							const isSelected = localSelectedCurriculums.includes(curriculum._id);
							return (
								<button
									key={curriculum._id}
									type={'button'}
									onClick={() =>
										isSelected ? handleRemoveCurriculum(curriculum._id) : handleAddCurriculum(curriculum._id)
									}
									className={`px-4 py-2 rounded ${isSelected ? 'bg-green-500 text-white' : 'bg-gray-200 text-black'}`}
								>
									{curriculum.title}
								</button>
							);
						})}
					</div>
					<h4 className='font-sans text-text-light dark:text-text-dark mb-2 mt-5 text-base font-medium'>
						Selected Courses
					</h4>
					<div className='flex flex-wrap gap-2'>
						{localSelectedCurriculums.length === 0 ? (
							<span className='px-4 py-2 bg-gray-300 text-black rounded'>
								All Courses
							</span>
						) : (
							localSelectedCurriculums.map((id) => {
								const curriculum = curriculums.find((c) => c._id === id);
								return (
									<span
										key={id}
										className='px-4 py-2 bg-blue-500 text-white rounded cursor-pointer'
										onClick={() => handleRemoveCurriculum(id)}
									>
										{curriculum?.title || 'Unknown'} ✕
									</span>
								);
							})
						)}
					</div>
				</div>
			)}
		</CurriculumFindMany>
	);
};