import {PencilIcon} from '../../../../icons';
import {StyledTableRow} from '../../../Courses/Courses.style';
import React, {useState} from 'react';
import {VideoQuestions} from '../VideoQuestions';
import Modal from '../../../../components/ui/Modal/Modal';
import {ArticleQuestions} from '../ArticleQuestions';
import {FunFact} from '../FunFact';
import {Journals} from '../Journal';
import {MindfulMoment} from '../MindfulMoment';
import PropTypes from 'prop-types';
import {EditDeleteActions} from '../../../../components/layout';
import {VideoForm} from '../../forms';

export const SparkLibraryRow =({sparkL,index, handleEdit,handleDelete, refetch})=>{
	const [showVideoModal,setShowVideoModal]= useState(false);
	const [showVideoQuestionsModal,setShowVideoQuestionsModal]= useState(false);
	const [showArticleModal,setShowArticleModal]= useState(false);
	const [showJournal,setShowJournal]= useState(false);
	const [showMindfulMomentModal,setShoMindfulMomentModal]= useState(false);
	const [showFunFactModal,setShowFunFactModal]= useState(false);
	return (
		<>
			<StyledTableRow
				className={'cursor-pointer'}
				index={index}
			>
				<td>
					<div className={'flex gap-2 pl-4'}>
						{sparkL.cover?.url ?
							<img
								className={'w-[1rem] aspect-square'}
								alt={`cover for ${sparkL.id} spark library`}
								src={sparkL.cover?.url}
							/>
							: null
						}
					</div>
				</td>
				<td className={'p-2 text-start'}>{sparkL.title}</td>
				<td className={'p-2 text-start'}>
					<div
						className='max-h-24 overflow-hidden text-ellipsis line-clamp-3'
						dangerouslySetInnerHTML={{__html: sparkL?.description ? sparkL.description : 'No description yet'}}
					/>
				</td>
				<td className={'p-2 text-start'}>
					{sparkL.slug}
				</td>
				{/*video*/}
				<td>
					<div className={'flex gap-2 pl-4'}
						 onClick={() => setShowVideoModal(true)}
					>
						{sparkL.video?.thumbnail?.url ?
							<img
								className={'w-[1rem] aspect-square'}
								alt={`cover for ${sparkL.id} spark library`}
								src={sparkL.video?.thumbnail?.url}
							/>
							: null
						}
						<PencilIcon/>
					</div>
				</td>
				<td>
					<button
						className={'flex w-full justify-center gap-2 w-22 p-1 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}
						onClick={() => setShowVideoQuestionsModal(true)}
					>
						{sparkL.videoQuestions.length} <PencilIcon/>
					</button>
				</td>
				{/*article*/}
				<td>
					<button
						className={'flex w-full justify-center gap-2 w-22 p-1 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}
						onClick={() => setShowArticleModal(true)}
					>
						{sparkL.articleQuestion.length} <PencilIcon/>
					</button>
				</td>

				{/*fun fact*/}
				<td>
					<button
						className={'flex w-full justify-center gap-2 w-22 p-1 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}
						onClick={() => setShowFunFactModal(true)}
					>
						{sparkL.funFact.length} <PencilIcon/>
					</button>
				</td>
				{/*journals*/}
				<td>
					<button
						className={'flex w-full justify-center gap-2 w-22 p-1 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}
						onClick={() => setShowJournal(true)}
					>
						{sparkL.journals?.length || 0} <PencilIcon/>
					</button>
				</td>
				{/*Mindful moments*/}
				<td>
					<button
						className={'flex w-full justify-center gap-2 w-22 p-1 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}
						onClick={() => setShoMindfulMomentModal(true)}
					>
						{sparkL.mindfulMoment?.length || 0} <PencilIcon/>
					</button>
				</td>
				<td>
					<EditDeleteActions
						onEdit={() => handleEdit(sparkL)}
						onDelete={() => handleDelete(sparkL)}
					/>
				</td>
			</StyledTableRow>
			<Modal isOpen={showVideoModal} setIsOpen={setShowVideoModal}>
				<VideoForm
					sparkL={sparkL}
					setIsOpen={setShowVideoQuestionsModal}
					refetch={refetch}
				/>
			</Modal>
			<Modal isOpen={showVideoQuestionsModal} setIsOpen={setShowVideoQuestionsModal}>
				<VideoQuestions
					sparkL={sparkL}
					setIsOpen={setShowVideoModal}
					refetch={refetch}
				/>
			</Modal>
			<Modal isOpen={showArticleModal} setIsOpen={setShowArticleModal}>
				<ArticleQuestions
					sparkL={sparkL}
					setIsOpen={setShowArticleModal}
				/>
			</Modal>

			<Modal isOpen={showFunFactModal} setIsOpen={setShowFunFactModal}>
				<FunFact
					sparkL={sparkL}
					setIsOpen={setShowFunFactModal}
				/>
			</Modal>

			<Modal isOpen={showJournal} setIsOpen={setShowJournal}>
				<Journals
					sparkL={sparkL}
					setIsOpen={setShowJournal}
				/>
			</Modal>

			<Modal isOpen={showMindfulMomentModal} setIsOpen={setShoMindfulMomentModal}>
				<MindfulMoment
					sparkL={sparkL}
					setIsOpen={setShowJournal}
				/>
			</Modal>
		</>
	);
};

SparkLibraryRow.propTypes ={
	sparkL: PropTypes.shape({
		_id:PropTypes.string.isRequired,
		videoQuestions:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
		articleQuestion:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
		funFact:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
		journals:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
		mindfulMoment:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};