import React, {useState} from 'react';
import {VideoTapsViewRender} from './VideoTapsView.render';
import PropTypes from 'prop-types';

export const VideoTapsViewLoad = ({ lesson }) => {

	return (
		<VideoTapsViewRender lesson={lesson}/>
	);
};

VideoTapsViewLoad.propTypes = {
	lesson: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}).isRequired,
};