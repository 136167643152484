import PropTypes from 'prop-types';
import {Question} from '../../../LessonsEdit/components';
import {QuestionForm} from '../../../LessonsEdit/components/QuestionForm';
import {DraggableQuestions, Loader} from '../../../../components';

export const VideoQuestions =({ sparkL,addQuestionModal, questions,questionPoints, setAddQuestionModal,onDeleteQuestion,onUpdateQuestion,onCreateQuestion,onResortQuestions,loadingQuestions })=>{

	return (
		<div className={'p-4 font-mono w-[1000px] flex flex-col gap-3 text-white'}>

			<div className={'flex w-full justify-between items-center mt-2'}>
				<div className={'flex gap-2'}>
					<h1 className={'center h1 block'}>Video Questions </h1>
					<p className={'bg-brightLilac-default p-0 rounded-full w-[22px] h-[22px] aspect-square text-sm center'}>{questions.length}</p>
				</div>
				<button
					type={'button'}
					className={'bg-brightLilac-default cursor-pointer rounded px-2 hover:opacity-50'}
					onClick={()=>setAddQuestionModal(true)}
				>
					<p className={'flex gap-2 justify-center align-center font-sans'}>
									Add Article Question
						<span className={'text-2xl'}>+</span>
					</p>

				</button>
			</div>
			<div className={'flex flex-col gap-4 max-h-[20rem] overflow-y-scroll'}>
				{!loadingQuestions?
					<DraggableQuestions
						questions={questions}
						questionPoints={questionPoints}
						onDeleteQuestion={onDeleteQuestion}
						onUpdateQuestion={onUpdateQuestion}
						onResortQuestions={onResortQuestions}
					/>
					:<Loader/>
				}
			</div>

			<QuestionForm
				onCreate={onCreateQuestion}

				isOpen={addQuestionModal}
				setIsOpen={setAddQuestionModal}
			/>

		</div>
	);
};


VideoQuestions.propTypes = {
	sparkL:PropTypes.object,
	addQuestionModal:PropTypes.bool.isRequired,
	questions: PropTypes.arrayOf(
		PropTypes.shape({
			question: PropTypes.string.isRequired,
			points: PropTypes.string.isRequired
		})
	).isRequired,
	questionPoints:PropTypes.shape({
		question:PropTypes.string,
		points:PropTypes.number,
	}).isRequired,

	setAddQuestionModal: PropTypes.func.isRequired,
	onUpdateQuestion:PropTypes.func.isRequired,
	onDeleteQuestion:PropTypes.func.isRequired,
	onCreateQuestion:PropTypes.func.isRequired,
};