import {useQuery} from '@apollo/client';
import {SchoolQueries} from '../../actions/school.queries';

export const TeacherClasses = ({teacherId})=>{

	const {data,loading} = useQuery(SchoolQueries('TeacherClassAndStudents'),{
		variables:{
			user:teacherId
		}
	});
	console.log({data,loading});
	return(
		<div>
			<p>{teacherId}</p>
		</div>
	);
};