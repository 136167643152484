import React from 'react';
import PropTypes from 'prop-types';
import {CoursesTableMainWrapper} from '../../../Courses/Courses.style';
import {StyledTableRow} from '../../../Users/Users.style';
import {PencilIcon} from '../../../../icons';
import Button from '../../../../components/ui/Button';
import {CheckInQuestionCreateForm, CheckInQuestionEditForm} from '../../components';

export const CheckinQuestionRender = ({lesson_id, lesson_curriculum, questions,
	showCreateCheckInQuestionForm, setShowCreateCheckInQuestionForm, CheckInQuestionRefetch, handleEditClick,
	showCreateCheckInQuestionFormEdit, setShowCreateCheckInQuestionFormEdit, selectedCheckInQuestionId
}) => {
	console.log({questions});
	return (
		<div className={'w-full px-4 text-white'}>
			<div className={'flex justify-between gap-4 items-center mb-4'}>
				<h2 className={'h2'}>CheckinQuestion</h2>
				<Button type={'button'} className={'bg-brightLilac-default'}
					onClick={() => {setShowCreateCheckInQuestionForm(true);}}>
					New CheckinQuestion +
				</Button>
			</div>
			{
				!questions.length ? (
					<div className={'pt-10 w-100'}>
						<p className={'h4 center'}>This Lesson does not have CheckInQuestions yet.</p>
						<p className={'h4 center'}>You can also, create one!</p>
					</div>
				) :
					<div className={'w-full br border-text-dark border overflow-hidden px-2 pb-4'}>
						<CoursesTableMainWrapper className={'w-100 mt rounded-lg p2.5 '}
												 style={{boxDecorationBreak: 'unset'}}>
							<thead>
								<tr>
									<th className={'font-mono main-color-background p-2 text-start whitespace-nowrap'}>
										<span>Question</span>
									</th>
									<th className={'font-mono main-color-background p-2 text-start whitespace-nowrap'}>
										<span>Start</span>
									</th>
									<th className={'font-mono main-color-background p-2 text-start whitespace-nowrap'}>
										<span>Actions</span>
									</th>
								</tr>
							</thead>
							<tbody className={'p-2'}>
								{questions.map(({_id, curriculum, label, start, createdAt, updatedAt}) => (
									<StyledTableRow key={_id} onClick={() => handleEditClick(_id)} className={'cursor-pointer'}>
										<td className={'p-2 text-start '}>
											<div className={'overflow-hidden max-w-[900px] max-h-[80px] overflow-ellipsis'}>
												{label}
											</div>
										</td>
										<td className={'p-2 text-start whitespace-nowrap'}>
											<div className={'flex justify-start align-center gap-2'}>
												<div className={'radio'}
													 style={{
															 width: '0.625rem',
															 height: '0.625rem',
															 background: start ?'#00FF2AFF' : '#FF0000FF'
														 }}></div>
												<span>{start ?'Yes' : 'No'}</span>
											</div>
										</td>
										<td className={'cursor-pointer p-2 text-start whitespace-nowrap'}>
											<button type='button' onClick={() => handleEditClick(_id)}>
												<PencilIcon className={'ml-5 cursor-pointer'}/>
											</button>
										</td>
									</StyledTableRow>
								))}
							</tbody>
						</CoursesTableMainWrapper>
					</div>
			}
			<CheckInQuestionCreateForm
				lessonId={lesson_id}
				lesson_curriculum={lesson_curriculum}
				isOpen={showCreateCheckInQuestionForm}
				setIsOpen={setShowCreateCheckInQuestionForm}
				refetchCheckInQuestions={CheckInQuestionRefetch}
			/>
			<CheckInQuestionEditForm
				checkInQuestionId={selectedCheckInQuestionId}
				lessonId={lesson_id}
				lesson_curriculum={lesson_curriculum}
				isOpen={showCreateCheckInQuestionFormEdit}
				setIsOpen={setShowCreateCheckInQuestionFormEdit}
			/>
		</div>
	);
};

CheckinQuestionRender.propTypes = {
	lesson_id: PropTypes.string.isRequired,
	lesson_curriculum: PropTypes.string,
	questions: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			curriculum: PropTypes.string,
			class: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			start: PropTypes.string,
			deletedAt: PropTypes.string,
			createdAt: PropTypes.string.isRequired,
			updatedAt: PropTypes.string,
		})
	).isRequired,
	showCreateCheckInQuestionForm: PropTypes.bool.isRequired,
	setShowCreateCheckInQuestionForm: PropTypes.func.isRequired,
	CheckInQuestionRefetch: PropTypes.func.isRequired,
	handleEditClick: PropTypes.func.isRequired,
	showCreateCheckInQuestionFormEdit: PropTypes.bool.isRequired,
	setShowCreateCheckInQuestionFormEdit: PropTypes.func.isRequired,
	selectedCheckInQuestionId: PropTypes.string,
};