// @ts-ignore
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {StyledTableRow} from '../../../Courses/Courses.style';
import {DistrictFindOneByID} from '../../../../hoc/DistrictFindOneByID';
import React, {useState} from 'react';
import Modal from '../../../../components/ui/Modal/Modal';
import {SchoolForm} from '../../forms';
import {PencilIcon} from '../../../../icons';
export const SchoolArrow =({school,index})=>{
	const history = useHistory();
	const { name, district, state, country} = school;
	const [showEditForm,setShowEditForm] = useState(false);
	const handleEdit = ()=>setShowEditForm(true);
	const handleDelete = ()=>console.log('delete');

	return (
		<StyledTableRow
			index={index}
		>
			<Modal isOpen={showEditForm} setIsOpen={setShowEditForm}>
				<SchoolForm
					currentSchool={school}
					onSuccess={()=>setShowEditForm(false)}
				/>
			</Modal>
			<td className={'p-2'}>
				<div className={'flex justify-start items-center p-1 gap-2'}>
					<span
						className={'font-mono text-text-light dark:text-text-dark text-xs'}>{name}</span>
				</div>
			</td>
			<td className={'justify-start items-center text-start p-2'}>
				<p className={'font-mono truncate overflow-ellipsis'}
					style={{color: 'rgba(174, 185, 225, 1)'}}>
					{district ? (
						<DistrictFindOneByID id={district}>
							{({name}) => <span
								className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{name}</span>}
						</DistrictFindOneByID>
					) : (
						<span className={'font-mono text-text-light dark:text-text-dark  text-sm'}>Not Available</span>
					)}
				</p>
			</td>

			<td className={'justify-start items-center text-start p-2'}>
				<p className={'font-mono truncate overflow-ellipsis'}
					style={{color: 'rgba(174, 185, 225, 1)'}}>
					{country ? (
						<span
							className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{country}</span>
					) : (
						<span>Not Available</span>
					)}
				</p>
			</td>
			<td className={'justify-start items-center text-start p-2'}>
				<p className={'font-mono truncate overflow-ellipsis'}
					style={{color: 'rgba(174, 185, 225, 1)'}}>
					{state ? (
						<span
							className={'font-mono text-text-light dark:text-text-dark  text-sm'}>{state}</span>
					) : (
						<span>Not Available</span>
					)}
				</p>
			</td>
			<td>
				<button
					type='button'
					onClick={() => history.push(`/schools/${school._id}`)}
				>
					<PencilIcon className={'cursor-pointer'}/>
				</button>
			</td>
		</StyledTableRow>
	);
};

SchoolArrow.prototype = {
	school: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		district: PropTypes.string,
		state:PropTypes.string.isRequired,
		country:PropTypes.string.isRequired,
	}).isRequired,
	index:PropTypes.number.isRequired,
};