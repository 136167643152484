import gql from 'graphql-tag';

const queries = {
	reportsFindMany: gql`
        query ReportsFindMany($filter: FilterFindManyreportsInput, $skip: Int, $limit: Int, $sort: SortFindManyreportsInput){
            ReportsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                body
                scale
                deleted
                createdAt
                updatedAt
            }
        }
    `,
	reportsFindOne: gql`
        query ReportsFindOne($filter: FilterFindOnereportsInput, $skip: Int, $limit: Int, $sort: SortFindOnereportsInput){
            ReportsFindOne(filter: $filter, skip: $skip, sort: $sort) {
                _id
                body
                scale
                deleted
                createdAt
                updatedAt
            }
        }
    `
};

export const ReportsQueries = (value) => {
	return queries[value];
};