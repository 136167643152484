import Input from '../../../../components/ui/Input';
import {SchoolInformationTypes} from '../SchoolsDetails.types';

export const SchoolsInformation = ({ formState, handleChange, handleSubmit, loading, isChanged }) => {
	return (
		<div className={'w-full h-full font-mona f-600 text-xs text-text-light dark:text-text-dark m-4'}>
			<form
				className={'w-100 flex direction-column justify-center align-center p-5 rounded-lg'}
				onSubmit={handleSubmit}
				style={{ border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)' }}
			>
				<div className={'flex flex-col justify-between align-center gap-4 mb-4'}>
					<Input label={'School Name'}
						   type='text'
						   name='name'
						   value={formState?.name}
						   onChange={handleChange}
						   className={'w-full'}
					/>
					<Input label={'State'}
						   type='text'
						   name='state'
						   value={formState?.state}
						   onChange={handleChange}
						   className={'w-full'}
					/>
					<Input label={'City'}
						   type='text'
						   name='city'
						   value={formState?.city}
						   onChange={handleChange}
						   className={'w-full'}
					/>
					<Input label={'District'}
						   type='text'
						   name='district'
						   value={formState?.district}
						   onChange={handleChange}
						   className={'w-full'}
					/>
					<Input label={'Country'}
						   type='text'
						   name='country'
						   value={formState?.country}
						   onChange={handleChange}
						   className={'w-full'}
					/>
					<Input label={'Slug'}
						   type='text'
						   name='slug'
						   value={formState?.slug}
						   onChange={handleChange}
						   className={'w-full'}
					/>
					<div className={'mb-4 flex direction-column justify-between gap-2'}>
						<p className={'font-mono text-text-light dark:text-text-dark'}>
							<b>Created At: </b>{new Date(formState?.createdAt).toLocaleString()}
						</p>
						<p className={'font-mono text-text-light dark:text-text-dark'}>
							<b>Updated At:</b> {new Date(formState?.updatedAt).toLocaleString()}
						</p>
					</div>
					<div className={'flex align-middle justify-center center'}>
						<button
							type='submit'
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								loading || !isChanged ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={loading || !isChanged}
						>
							{loading ? 'Updating School...' : 'Update School'}
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

SchoolsInformation.propTypes = SchoolInformationTypes;