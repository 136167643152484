import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {CheckinQuestionRender} from './CheckinQuestion.render';
import {LessonQueries} from '../../actions/Lessons.queries';
import {Loader} from '../../../../components';
import React, {useState} from 'react';

export const CheckInQuestionLoad = ({ lesson }) => {
	const [showCreateCheckInQuestionForm, setShowCreateCheckInQuestionForm] = useState(false);
	const [showCreateCheckInQuestionFormEdit, setShowCreateCheckInQuestionFormEdit] = useState(false);
	const [selectedCheckInQuestionId, setSelectedCheckInQuestionId] = useState(null);

	const { data: CheckInQuestionData, loading: CheckInQuestionLoading, error: CheckInQuestionError, refetch: CheckInQuestionRefetch } =
		useQuery(LessonQueries('CheckInQuestionFindMany'), {
			variables: { filter: { class: lesson._id, deletedAt: null }, skip: 0, limit: 100, sort: '_ID_ASC' },
		});

	if(CheckInQuestionLoading) return <Loader/>;
	if (CheckInQuestionError) return <span className={'font-mona f-600 white p-3'}>Error...</span>;

	const handleEditClick = (id) => {
		setSelectedCheckInQuestionId(id);
		setShowCreateCheckInQuestionFormEdit(true);
	};

	console.log('This is the curriculum of this Lesson:', lesson?.curriculum);

	return <CheckinQuestionRender
		lesson_id={lesson._id}
		lesson_curriculum={lesson.curriculum}
		questions={CheckInQuestionData?.CheckInQuestionFindMany || []}
		showCreateCheckInQuestionForm={showCreateCheckInQuestionForm}
		setShowCreateCheckInQuestionForm={setShowCreateCheckInQuestionForm}
		CheckInQuestionRefetch={CheckInQuestionRefetch}
		handleEditClick={handleEditClick}
		showCreateCheckInQuestionFormEdit={showCreateCheckInQuestionFormEdit}
		setShowCreateCheckInQuestionFormEdit={setShowCreateCheckInQuestionFormEdit}
		selectedCheckInQuestionId={selectedCheckInQuestionId}
	/>;
};

CheckInQuestionLoad.propTypes = {
	lesson: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		curriculum: PropTypes.string,
	}).isRequired,
};