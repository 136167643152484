import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {DistrictQueries} from '../actions/district.queries';
import {DistrictDetailsRender} from './DistrictDetails.render';
import {Loader} from '../../../components';
import {districtMutations} from '../actions/district.mutations';
import {query} from '../../../actions/queries';
import {districtTeacher, districtStudent} from './DistrictDetails.types';
import {DistrictInformation} from './components';

export const DistrictDetailsLoad = () => {
	const student = districtStudent;
	const teacher = districtTeacher;

	const { id, organization } = useParams();
	const [type, setType] = useState(student);
	const history = useHistory();
	const [selectedTab, setSelectedTab] = useState('users');
	const [formState, setFormState] = useState(null);
	const [isChanged, setIsChanged] = useState(false);

	//menu
	const menu = [
		{
			to: 'users',
			name: 'Users',
			icon: 'PencilIcon',
			component: 'DistrictList'
		},
		{
			to: 'classes',
			name: 'Classes',
			icon: 'PencilIcon',
			component: 'GroupList'
		},
		{
			to: 'info',
			name: 'Info',
			icon: 'UserIcon',
			component: 'DistrictInformation'
		}
	];

	const { loading: DistrictStudentsLoading, error: DistrictStudentsError, data: DistrictStudentsData } =
		useQuery(DistrictQueries('UsersByOrganizationFindMany'), {
			variables: { organization: organization, type: student },
			nextFetchPolicy: 'network-only',
		});
	const { loading: DistrictTeachersLoading, error: DistrictTeachersError, data: DistrictTeachersData } =
		useQuery(DistrictQueries('UsersByOrganizationFindMany'), {
			variables: { organization: organization, type: teacher },
			nextFetchPolicy: 'network-only',
		});

	const { loading: DistrictLoading, error: DistrictError, data: District } =
		useQuery(DistrictQueries('districtFindOne'), {
			variables: { filter: { _id: id } },
			nextFetchPolicy: 'network-only'
		});

	useEffect(() => {
		if (District?.DistrictFindOne) {
			setFormState({
				name: District.DistrictFindOne.name || '',
				state: District.DistrictFindOne.state || '',
				country: District.DistrictFindOne.country || '',
				updatedAt: District.DistrictFindOne.updatedAt || '',
				createdAt: District.DistrictFindOne.createdAt || '',
			});
		}
	}, [District]);

	const [editDistrict, {loading:editLoading}] = useMutation(districtMutations('updateOne'), {
		refetchQueries: [{ query: query('districtFindMany') }, { query: query('districtFindOne') }],
		awaitRefetchQueries: true,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState({
			...formState,
			[name]: value,
		});
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await editDistrict({
				variables: {id: id , record: formState},
			});
		} catch (error) {
			console.error('Error creating District:', error);
		}
	};

	if (DistrictLoading || DistrictStudentsLoading || DistrictTeachersLoading)
		return <Loader />;
	if (DistrictError || DistrictStudentsError || DistrictTeachersError)
		return <p className={'font-mona f-900 text-white p-3'}>Error</p>;
	if (!District || (!DistrictStudentsData && !DistrictTeachersData))
		return <p className={'font-mona f-900 p-3'}>No Data in Districts</p>;

	const handleFilterChange = (newType) => {
		setType(newType);
	};

	return <DistrictDetailsRender
		DistrictDataArrayTeacher={DistrictTeachersData}
		DistrictDataArrayStudent={DistrictStudentsData}
		District={District}
		formState={formState}
		history={history}
		menu={menu}
		selectedTab={selectedTab}
		setSelectedTab={setSelectedTab}
		handleFilterChange={handleFilterChange}
		selectedType={type}
		loading={editLoading}
		handleSubmit={handleSubmit}
		handleChange={handleChange}
		isChanged={isChanged}
	/>;
};