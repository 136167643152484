import PropTypes from 'prop-types';
import {VideoTapsViewRender} from '../../../pages/LessonsEdit/views/VideoTapsView/VideoTapsView.render';
import {TVideoTap} from '../../../types/propTypes/SVGPropTypes';
import {secondsToMinutes} from '../../../tools/transform';

export const VideoTapArticle =({videTap,selected, ...props})=>{
    
	return (
		<article className={`p-2 cursor-pointer my-2 rounded-md min-w-[300px] ${selected? 'bg-cloudyBlue-dark' : ''}`} {...props}>
			<p>{videTap.tag}</p>
			<p className={'font-bold flex gap-6'}>
				<span>{ secondsToMinutes(videTap.start)}</span>
				<span className={'font-normal'}>{`>`}</span>
				<span>{secondsToMinutes(videTap.end)}</span>
			</p>

		</article>
	);
};

VideoTapsViewRender.propTypes = {
	VideoTap:PropTypes.shape(TVideoTap),
	selected:PropTypes.bool,
};