import PropTypes from 'prop-types';

export const lessonShape = PropTypes.shape({
	_id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	curriculum: PropTypes.string.isRequired,
	order: PropTypes.number.isRequired,
	lifeSkill: PropTypes.arrayOf(PropTypes.string).isRequired,
	skillSet: PropTypes.arrayOf(PropTypes.string).isRequired,
	theme: PropTypes.arrayOf(PropTypes.string).isRequired,
	cover: PropTypes.shape({
		url: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,
	episodeTime: PropTypes.number.isRequired,
	quizTime: PropTypes.number.isRequired,
	journalTime: PropTypes.number.isRequired,
	classActivityTime: PropTypes.number.isRequired,
	tapLibraryTime: PropTypes.number.isRequired,
	extendTime: PropTypes.number.isRequired,
	lessonLength: PropTypes.string,
	overview: PropTypes.string,
	extraActivities: PropTypes.string,
	bigIdea: PropTypes.string,
	discussion: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
	})).isRequired,
	extend: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
	})).isRequired,
	activity: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
	})).isRequired,
	reflection: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
	})).isRequired,
	resources: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		url: PropTypes.string,
		type: PropTypes.string,
	})).isRequired,
	extracurricular: PropTypes.array,
	questions: PropTypes.arrayOf(PropTypes.object),
	learningGoal: PropTypes.string,
});