import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {CheckInQueries} from '../pages/CheckIn/actions/CheckIn.queries';
// checkingQuestionId:string - we receive the ID from CheckingQuestions and to search inside the CheckingQuestionsFindOne query,
// and then, if the fields exists, get the Class information, in this case we only need the Title. If the Class is not found or non exists
// we only return the 'Class not found' text.
export const ClassesFindOneByCheckingQuestionId = ({ checkingQuestionId }) => {
	const {
		data: checkingQuestionData,
		loading: checkingQuestionLoading,
		error: checkingQuestionError,
	} = useQuery(CheckInQueries('checkInQuestionFindOne'), {
		variables: { filter: { _id: checkingQuestionId } },
		fetchPolicy: 'network-only',
	});

	const classId = checkingQuestionData?.CheckInQuestionFindOne?.class;

	const {
		data: classData,
		loading: classLoading,
		error: classError,
	} = useQuery(CheckInQueries('classesAdminFindOneForCheckingQuestions'), {
		skip: !classId,
		variables: { filter: { _id: classId } },
		fetchPolicy: 'network-only',
	});

	if (checkingQuestionLoading || (classId && classLoading)) return null;
	if (checkingQuestionError || !checkingQuestionData?.CheckInQuestionFindOne) return 'Class not found';
	if (classId && (classError || !classData?.ClassesAdminFindOne)) return 'Class not found';

	return classId && classData
		? classData.ClassesAdminFindOne.title
		: 'Class not found';
};

ClassesFindOneByCheckingQuestionId.propTypes = {
	checkingQuestionId: PropTypes.string.isRequired,
};