import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {SchoolQueries} from '../actions/school.queries';
import {query} from '../../../actions/queries';
import {Loader} from '../../../components';
import {SchoolsList} from './components/SchoolsList';
import {schoolMutations} from '../actions/school.mutations';
import {schoolStudent, schoolTeacher} from './SchoolsDetails.types';
import {SchoolDetailsRender} from './SchoolDetails.render';

const teacher = schoolTeacher;
const student = schoolStudent;

export const SchoolDetailsLoad = () => {
	const history = useHistory();
	const { id } = useParams();
	const [type, setType] = useState(student);
	const [selectedTab, setSelectedTab] = useState('school-information');
	const [formState, setFormState] = useState(null);
	const [isChanged, setIsChanged] = useState(false);

	const menu = [
		{
			to: 'school-information',
			name: 'School Information',
			icon: 'UserIcon',
			component: 'SchoolsInformation'
		},
		{
			to: 'school-list',
			name: 'School List',
			icon: 'PencilIcon',
			component: 'SchoolsList'
		}
	];

	const { loading: StudentsLoading, error: StudentsError, data: StudentsData } = useQuery(SchoolQueries('UsersBySchoolFindMany'), {
		variables: {
			school: id,
			type: student
		},
		nextFetchPolicy: 'network-only'
	});

	const { loading: TeachersLoading, error: TeachersError, data: TeachersData } = useQuery(SchoolQueries('UsersBySchoolFindMany'), {
		variables: {
			school: id,
			type: teacher
		},
		nextFetchPolicy: 'network-only'
	});
	const { loading: SchoolLoading, error: SchoolError, data: SchoolData } =
		useQuery(query('schoolFindOne'),{
			variables: { filter: { _id: id } }
		});

	const [updateSchool,{loading:updateLoading}] = useMutation(schoolMutations('updateOne'), {
		refetchQueries: [{ query: query('schoolFindMany') }, { query: query('schoolFindOne') }],
		awaitRefetchQueries: true,
	});

	useEffect(() => {
		if(SchoolData?.SchoolFindOne){
			setFormState({
				name: 		SchoolData.SchoolFindOne.name ||'',
				state: 		SchoolData.SchoolFindOne.state || '',
				city: 		SchoolData.SchoolFindOne.city || '',
				district: 	SchoolData.SchoolFindOne.district || '',
				country: 	SchoolData.SchoolFindOne.country || '',
				slug: 		SchoolData.SchoolFindOne.slug || '',
			});
		}
	}, [SchoolData]);


	const handleFilterChange = (newType) => {
		setType(newType);
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState({
			...formState,
			[name]: value,
		});
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try{
			await updateSchool({
				variables: {_id: id, record: formState,},
			});
		} catch (err) {
			console.error('Error editing School:', err);
		}
	};

	if (StudentsLoading || TeachersLoading || SchoolLoading) return <div><Loader /></div>;
	if (StudentsError || TeachersError || SchoolError) return <p className={'font-mona f-900 p-3'}>Error</p>;
	if (!SchoolData || !SchoolData.SchoolFindOne) return <p className={'font-mona f-900 p-3'}>No Data Available</p>;

	return <SchoolDetailsRender
		menu={menu}
		history={history}
		handleFilterChange={handleFilterChange}
		selectedTab={selectedTab}
		setSelectedTab={setSelectedTab}
		handleChange={handleChange}
		handleSubmit={handleSubmit}
		isChanged={isChanged}
		formState={formState}
		teachersData={TeachersData}
		studentsData={StudentsData}
		School={SchoolData.SchoolFindOne}
		loading={updateLoading}
		error={SchoolError}
	 	selectedType={type}
	/>;
};