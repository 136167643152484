import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledSelect= styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:0;
`;

StyledSelect.defaultProps={
	$width: '100%',
	$widthLaptop:'100%',
	$widthMobile: '100%',
	$padding:'0 8px',

	//input
	$color:'transparent',
	$height: '56px',
	$heightLaptop: '40px',
	$heightMobile: '30px',
	$border:'2px solid #9F9F9F',
	$borderRadius: '10px',
	$inputPadding:'0 1rem',
	$placeHolderColor:'#9F9F9F',
	$fontFamily:'"Roboto", sans-serif',
	$fontColor:'#777',
	$fontSize:'1rem',
	$fontWeight:'400px',

	//label
	$labelFontColor:'#000000',
	$labelFontSize:'1rem',
	$labelFontWeight:'400px'
};

export default function Select({ className, label, name, required, defaultValue, placeholder, options, styledRules, ...props }) {
	return (
		<StyledSelect className={'w-full my-2 ' + className} {...styledRules} {...props}>
			{label && (
				<div className={'w-full text-cloudyBlue-default '}>
					<label className={'m-0'}>{label}</label>
					<p>{required ? '*Required' : ''}</p>
				</div>
			)}
			<select
				className={'w-full grow text-cloudyBlue-default rounded-md p-2 border border-cloudyBlue-dark bg-midnight-default'}
				name={name}
				placeholder={placeholder}
				defaultValue={defaultValue}
				{...props}
			>
				{placeholder ? (
					<option value={''}>{placeholder}</option>
				) : (
					<option value={''}>Select One</option>
				)}
				{options.map(({ label, value }) => (
					<option key={value+label} value={value}>
						{label}
					</option>
				))}
			</select>
		</StyledSelect>
	);
}

Select.Proptypes = {
	options:PropTypes.shape({
		value:PropTypes.string,
		label:PropTypes.string,
	})
};