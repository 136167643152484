import PropTypes from 'prop-types';
import {ArrowIndicatorIcon} from '../../icons';
import React from 'react';
import {LessonsTab} from '../LessonsEdit/components';
import {useParams} from 'react-router-dom';
import {DocsTab, Info} from './views';

export const CoursesEditRender = ({history, id, curriculum, refetch}) => {
	const { tab } = useParams();
	return (
		<div
			className={'w-100 flex flex-col  justify-start align-center gap-7 p-5 rounded-lg'}
		>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
				<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Edit Course</h1>
			</div>
			<section className={'rounded bg-midnight-light border border-cloudyBlue-dark w-full min-h-[800px]'}>

				<div className={'mb-4 w-100 flex gap-3 justify-center align-center self-start'}>
					<LessonsTab label={'info'} to={`/courses/${id}/info`} />
					<LessonsTab label={'docs'} to={`/courses/${id}/docs`} />
				</div>
				{ tab === 'info' || !tab	  ? <Info/> : null}
				{ tab === 'docs' ? <DocsTab
					documents={curriculum.documents}
					id = {curriculum._id}
					refecth={refetch}/> : null
				}
			</section>
		</div>
	);
};

CoursesEditRender.propTypes = {
	history: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	curriculum:PropTypes.shape({
		_id:PropTypes.string.isRequired,
		documents:PropTypes.arrayOf(PropTypes.shape({
			title:PropTypes.string.isRequired,
			url:PropTypes.string.isRequired,
			type:PropTypes.string.isRequired,
		}))
	}),
	refetch: PropTypes.func.isRequired,
};