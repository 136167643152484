import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {CheckInQueries} from './actions/CheckIn.queries';
import {Loader} from '../../components';
import {CheckInRender} from './ChekIn.render';

export const CheckInLoad = () => {
	const [limit, setLimit] = useState(100);
	const [skip, setSkip] = useState(0);
	const [allCheckIns, setAllCheckIns] = useState([]);
	const [filteredCheckIns, setFilteredCheckIns] = useState([]);
	const [sortByDate, setSortByDate] = useState('desc');

	const { loading, error, data, refetch } = useQuery(
		CheckInQueries('checkInUserAnswerFindMany'),
		{
			variables: {
				limit: 8000,
				skip: 0
			},
			fetchPolicy: 'network-only',
			onCompleted: (data) => {
				if (data?.CheckInUserAnswerFindMany) {
					setAllCheckIns(data.CheckInUserAnswerFindMany || []);
					const sortedData = sortCheckIns(data.CheckInUserAnswerFindMany, sortByDate);
					setFilteredCheckIns(sortedData);
				}
			},
		}
	);

	const sortCheckIns = (checkIns, order) => {
		return [...checkIns].sort((a, b) => {
			const dateA = new Date(a.createdAt);
			const dateB = new Date(b.createdAt);

			return order === 'asc' ? dateA - dateB : dateB - dateA;
		});
	};

	useEffect(() => {
		const sortedData = sortCheckIns(allCheckIns, sortByDate);
		setFilteredCheckIns(sortedData);
	}, [sortByDate, allCheckIns]);

	const totalPages = Math.ceil(filteredCheckIns.length / limit);

	const handleNextPage = () => {
		if (skip + limit < filteredCheckIns.length) setSkip(skip + limit);
	};

	const handlePreviousPage = () => {
		if (skip > 0) {
			setSkip(skip - limit);
		}
	};

	if (loading) return <Loader />;
	if (error) return <p>Error: {error.message}</p>;

	return (
		<CheckInRender
			checkIns={filteredCheckIns.slice(skip, skip + limit)}
			skip={skip}
			limit={limit}
			setLimit={setLimit}
			onNextPage={handleNextPage}
			onPreviousPage={handlePreviousPage}
			totalPages={totalPages}
			currentPage={Math.floor(skip / limit) + 1}
			allCheckIns={allCheckIns.length}
			sortByDate={sortByDate}
			setSortByDate={setSortByDate}
		/>
	);
};