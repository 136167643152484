import {AdminProfileRender} from './AdminProfile.render';
import {useEffect, useState} from 'react';
import {AuthUser} from '../../actions/restactions';
import {usersMutations} from '../Users/actions/Users.mutations';
import {useMutation} from '@apollo/client';
import {usersQueries} from '../Users/actions/Users.queries';
import {Loader} from '../../components';
import {isValidImageUrl} from '../../tools';

export const AdminProfile = () => {

	const [authUser, setAuthUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const [preview, setPreview] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isChanged, setIsChanged] = useState(false);

	useEffect(() => {
		AuthUser().then(data => {
			setAuthUser({
				_id: data._id,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				profilePicture: {
					url: isValidImageUrl(data.profilePicture?.url) ? data.profilePicture.url : '',
				},
			});
			setLoading(false);
		});
	}, []);

	const [updateUser, { loading: updateUserLoading, error: updateUserError }] = useMutation(usersMutations('userUpdateOne'),{
		refetchQueries: [{ query: usersQueries('usersFindOne') }],
		awaitRefetchQueries: true,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setAuthUser({
			...authUser,
			[name]: value,
		});
		setIsChanged(true);
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
		setIsChanged(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try{
			await updateUser({
				variables: {_id: authUser._id, record: authUser},
			});
			if(selectedFile) console.log('uploading image.');
		} catch (err) {
			console.error('Error updating Admin:', err);
		}
	};

	if( loading || updateUserLoading) return <div><Loader/></div>;

	return <AdminProfileRender
		userData={authUser}
		handleSubmit={handleSubmit}
		handleChange={handleChange}
		handleFileChange={handleFileChange}
		isChanged={isChanged}
		preview={preview || authUser.profilePicture.url}
		loading={updateUserLoading}
		error={updateUserError}
	/>;
};