import {useEffect, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import {JournalsQueries} from './actions/Journals.queries';
import {Loader} from '../../components';
import {JournalsRender} from './Journals.render';

export const JournalsLoad = () => {
	const [limit, setLimit] = useState(100);
	const [skip, setSkip] = useState(0);
	const [searchTerm, setSearchTerm] = useState('');
	const [allJournals, setAllJournals] = useState([]);
	const [minScale, setMinScale] = useState(0);
	const [maxScale, setMaxScale] = useState(6);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortOrderByDate, setSortOrderByDate] = useState('desc');

	const { loading, error, data, refetch } = useQuery(JournalsQueries('journalsFindMany'), {
		variables: { limit: 8000, skip: 0, sort: '_ID_DESC' },
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			if (data?.JournalsFindMany) {
				const sortedJournals = [...data.JournalsFindMany].sort((a, b) =>
					new Date(b.createdAt) - new Date(a.createdAt)
				);
				setAllJournals(sortedJournals);
			}
		},
	});

	useEffect(() => {
		refetch().then(() => {});
	}, [skip, limit, refetch]);

	const filteredJournals = useMemo(() => {
		let filtered = allJournals;
		if (searchTerm) {
			const searchTermLower = searchTerm.toLowerCase();
			filtered = filtered.filter(
				(journal) =>
					journal.title?.toLowerCase().includes(searchTermLower) ||
					journal.description?.toLowerCase().includes(searchTermLower)
			);
		}

		filtered = filtered.filter((journal) => journal.scale >= minScale && journal.scale <= maxScale);

		if (sortOrder === 'asc') {
			filtered = filtered.sort((a, b) => a.scale - b.scale);
		} else {
			filtered = filtered.sort((a, b) => b.scale - a.scale);
		}

		if (sortOrderByDate === 'asc') {
			return filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
		} else {
			return filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
		}
	}, [searchTerm, allJournals, minScale, maxScale, sortOrder, sortOrderByDate]);

	const totalPages = Math.ceil(filteredJournals.length / limit);

	const handleSearch = (term) => setSearchTerm(term);
	const handleNextPage = () => {
		if (skip + limit < filteredJournals.length) setSkip(skip + limit);
	};
	const handlePreviousPage = () => {
		if (skip > 0) setSkip(skip - limit);
	};

	if (loading) return <Loader />;
	if (error) return <p>Error: {error.message}</p>;

	return (
		<>
			<JournalsRender
				journals={filteredJournals.slice(skip, skip + limit)}
				skip={skip}
				limit={limit}
				setLimit={setLimit}
				onSearch={handleSearch}
				onNextPage={handleNextPage}
				onPreviousPage={handlePreviousPage}
				totalPages={totalPages}
				currentPage={Math.floor(skip / limit) + 1}
				allJournals={filteredJournals.length || 0}
				onMinScaleChange={setMinScale}
				onMaxScaleChange={setMaxScale}
				minScale={minScale}
				maxScale={maxScale}
				sortOrder={sortOrder}
				setSortOrder={setSortOrder}
				sortOrderByDate={sortOrderByDate}
				setSortOrderByDate={setSortOrderByDate}
			/>
		</>
	);
};