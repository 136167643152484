import React from 'react';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const ClassesFindManyByArrayIds = ({arrayOfClasses, children}) => {
	if(!arrayOfClasses) return <span className='text-gray-500'>No Classes</span>;
	const {loading, error, data} = useQuery(query('classesAdminFindMany'), {
		variables: {
			filter: {
				_operators: {
					_id: { in: arrayOfClasses },
				},
			},
		},
		skip: !arrayOfClasses || arrayOfClasses.length === 0,
	});
	if (loading || error) return null;

	return children(data?.ClassesAdminFindMany || []);
};

ClassesFindManyByArrayIds.propTypes = {
	arrayOfClasses: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.func.isRequired,
};

ClassesFindManyByArrayIds.defaultProps = {
	arrayOfClasses: [],
};