import {QuestionsViewRender} from './QuestionsView.render';
import PropTypes, {string} from 'prop-types';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {mutation, mutations} from '../../../../actions/mutations';
import {useQuestionsLayout} from '../../../../hooks';

export const QuestionsViewLoad = ({ lesson }) =>{
	const [ addQuestionModal, setAddQuestionModal ] = useState(false);

	const lessonQuestions = lesson.questions.map(item => ({
		question: item.question,
		points: item.points
	}))||[];

	const [updateLesson] = useMutation(mutations.classesUpdateOne, {
		refetchQueries: ['ClassesAdminFindOne'],
		awaitRefetchQueries: true
	});

	const onChangeQuestionsArray =async (newQuestions)=>{
		await updateLesson(
			{variables:{id:lesson._id, record:{ questions: newQuestions }}
			});
	};

	const { questions, loadingQuestions, onUpdateQuestion, onDeleteQuestion, onCreateQuestion, onResortQuestions }
		= useQuestionsLayout({questions:lessonQuestions,onChangeQuestionsArray});

	return<>
		<QuestionsViewRender
			addQuestionModal={addQuestionModal}
			questions={questions}
			questionPoints={lessonQuestions}
			loadingQuestions={loadingQuestions}

			setAddQuestionModal={setAddQuestionModal}
			onUpdateQuestion={onUpdateQuestion}
			onDeleteQuestion={onDeleteQuestion}
			onCreateQuestion={onCreateQuestion}
			onResortQuestions={onResortQuestions}
		/>
	</>
	;
};

QuestionsViewLoad.propTypes = {
	lesson: PropTypes.shape({
		_id:string,
	}).isRequired,
};