import {Users} from './Users';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {loadDevMessages, loadErrorMessages} from '@apollo/client/dev';
import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {studentTypeId, teacherTypeId} from './User.types';
import {Loader} from '../../components';

export const UsersLoad = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const sort = searchParams.get('sort') || '';
	const [limit, setLimit] = useState(10);
	const [skip, setSkip] = useState(0);
	const { tab } = useParams();
	const [searchTerm, setSearchTerm] = useState('');
	const [allUsers, setAllUsers] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchCompleted, setSearchCompleted] = useState(false);
	const [studentsCount, setStudentsCount] = useState(0);
	const [teachersCount, setTeachersCount] = useState(0);

	const { loading, error, data, refetch } = useQuery(query('UsersFindMany'), {
		variables: {
			skip: 0,
			limit: 5000,
			sort: sort !== '' ? sort : '_ID_DESC',
		},
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setAllUsers(data?.UsersFindMany || []);
		}
	});

	useEffect(() => {
		refetch().then(r => '');
	}, [skip, limit, sort, refetch]);

	useEffect(() => {
		setSearchLoading(true);
		let filtered = allUsers;
		let studentsCount = 0;
		let teachersCount = 0;

		studentsCount = allUsers.filter(user => user.type === studentTypeId).length;
		teachersCount = allUsers.filter(user => user.type === teacherTypeId).length;

		if (tab && tab !== 'all') {
			const typeId = tab === 'students' ? studentTypeId : teacherTypeId;
			filtered = filtered.filter(user => user.type === typeId);
		}
		if (searchTerm) {
			const searchTermLower = searchTerm.toLowerCase();
			filtered = filtered.filter(user => {
				const email = user.email?.toLowerCase() || '';
				const firstName = user.firstName?.toLowerCase() || '';
				const lastName = user.lastName?.toLowerCase() || '';
				return (
					email.includes(searchTermLower) ||
					firstName.includes(searchTermLower) ||
					lastName.includes(searchTermLower)
				);
			});
		}
		setFilteredUsers(filtered);
		setStudentsCount(studentsCount);
		setTeachersCount(teachersCount);
		setSearchLoading(false);
		setSearchCompleted(true);
	}, [searchTerm, allUsers, tab]);

	if (loading) return <div className={'font-mona f-600 text-white p-3'}>
		<Loader/>
	</div>;
	if (error) return <p className={'font-mona f-600 white p-3'}>Error</p>;
	loadDevMessages();
	loadErrorMessages();

	const handleSearch = (term) => {
		setSearchTerm(term);
	};

	const handleLimitChange = (newLimit) => {
		setLimit(newLimit);
		setSkip(0);
	};

	const handleNextPage = () => {
		if (skip + limit < filteredUsers.filter(user => user.firstName && user.lastName).length) {
			setSkip(skip + limit);
		}
	};

	const handlePreviousPage = () => {
		if (skip > 0) {
			setSkip(skip - limit);
		}
	};

	return (
		<>
			<Users
				Users={filteredUsers.slice(skip, skip + limit).filter(user => user.firstName && user.lastName)}
				skip={skip}
				limit={limit}
				setLimit={handleLimitChange}
				onSearch={handleSearch}
				onNextPage={handleNextPage}
				onPreviousPage={handlePreviousPage}
				allUsersCount={allUsers.length}
				studentsCount={studentsCount}
				teachersCount={teachersCount}
			/>
			{searchLoading ? (
				<div className={'font-mona f-600 text-white p-3'}>
					<Loader />
				</div>
			) : (
				filteredUsers.length === 0 && searchTerm && (
					<p className={'font-mona f-600 text-center text-white p-3'}>{`"${searchTerm}", not found.`}</p>
				)
			)}
		</>
	);
};