import {useQuery} from '@apollo/client';
import {CheckInQueries} from '../pages/CheckIn/actions/CheckIn.queries';
import PropTypes from 'prop-types';

export const CheckInQuestionFindOne = ({_id}) => {
	if (!_id) return 'Question not found';
	const { data, loading, error } = useQuery(CheckInQueries('checkInQuestionFindOne'), {
		variables: { filter: { _id } },
		fetchPolicy: 'network-only'
	});

	if(loading) return null;
    
	if(error || !data || !data.CheckInQuestionFindOne) return 'Question not found';
	return `${data.CheckInQuestionFindOne.label}`;
};

CheckInQuestionFindOne.propTypes = {
	_id: PropTypes.string.isRequired,
};