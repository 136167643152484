import {Search} from '../../components';
import {
	UserCounterTitleTable,
	UserPageWrapperElements, UserTableMainWrapper,
} from './District.style';
import PropTypes from 'prop-types';
import Button from '../../components/ui/Button';
import React, {useState} from 'react';
import Modal from '../../components/ui/Modal/Modal';
import {DistrictForm} from './forms';
import {DistrictArrow} from './components';

export const DistrictRender = ({ Organizations }) => {
	const [showCreate, setShowCreate] = useState(false);
	const handleCreateOrganization = () => setShowCreate(true);

	return (
		<div className={'w-full h-full m-2'}>
			{showCreate ?
				<Modal isOpen={showCreate} setIsOpen={setShowCreate}>
					<DistrictForm  onSuccess={() => setShowCreate(false)} />
				</Modal>
				: null
			}
			<div className={'w-full flex justify-between items-center p-1 mb-3 gap-6'}>
				<div className={'flex justify-between items-center'}>
					<h1 className={'font-sans text-white f-500 text-2xl'}>Organizations</h1>
					<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
				</div>
				<Button
					type={'button'}
					className={'font-bold text-sm border-2 bg-brightLilac-default'}
					onClick={handleCreateOrganization}
				> Add Organization +</Button>
			</div>
			<div className={'flex justify-end items-center mb-3'}>
				<div className={'font-mono f-500 text-sm text-white'}>
					<span><strong style={{ color: 'rgba(203, 60, 255, 1)' }}>{Organizations.length} </strong></span>
					<span>of {Organizations.length}</span>
				</div>
			</div>
			<div className={'w-full mb-4'} style={{ height: '1px', border: '1px solid rgba(52, 59, 79, 1)' }} />
			<UserPageWrapperElements className={''}>
				<UserTableMainWrapper className='mt rounded-lg p-2.5'>
					<thead>
						<tr>
							<th className={'left font-mono main-color-background p-2 t-white'}>Name</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>Code</th>
							<th className={'left font-mono main-color-background p-2 t-white'}>District</th>
						</tr>
					</thead>
					<tbody>
						{Organizations.map((organization, index) => (
							<DistrictArrow organization={organization} index={index} key={organization?._id} />
						))}
					</tbody>
				</UserTableMainWrapper>
			</UserPageWrapperElements>
			<div className={'flex justify-end items-center mt-3 mb-3'}>
				<UserCounterTitleTable className={'font-mono'}>
					<span>1 - {Organizations.length} </span>
					<span>of {Organizations.length}</span>
				</UserCounterTitleTable>
			</div>
		</div>
	);
};

DistrictRender.propTypes = {
	Organizations: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		code: PropTypes.string,
		deletedAt: PropTypes.string,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string,
		lockedAt: PropTypes.string,
		cover: PropTypes.shape({
			url: PropTypes.string,
			type: PropTypes.string,
		}),
	})).isRequired,
};