import gql from 'graphql-tag';

const queries = {
	journalsFindMany: gql`
		query JournalsFindMany($filter: FilterFindManyjournalsInput, $skip: Int, $limit: Int, $sort: SortFindManyjournalsInput){
            JournalsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort){
                _id
                body
                user
                class
                teacher
                question
                analyze
                scale
                documents {
                    title
                    url
                    type
                }
                createdAt
                updatedAt
            }
		}
	`
};

export const JournalsQueries = ( value ) => {
	return queries[ value ];
};