import {DocumentForm} from './DocumentForm';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal/Modal';
import {removeTypename} from '../../../../tools';
import {uploadClassDocument, uploadCurriculumDocument} from '../../../../actions/restactions';

export const DocumentFormLoad = ({id,isOpen,setIsOpen, selectedDocument, updateDocument, documentsArray, refecth, isCourse})=> {
	const defaultForm = {
		title: '',
		file: undefined,
		type: '',
		url: '',
	};

	const [stateForm,setStateForm] =useState({...defaultForm});
	const [isLoading, setIsLoading] = useState(false);
	const documents = documentsArray || [];
	const [isUpdated, setIsUpdated] = useState(false);

	const handleChange = (e)=>  e.target.type === 'file'?
		setStateForm(prev=>({...prev,[e.target.name]:e.target.files[0]}))
		:
		setStateForm(prev=>({...prev,[e.target.name]:e.target.value}))
	;

	useEffect(() => {
		if (isOpen) {
			const cleanedDocument = selectedDocument ? removeTypename(selectedDocument) : null;
			setStateForm(cleanedDocument || { ...defaultForm });
		}
	}, [isOpen, selectedDocument]);

	const handleUploadDocument = async ({ title, file }) => {
		setIsLoading(true);
		try {
			const uploadFunction = isCourse ? uploadCurriculumDocument : uploadClassDocument;

			const response = await uploadFunction(file, id, title);

			if (!response || !response.url) {
				throw new Error('We can not get the url from server');
			}

			return response;
		} catch (err) {
			console.error('Error uploading document.', err);
			throw err;
		} finally {
			setIsLoading(false);
		}
	};

	const handleSave = async () => {
		setIsLoading(true);
		try {
			const isUpdatingDocument = !!selectedDocument;
			if (isUpdatingDocument) {
				const selectedAndCleanedDocument = removeTypename(selectedDocument);
				const oldUrl = selectedAndCleanedDocument.url;

				let updatedDocument = { ...selectedAndCleanedDocument, ...stateForm };
				const sanitizedDocument = {
					title: updatedDocument.title,
					type: updatedDocument.type,
					url: updatedDocument.url,
				};

				const updatedDocuments = documents.map(doc =>
					doc.url === oldUrl ? sanitizedDocument : doc
				);

				if (isCourse) {
					await updateDocument({
						variables: {
							_id: id,
							record: {
								documents: removeTypename(updatedDocuments),
							},
						},
					});
				} else {
					await updateDocument({
						variables: {
							id: id,
							record: {
								documents: removeTypename(updatedDocuments),
							},
						},
					});
				}
			} else if (stateForm.title && stateForm.file) {
				const uploadResponse = await handleUploadDocument(stateForm);
				const newDocument = {
					title: stateForm.title,
					url: uploadResponse.url,
					type: stateForm.file.type,
				};

				const updatedDocuments = [...documents, newDocument];

				if (isCourse) {
					await updateDocument({
						variables: {
							_id: id,
							record: {
								documents: removeTypename(updatedDocuments),
							},
						},
					});
				} else {
					await updateDocument({
						variables: {
							_id: id,
							record: {
								documents: removeTypename(updatedDocuments),
							},
						},
					});
				}
			}
		} catch (error) {
			console.error(error);
		} finally {
			refecth().then(() => console.log('Refecth completed'));
			setIsLoading(false);
			setIsUpdated(false);
			setIsOpen(false);
		}
	};

	const handleDelete = async () => {
		setIsLoading(true);
		try {
			if (!selectedDocument) return;
			const updatedDocuments = documents.filter(doc => doc.url !== selectedDocument.url);
			if (isCourse) {
				await updateDocument({
					variables: {
						_id: id,
						record: {
							documents: removeTypename(updatedDocuments),
						},
					},
				});
			} else {
				await updateDocument({
					variables: {
						id: id,
						record: {
							documents: removeTypename(updatedDocuments),
						},
					},
				});
			}
		} catch (error) {
			console.error('Error deleting document:', error);
		} finally {
			refecth().then(() => console.log('Refetch completed after deletion'));
			setIsLoading(false);
			setIsOpen(false);
		}
	};

	const onSubmit = (e)=>{
		e.preventDefault();
		if( stateForm.title && (stateForm.file || selectedDocument) ){
			handleSave().then(r => console.log('done uploading or editing'));
		}
	};

	return(
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<DocumentForm
				isLoading={isLoading}
				stateForm={stateForm}
				handleChange={handleChange}
				onSubmit={onSubmit}
				onDelete={handleDelete}
				close={()=>setIsOpen(false)}
				isEditing={!!selectedDocument}
				isChanged={isUpdated}
			/>
		</Modal>
	);
};
DocumentFormLoad.prototype={
	id: PropTypes.string.isRequired,
	onCreate:PropTypes.func.isRequired,
	uploadDocument:PropTypes.func.isRequired,
	updateDocument:PropTypes.func.isRequired,
	selectedDocument: PropTypes.object,
	documentsArray: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			url: PropTypes.string,
			type: PropTypes.string,
			__typename: PropTypes.string,
		})
	),
	refetch: PropTypes.func.isRequired,
	isCourse: PropTypes.bool.isRequired,
};