import PropTypes from 'prop-types';

//filter types:
export const schoolTeacher = 'e4475192-3c69-11ee-be56-0242ac120002';
export const schoolStudent = 'cae9c4f0-b804-11eb-87d9-ab38d9aa58aa';

export const SchoolDetailsRenderTypes = {
	formState: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		district: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
	}),
	StudentsData: PropTypes.shape({
		UsersBySchoolFindMany: PropTypes.shape({
			users: PropTypes.arrayOf(
				PropTypes.shape({
					_id: PropTypes.string.isRequired,
					id: PropTypes.string,
					firstName: PropTypes.string.isRequired,
					lastName: PropTypes.string.isRequired,
					userName: PropTypes.string,
					email: PropTypes.string.isRequired,
					profilePicture: PropTypes.shape({
						type: PropTypes.string,
						url: PropTypes.string,
						title: PropTypes.string,
					}),
					points: PropTypes.number,
					type: PropTypes.string.isRequired,
					lastLogin: PropTypes.string.isRequired,
					bio: PropTypes.string,
					birthday: PropTypes.string,
					tag: PropTypes.string,
					organization: PropTypes.string,
					cleverId: PropTypes.string,
					cleverToken: PropTypes.string,
					classLinkId: PropTypes.string,
					cleverSync: PropTypes.string,
					classLinkSync: PropTypes.string,
					deedlyAuthenticationToken: PropTypes.string,
					deedlyUserId: PropTypes.string,
					createdAt: PropTypes.string,
					updatedAt: PropTypes.string,
				})
			).isRequired,
			total: PropTypes.number.isRequired,
		}).isRequired,
	}).isRequired,
	TeachersData: PropTypes.shape({
		UsersBySchoolFindMany: PropTypes.shape({
			users: PropTypes.arrayOf(
				PropTypes.shape({
					_id: PropTypes.string.isRequired,
					id: PropTypes.string,
					firstName: PropTypes.string.isRequired,
					lastName: PropTypes.string.isRequired,
					userName: PropTypes.string,
					email: PropTypes.string.isRequired,
					profilePicture: PropTypes.shape({
						type: PropTypes.string,
						url: PropTypes.string,
						title: PropTypes.string,
					}),
					points: PropTypes.number,
					type: PropTypes.string.isRequired,
					lastLogin: PropTypes.string.isRequired,
					bio: PropTypes.string,
					birthday: PropTypes.string,
					tag: PropTypes.string,
					organization: PropTypes.string,
					cleverId: PropTypes.string,
					cleverToken: PropTypes.string,
					classLinkId: PropTypes.string,
					cleverSync: PropTypes.string,
					classLinkSync: PropTypes.string,
					deedlyAuthenticationToken: PropTypes.string,
					deedlyUserId: PropTypes.string,
					createdAt: PropTypes.string,
					updatedAt: PropTypes.string,
				})
			).isRequired,
			total: PropTypes.number.isRequired,
		}).isRequired
	}).isRequired,
	School: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		district: PropTypes.string,
		country: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		deletedAt: PropTypes.string,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired,
	}),
	menu: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
			component: PropTypes.string.isRequired,
		})
	).isRequired,
	history: PropTypes.object.isRequired,
	selectedTab: PropTypes.string.isRequired,
	setSelectedTab: PropTypes.func.isRequired,
	handleFilterChange: PropTypes.func.isRequired,
	selectedType: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
	allFieldsFilled: PropTypes.bool,
	isChanged: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

//School Information Types
export const SchoolInformationTypes = {
	formState: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		district: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
	}),
	history: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isChanged: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
};

//School List Types
export const SchoolsListTypes = {
	StudentsData: SchoolDetailsRenderTypes.StudentsData,
	TeachersData: SchoolDetailsRenderTypes.TeachersData,
	School: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		district: PropTypes.string,
		country: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
		deletedAt: PropTypes.string,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired,
	}),
	history: PropTypes.object.isRequired,
	handleFilterChange: PropTypes.func.isRequired,
	selectedType: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
};