import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {VideoTapArticle} from './components/VideoTapArticle';
import {TVideoTap} from '../../types/propTypes/SVGPropTypes';
import {Loader, VideoTap} from '../index';
import Input from '../ui/Input';
import Select from '../ui/Select';
import Button from '../ui/Button';
import {SparkLibraryFindMany} from '../../hoc/SparkLibraryFindMany';
import {VideoTapForm} from '../../pages/LessonsEdit/components/VideoTapForm/VideoTapForm.load';


export const LessonVideoTapsRender= ({
	lesson,
	videoTaps,
	isUpdating,
	updateError,
	currentVideoTap,
	setCurrentTapId,
	handleOnchange,
	onSubmit,
	formState
})=>{

	const [showVideoTapForm, setShowVideoTapForm] = useState(false);
	return (
		<div>
			{lesson.videos.url?
				<div className={'flex gap-4'}>
					<div>
						<VideoTap
							video={lesson.videos}
							value={{
								start: formState.start,
								end: formState.end,
							}}
							onChange={handleOnchange}
						/>
						<form onSubmit={onSubmit}>
							<Input
								label={'Title'}
								name='tag'
								value={formState?.tag}
								onChange={({target}) => handleOnchange(target.name, target.value)}
							/>
							<SparkLibraryFindMany>
								{({sparkLibraries}) => (
									<Select
										name='sparkLibrary'
										value={formState?.sparkLibrary}
										onChange={({target}) => handleOnchange(target.name, target.value)}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
										options={sparkLibraries.map(sp => ({
											value: sp._id,
											label: sp.title
										}))}
									/>
								)}
							</SparkLibraryFindMany>
							<Button
								disabled={isUpdating}
								type='submit'
								className={`bg-brightLilac-default disabled:opacity-50`}
							>
								{!isUpdating ? `${'Save >'}` :<Loader/>}
							</Button>
						</form>
					</div>
					<div className={'col-span-4'}>
						<Button type={'button'} className={'bg-brightLilac-default w-100'}
							onClick={() => {
								setShowVideoTapForm(true);
							}}>
								New VideoTap +
						</Button>
						
						{videoTaps.length ?
							videoTaps.map(vt =>
								<VideoTapArticle
									videTap={vt} key={vt._id}
									selected={currentVideoTap?._id === vt._id}
									onClick={() => setCurrentTapId(vt._id)}
								/>
							)
							:
							<p className={'h4 center'}>This lesson does not have Video Taps yet.</p>
						}
					</div>
				</div>
				:
				<p>This lesson do not have video yet.</p>
				
				
			}
			<VideoTapForm
				lessonId={lesson._id}
				isOpen={showVideoTapForm}
				setIsOpen={setShowVideoTapForm}
			/>
		</div>
	);
};
LessonVideoTapsRender.propTypes = {
	currentVideoTap: PropTypes.shape(TVideoTap),
	setCurrentTapId: PropTypes.func.isRequired,
	videoTaps: PropTypes.arrayOf(PropTypes.shape(TVideoTap)).isRequired,
	isUpdating :PropTypes.bool,
	updateError:PropTypes.object,

	lesson: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		videos:PropTypes.shape({
			url:PropTypes.string.isRequired,
			type:PropTypes.string.isRequired,
			thumbnail:PropTypes.shape({
				url:PropTypes.string.isRequired,
				type:PropTypes.string.isRequired,
			}),
		})
	}).isRequired,
	formState:PropTypes.shape({
		start : PropTypes.number,
		end : PropTypes.number,
		sparkLibrary : PropTypes.string,
		tag : PropTypes.string,
	}),

	handleOnchange:PropTypes.func.isRequired,
	onSubmit:PropTypes.func.isRequired,
};