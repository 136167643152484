import Modal from '../../../../components/ui/Modal/Modal';
import {SchoolFindMany, UsersFindOneById} from '../../../../hoc';
import Button from '../../../../components/ui/Button';
import PropTypes from 'prop-types';

export const AddSchoolToTeacherFormRender = ({
	currentUserId,
	isOpenState,
	isLoading,
	values,
	errors,
	handleOnChange,
	onSubmit
}) => {
	const [isFormOpen, setIsFormOpen] = isOpenState;

	return (
		<Modal isOpen={isFormOpen} setIsOpen={setIsFormOpen}>
			<div className={'w-600px flex flex-col items-center w-full'}>
				<h2>Edit User:</h2>
				<UsersFindOneById id={currentUserId._id}>
					{(userData) => (
						<form className={'w-full items-center gap-2'} onSubmit={onSubmit}>
							<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
								<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
									Select one School to assign
									to <strong>{userData.firstName} {userData.lastName}</strong>
								</label>
								<SchoolFindMany>
									{(schools) => (
										<select
											name='classLinkId'
											value={values.classLinkId}
											onChange={(e) => handleOnChange(e.target.name, e.target.value)}
											className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
											required
										>
											<option value=''>Select a School</option>
											{schools.map((school) => (
												<option key={school._id} value={school._id}>
													{school.name}, {school.state}
												</option>
											))}
										</select>
									)}
								</SchoolFindMany>
							</div>
							<Button
								disabled={isLoading || !values.classLinkId}
								type={'submit'}
								className={`${isLoading || !values.classLinkId ? 'bg-purple-400' : 'bg-brightLilac-default'}`}
							>
								Update
							</Button>
							{errors.submit ? <p className={'text-red-500'}>Error updating user</p> : null}
						</form>
					)}
				</UsersFindOneById>
			</div>
		</Modal>
	);
};

AddSchoolToTeacherFormRender.propTypes = {
	currentUserId: PropTypes.string.isRequired,
	isOpenState: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	values: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		userName: PropTypes.string,
		email: PropTypes.string,
		classLinkId: PropTypes.string,
	}).isRequired,
	errors: PropTypes.shape({
		submit: PropTypes.object,
	}),
	handleOnChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};