import PropTypes from 'prop-types';

export const svgPropTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	background: PropTypes.string,
	className: PropTypes.string,
	style: PropTypes.object,
	isActive: PropTypes.bool
};

export const TVideoTap = {
	_id: PropTypes.string.isRequired,
	lesson: PropTypes.string.isRequired,
	start: PropTypes.number.isRequired,
	end: PropTypes.number.isRequired,
	sparkLibrary: PropTypes.string.isRequired,
	tag: PropTypes.string.isRequired,
	deletedAt: PropTypes.string,
	createdAt: PropTypes.string.isRequired,
	updatedAt: PropTypes.string.isRequired,
};