
import {useQuery} from '@apollo/client';
import PropTypes from 'prop-types';
import {usersQueries} from '../pages/Users/actions/Users.queries';

export const UserFindOneByIdOnlyName = ({ id }) => {
	if (!id) return 'user not found';
	const { data, loading, error } = useQuery(usersQueries('usersFindOne'), {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	if(loading) return null;

	if (
		error ||
		!data ||
		!data.UsersFindOne ||
		(error && error.message.includes('User not found'))
	) {
		return 'user not found';
	}

	return `${data.UsersFindOne.firstName} ${data.UsersFindOne.lastName}`;
};

UserFindOneByIdOnlyName.propTypes = {
	id: PropTypes.string.isRequired,
};