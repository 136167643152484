import {SchoolCodeRender} from './SchoolCode.render';
import {Loader} from '../../components';
import {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {SchoolCodeQueries} from './actions/SchoolCode.queries';
import {useHistory} from 'react-router-dom';

export const SchoolCodesLoad = () => {
	const [limit, setLimit] = useState(100);
	const [skip, setSkip] = useState(0);
	const history = useHistory();
	const [allSchoolsCodes, setAllSchoolsCodes] = useState([]);
	const [allSchoolsCodes2, setAllSchoolsCodes2] = useState([]);
	const [sort, setSort] = useState('CREATEDAT_DESC');
	const [allElements, setAllElements] = useState([]);

	const { loading, error, data, refetch } = useQuery(SchoolCodeQueries('SchoolCodeFindMany'), {
		variables: {
			filter: { deletedAt: null },
			skip: 0,
			limit: 8000,
			sort: sort !== '' ? sort : 'CREATEDAT_DESC'
		},
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setAllElements(data?.SchoolCodeFindMany || []);
		},
	});

	const totalSchoolCodes = allSchoolsCodes2.length || 0;

	useEffect(() => {
		refetch().then(r => '');
	}, [skip, limit, sort, refetch]);


	const paginatedData = allElements.slice(skip, skip + limit);
	const currentPage = skip / limit + 1;
	const totalPages = Math.ceil(allElements.length / limit);

	const hasNextPage = skip + limit < totalSchoolCodes;

	const handleNextPage = () => {
		if ((skip + limit) < allElements.length) {
			setSkip(skip + limit);
		}
	};

	const handlePreviousPage = () => {
		if (skip > 0) {
			setSkip(skip - limit);
		}
	};

	const handleLimitChange = (newLimit) => {
		setLimit(newLimit);
		setSkip(0);
	};

	if (loading) return <Loader />;
	if (error) return <p>Error: {error.message}</p>;

	return (
		<SchoolCodeRender
			schoolCodes={paginatedData}
			totalSchoolCodes={allElements.length}
			skip={skip}
			limit={limit}
			setLimit={handleLimitChange}
			onNextPage={handleNextPage}
			onPreviousPage={handlePreviousPage}
			totalPages={totalPages}
			currentPage={currentPage}
			allSchoolCodes={allElements.length}
			handleEditClick={(id) => history.push(`/school-codes/${id}`)}
			handleCreateClick={() => history.push(`/school-codes/create`)}
			setSort={setSort}
			sort={sort}
		/>
	);
};