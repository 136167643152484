import {query} from '../../actions/queries';
import {useQuery} from '@apollo/client';
import {useHistory} from 'react-router-dom';
import {Loader, Search} from '../../components';
import {skillSetDataMock} from '../../test/mock/skillSetDataMock';
import {
	SkillSetPageContainer,
	SkillSetStyledGrid,
	SkillSetTitle, SkillSetWrapperElements,
	SkillSetWrapperElementsAddOne
} from './SkillSet.style';
import {PencilIcon} from '../../icons';

export const SkillSet = () => {

	const history = useHistory();

	const { loading, error, data } = useQuery(query('skillSetFindMany'), {
		variables: { limit: 1000 },
		nextFetchPolicy: 'network-only'
	});

	const sortedData = data ? data.SkillSetFindMany : skillSetDataMock;

	if (loading) return <div><Loader/></div>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;

	return (
		<SkillSetPageContainer className={'m-2'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<SkillSetTitle className={'font-sans'}>Skill Sets</SkillSetTitle>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<SkillSetStyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<SkillSetWrapperElementsAddOne
					className={'flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer'}
					onClick={() => history.push('/skillSet/create')}>
					<div className={'flex gap-3 align-center justify-center'}>
						<span className={'font-sans text-base f-600 text-white'}>Add Skill Set</span>
					</div>
					<div className={'flex gap-3 align-center justify-center'}>
						<div className={'flex align-center justify-center'}>
							<span className={'font-sans'}>+</span>
						</div>
					</div>
				</SkillSetWrapperElementsAddOne>
				{sortedData.map(({ _id, label, icon, description }) => (
					<SkillSetWrapperElements
						key={_id}
						className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
						onClick={() => history.push(`/skillSet/${_id}`)}>
						<div className={'flex justify-start'}>
							<div className={'grid'}>
								<span className={'font-sans text-base f-600 text-white'}>{label}</span>
								<p className={'font-mono text-sm truncate overflow-ellipsis'}
								   style={{color:'rgba(174, 185, 225, 1)'}}
								>{description ? description : 'No description yet'}</p>
							</div>
						</div>
						<div className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
							<PencilIcon className={'ml-auto'} />
						</div>
					</SkillSetWrapperElements>
				))}
			</SkillSetStyledGrid>
		</SkillSetPageContainer>
	);
};