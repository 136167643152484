import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {coursesQueries} from '../actions/Courses.queries';
import {useMutation} from '@apollo/client';
import {coursesMutations} from '../actions/Courses.mutations';
import { UsersFindMany, ThemesFindMany, LifeSkillFindMany, SkillSetFindMany} from '../../../hoc';
import {uploadCoursesCover, UploadCurriculumTrailer} from '../actions/Coures.rest';
import {TagsSelector} from '../../../components';
import {ArrowIndicatorIcon, ImageIcon} from '../../../icons';
import {UploadSparkLibraryClosedCaptions} from '../../SparkLibraries/actions/sparkLibraries.rest';

export const CoursesCreate = () => {
	const history = useHistory();
	const [formState, setFormState] = useState({
		title: '',
		description: '',
		teacher: '',
		active: false,
		hidden: false,
		slug: '',
		animalCategory: '',
		leaderBio: '',
		learningGoal: '',
		lifeSkill: [],
		skillSet: [],
		theme: [],
		cover: { url: '', type: '' },
		loop: { url: '', type: '' },
		logo: { url: '', type: '' },
		trailer: { url: '', type: '' },
		documents: [],
		language: {
			spanish: { title: '', description: '', identifier: '', label: '' },
			english: { title: '', description: '', identifier: '', label: '' },
			french: { title: '', description: '', identifier: '', label: '' },
		},
		resources: [],
		donation: false,
	});

	const [subtitleState, setSubtitleState] = useState({
		file: null,
		error: '',
		loading: false,
	});

	const [selectedLifeSkills, setSelectedLifeSkills] = useState([]);
	const [selectedSkillSets, setSelectedSkillSets] = useState([]);
	const [selectedThemes, setSelectedThemes] = useState([]);
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState(null);
	const [trailer, setTrailer] = useState(null);
	const [trailerPreview, setTrailerPreview] = useState(null);
	const [captions, setCaptions] = useState(null);

	const [createCourse, { loading: isLoading, error: isError }] =
		useMutation(coursesMutations('curriculumsCreateOne'), {
			refetchQueries: [{query: coursesQueries('curriculumsFindMany')}],
			awaitRefetchQueries: true
		});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleRadioChange = (e) => {
		const { value } = e.target;
		setFormState((prev) => ({ ...prev, active: value === 'true' }));
	};

	const handleRadioChangeDonation = (e) => {
		const { value } = e.target;
		setFormState((prev) => ({ ...prev, donation: value === 'true' }));
	};

	const handleFileChange = (e) => {
		const file = e.target.files[0];
		setSelectedFile(file);
		setPreview(URL.createObjectURL(file));
	};

	const handleTrailerChange = (e) => {
		const file = e.target.files[0];

		if (file) {
			setTrailer(file);
			setTrailerPreview(URL.createObjectURL(file));
		} else {
			setTrailer(null);
			setTrailerPreview(null);
		}
	};

	const handleCaptions = (e) => {
		const file = e.target.files[0];
		if(file){
			setCaptions(file);
		} else {
			setCaptions(null);
		}
	};

	const UploadCoursesTrailerClosedCaptions = (file, id, available, language) => {
		setSubtitleState(prev => ({ ...prev, loading: true }));
		UploadSparkLibraryClosedCaptions(file, id, available, language)
		.then(refetch)
		.catch(e => setSubtitleState(prev => ({ ...prev, error: e })))
		.finally(() => setSubtitleState(prev => ({
			...prev,
			loading: false,
			url: URL.createObjectURL(file)
		})));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await createCourse({
				variables: {
					record: {
						...formState,
						lifeSkill: selectedLifeSkills.map(skill => skill._id),
						skillSet: selectedSkillSets.map(skill => skill._id),
						theme: selectedThemes.map(theme => theme._id)
					},
				},
			});

			const createdCourseId = data.CurriculumsCreateOne?.record?._id;

			if (createdCourseId) {
				if (selectedFile) {
					await uploadCoursesCover(selectedFile, createdCourseId);
				}

				if (trailer) {
					await UploadCurriculumTrailer(trailer, createdCourseId);
				}

				if(subtitleState.file){
					UploadCoursesTrailerClosedCaptions(
						subtitleState.file,
						createdCourseId,
						true,
						formState.subtitleLanguage
					);
				}

				history.push('/courses');
			} else {
				const errorMessage = data.CurriculumsCreateOne?.error?.message || 'Failed to create course';
				console.error('Unexpected error creating course:', errorMessage);
			}
		} catch (error) {
			console.error('Unexpected error creating course:', error.message);
		}
	};

	const handleOnChangeFile = ( {target} ) =>{
		setSubtitleState((prevState) => ({ ...prevState, file }));
	};

	const allFieldsFilled = formState.title && formState.description && formState.teacher && formState.slug;

	return (
		<div className={'w-100 h-100'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'} />
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create Course</h1>
			<div className={'flex gap-2 justify-center align-center rounded-lg'}
				 style={{backgroundColor: 'rgba(11, 23, 57, 1)', border: '1px solid rgba(52, 59, 79, 1)'}}>
				<form className={'p-5 w-100 flex direction-row justify-start align-center gap-7'}
					  onSubmit={handleSubmit}>
					<div className={'w-100'}>
						<div className={'mb-4 flex gap-2 justify-center align-center'}>
							<label htmlFor='file-upload'
								   className={'w-45 h-45 cursor-pointer flex align-center justify-center direction-column'}>
								<div className={'flex justify-center align-center w-44 h-44 rounded-full'}
									 style={{backgroundColor: 'rgba(52, 59, 79, 1)'}}>
									{preview ? (
										<img src={preview} alt='Preview'
											 className={'rounded-full w-44 h-44 object-cover'}/>
									) : (
										<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
									)}
								</div>
								<span
									className={'font-sans mt-3 ml-3 text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
									Upload Image
								</span>
								<input
									id='file-upload'
									type='file'
									accept='image/*'
									onChange={handleFileChange}
									className={'hidden'}
								/>
							</label>
							<div className={'w-full ml-4'}>
								<div
									className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
									<label
										className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Title</label>
									<input
										type='text'
										name='title'
										value={formState.title}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									/>
								</div>
								<div
									className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
									<label
										className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Description</label>
									<textarea
										name='description'
										value={formState.description}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									/>
								</div>
							</div>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Teacher</label>
							<UsersFindMany>
								{users => (
									<select
										name='teacher'
										value={formState.teacher}
										onChange={handleChange}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									>
										<option value=''>Select a Teacher</option>
										{users.map(user => (
											<option key={user._id} value={user._id}>
												{user.firstName} {user.lastName}
											</option>
										))}
									</select>
								)}
							</UsersFindMany>
						</div>
						<div className={'w-100 flex mb-4 direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark self-start'}>Set
								Status in your Curriculum:</label>
						</div>
						<div className={'flex justify-center align-center gap-5 mb-4'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Active</label>
							<input
								type='radio'
								name='active'
								value='true'
								checked={formState.active === true}
								onChange={handleRadioChange}
								className={'font-mono p-5'}
							/>
							<label className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Inactive</label>
							<input
								type='radio'
								name='active'
								value='false'
								checked={formState.active === false}
								onChange={handleRadioChange}
								className={'font-mono p-5'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label
								className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Slug</label>
							<input
								type='text'
								name='slug'
								value={formState.slug}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Leader
								Bio</label>
							<textarea
								name='leaderBio'
								value={formState.leaderBio}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Learning
								Goal</label>
							<textarea
								name='learningGoal'
								value={formState.learningGoal}
								onChange={handleChange}
								className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
							/>
						</div>
						<div className={'w-100 flex mb-4 direction-column justify-start align-center self-start'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark self-start'}>Set
								Status for Donations in your Curriculum:</label>
						</div>
						<div className={'flex justify-center align-center gap-5 mb-4'}>
							<label className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Donation
								Enabled</label>
							<input
								type='radio'
								name='donation'
								value='true'
								checked={formState.donation === true}
								onChange={handleRadioChangeDonation}
								className={'font-mono p-5'}
							/>
							<label className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Donation
								Disabled</label>
							<input
								type='radio'
								name='donation'
								value='false'
								checked={formState.donation === false}
								onChange={handleRadioChangeDonation}
								className={'font-mono p-5'}
							/>
						</div>
						{/*
						<div className={'flex direction-column justify-center align-center self-center mb-4'}>
							<div className={'mb-4 flex direction-column gap-2 justify-center align-center'}>
								<LabelVideoContainer htmlFor='trailer-upload'>
									<VideoWrapper>
										{trailerPreview ? (
											<VideoElement src={trailerPreview} controls poster={preview || ''}>
												<p>{formState.title}</p>
											</VideoElement>
										) : (
											<div
												className={'w-100 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<div
													className={'flex justify-center align-center p-2 w-44 h-44 overflow-hidden'}>
													<PlayIcon width={'3em'} height={'3em'}
															  className={'cursor-pointer'}/>
												</div>
											</div>
										)}
									</VideoWrapper>
									<span
										className={'font-sans f-600 mt-3 text-center text-text-light dark:text-text-dark active-nav-item-font-color cursor-pointer'}>
										{trailerPreview ? 'Edit Trailer' : 'Upload Trailer'}
									</span>
									<input
										id='trailer-upload'
										type='file'
										accept='video/mp4'
										onChange={handleTrailerChange}
										className={'hidden'}
									/>
								</LabelVideoContainer>
							</div>
							<Input
								label='Upload Closed Captions'
								type='file'
								name='subtitles'
								accept='.vtt'
								onChange={onChangeFile}
								className='file:bg-brightLilac-default file:text-white file:py-2 file:px-4 file:rounded file:cursor-pointer file:font-mono'
							/>
						</div>
						*/}
						<TagsSelector
							selectedTags={selectedLifeSkills}
							setSelectedTags={setSelectedLifeSkills}
							HOC={LifeSkillFindMany}
							label='Life Skills'
						/>
						<TagsSelector
							selectedTags={selectedSkillSets}
							setSelectedTags={setSelectedSkillSets}
							HOC={SkillSetFindMany}
							label='Skill Sets'
						/>
						<TagsSelector
							selectedTags={selectedThemes}
							setSelectedTags={setSelectedThemes}
							HOC={ThemesFindMany}
							label='Themes'
						/>
						<div className={'flex center justify-center align-center mt-6'}>
							<button
								type='submit'
								disabled={!allFieldsFilled && isLoading}
								className={`flex mb-3 self-center align-center justify-center button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default ${
									!allFieldsFilled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
								}`}
							>
								{isLoading ? 'Creating Course...' : 'Create Course'}
							</button>
						</div>
						{isError &&
							<p className={'text-red-500'}>{isError.message ? isError.message : 'An error occurred'}</p>}
					</div>
				</form>
			</div>
		</div>
	);
};