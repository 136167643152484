import React from 'react';
import PropTypes from 'prop-types';
import {DistrictDetailsTableMainWrapper} from '../DistrictDetails.style';
import {SchoolFindOneById, UserFindOneNameByID} from '../../../../hoc';

export const GroupListDetails = ({ groupData, onFilterChange, selectedFilter }) =>{
	return (
		<div className={'w-full h-full p-5 text-text-light dark:text-text-dark m-2 text-base font-medium'}>
			<div className={'mt-4'}>
				<h1 className={'text-3xl mb-4'}>Group Details</h1>
				<label htmlFor='user-type-filter' className={'mr-2'}>Filter by:</label>
				<select
					id='user-type-filter'
					style={{backgroundColor: 'rgba(10, 19, 48, 1)', border: '1px solid rgba(11, 23, 57, 1)'}}
					value={selectedFilter}
					onChange={(e) => onFilterChange(e.target.value)}
					className={'font-sans f-500 border rounded p-2'}
				>
					<option value=''>All</option>
					<option value='teachers'>Teachers</option>
				</select>
			</div>
			<div className={'mt-4'}>
				{groupData.length > 0 ? (
					<div className='rounded-lg overflow-hidden mt-4' style={{border: '1px solid rgba(52, 59, 79, 1)'}}>
						<DistrictDetailsTableMainWrapper className='w-full rounded-lg'
														 style={{
															 borderCollapse: 'collapse',
															 boxDecorationBreak: 'unset'
														 }}>
							<thead>
								<tr>
									<th className={'left font-mono main-color-background p-2 t-white'}>
										<span className={'font-mona f-600 text-sm text-white'}>Name</span>
									</th>
									<th className={'left font-mono main-color-background p-2 t-white'}>
										<span className={'font-mona f-600 text-sm text-white'}>Manager</span>
									</th>
									<th className={'left font-mono main-color-background p-2 t-white'}>
										<span className={'font-mona f-600 text-sm text-white'}>School</span>
									</th>
									<th className={'left font-mono main-color-background p-2 t-white'}>
										<span className={'font-mona f-600 text-sm text-white'}>Grade</span>
									</th>
									<th className={'left font-mono main-color-background p-2 t-white'}>
										<span className={'font-mona f-600 text-sm text-white'}>Code</span>
									</th>
									<th className={'left font-mono main-color-background p-2 t-white'}>
										<span className={'font-mona f-600 text-sm text-white'}>Created At</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{groupData.map((group) => (
									<tr key={group._id} className={'cursor-pointer'}>
										<td className={'justify-start items-center text-start p-2'}>{group.name}</td>
										<td className={'justify-start items-center text-start p-2'}>
											<UserFindOneNameByID _id={group.manager}/>
										</td>
										<td className='justify-start items-center text-start p-2'>
											<SchoolFindOneById _id={group.school}/>
										</td>
										<td className={'justify-center items-center text-center p-2'}>{group.grade}</td>
										<td className={'justify-start items-center text-start p-2'}>{group.code}</td>
										<td className={'justify-start items-center text-start p-2'}>{new Date(group.createdAt).toLocaleDateString()}</td>
									</tr>
								))}
							</tbody>
						</DistrictDetailsTableMainWrapper>
					</div>
				) : (
					<p className={'text-center mt-4'}>No groups found in this Organization.</p>
				)}
			</div>
		</div>
	);
};

GroupListDetails.propTypes = {
	groupData: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		manager: PropTypes.string,
		name: PropTypes.string.isRequired,
		school: PropTypes.string,
		grade: PropTypes.string,
		code: PropTypes.string,
		deleted: PropTypes.bool,
		classes: PropTypes.arrayOf(
			PropTypes.shape({
				unLock: PropTypes.bool,
				id: PropTypes.string.isRequired,
			})
		),
		deletedAt: PropTypes.string,
		organization: PropTypes.string,
		classLink: PropTypes.string,
		curriculums: PropTypes.arrayOf(PropTypes.string),
		createdAt: PropTypes.string,
		updatedAt: PropTypes.string,
	}),
	onFilterChange: PropTypes.func.isRequired,
	selectedFilter: PropTypes.string,
};