import gql from 'graphql-tag';

const queries = {
	//Find Many:
	curriculumsFindMany: gql`
        query CurriculumsFindMany($filter: FilterFindManycurriculumsInput, $skip: Int, $limit: Int, $sort: SortFindManycurriculumsInput) {
            CurriculumsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
                _id
                title
                description
                hidden
                slug
                teacher
                animalCategory
                documents {
                    title
                    url
                    type
                }
                language {
                    spanish {
                        title
                        description
                        identifier
                        label
                    }
                    english {
                        title
                        description
                        identifier
                        label
                    }
                    french {
                        title
                        description
                        identifier
                        label
                    }
                    _id
                }
                leaderBio
                active
                cover {
                    url
                    type
                }
                loop {
                    url
                    type
                }
                logo {
                    url
                    type
                }
                trailer {
                    url
                    type
                }
                learningGoal
                lifeSkill
                skillSet
                theme
                donation
                resources {
                    _id
                    url
                    type
                    title
                }
                createdAt
                updatedAt
            }
        }
	`,
	//Find One:
	CurriculumsFindOne: gql`
        query CurriculumsFindOne($id: String!) {
            CurriculumsFindOne(filter: { _id: $id }) {
                _id
                title
                description
                hidden
                slug
                teacher
                animalCategory
                documents {
                    title
                    url
                    type
                }
                language {
                    spanish {
                        title
                        description
                        identifier
                        label
                    }
                    english {
                        title
                        description
                        identifier
                        label
                    }
                    french {
                        title
                        description
                        identifier
                        label
                    }
                    _id
                }
                leaderBio
                active
                cover {
                    url
                    type
                }
                loop {
                    url
                    type
                }
                logo {
                    url
                    type
                }
                trailer {
                    url
                    type
                }
                learningGoal
                lifeSkill
                skillSet
                theme
                donation
                resources {
                    _id
                    url
                    type
                    title
                }
                createdAt
                updatedAt
            }
        }
	`
	,
};

export const coursesQueries = (value) => {
	return queries[value];
};