import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const SchoolFindOneById = ({ _id }) => {
	const { data, loading, error } = useQuery(query('schoolFindOne'), {
		variables: { filter: { _id } },
	});

	if (loading) return null;
	if (error) return <span>Error loading school name</span>;

	return <span>{data?.SchoolFindOne?.name || 'School not found'}</span>;
};

SchoolFindOneById.propTypes = {
	_id: PropTypes.string.isRequired
};