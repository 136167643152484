import React from 'react';
import Button from '../../../../components/ui/Button';
import {CoursesTableMainWrapper} from '../../../Courses/Courses.style';
import PropTypes from 'prop-types';
import {StyledTableRow} from '../../../Users/Users.style';
import {formatDateString, formatDecimal} from '../../../../tools';
import {PencilIcon} from '../../../../icons';
import {VideoTapForm} from '../../components/VideoTapForm/VideoTapForm.load';
import {VideoTapEditForm} from '../../components/VideoTapForm/VideoTapFormEdit.load';
import { RiArrowRightDoubleFill } from 'react-icons/ri';
import {Link, useLocation} from 'react-router-dom';
import {LessonVideoTaps} from '../../../../components';

export const VideoTapsViewRender = ({lesson, selectedVideoTapId,}) => {
	const {pathname} = useLocation();
	return <div className={'w-full px-4 text-white'}>
		<LessonVideoTaps lesson={lesson}/>
	</div>;
};

VideoTapsViewRender.propTypes = {
	lesson: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		videos:PropTypes.shape({
			url:PropTypes.string.isRequired,
			type:PropTypes.string.isRequired,
			thumbnail:PropTypes.shape({
				url:PropTypes.string.isRequired,
				type:PropTypes.string.isRequired,
			}),
		})
	}).isRequired,

};