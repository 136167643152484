import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {LessonQueries} from '../../actions/Lessons.queries';
import {LessonsMutations} from '../../actions/Lessons.mutations';
import {VideoTapFormRender} from './VideoTapForm.render';
import Modal from '../../../../components/ui/Modal/Modal';
import PropTypes from 'prop-types';

export const VideoTapForm = ({ lessonId ,isOpen, setIsOpen }) => {
	const [formState, setFormState] = useState({
		start: 0,
		end: 0,
		sparkLibrary: '',
		tag: '',
	});

	const endError = formState.start && (parseFloat(formState.end) <= parseFloat(formState.start));

	const [VideoTapsCreateOne, {loading: isLoadingVideTapsCreateOne, error: isErrorVideTapsCreateOne}] =
		useMutation(LessonsMutations('VideoTapsCreateOne'), {
			refetchQueries: [{query: LessonQueries('VideoTapsFindMany')}],
			awaitRefetchQueries: true
		});

	const onSubmit = async (e) => {
		e.preventDefault();
		if (endError) {
			return;
		}
		try {
			await VideoTapsCreateOne({
				variables: {
					record: {
						lesson: lessonId,
						start: parseFloat(formState.start),
						end: parseFloat(formState.end),
						sparkLibrary: formState.sparkLibrary,
						tag: formState.tag,
					},
				},
			});
			setFormState({start: 0, end: 0, sparkLibrary: '', tag: '',});
			setIsOpen(false);
		} catch (error) {
			console.log('Error creating VideoTaps: ', error.message);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const allFieldsFilled =
		Object.values(formState).every((field) => field !== '');

	return <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
		<VideoTapFormRender
			formState={formState}
			onSubmit={onSubmit}
			handleChange={handleChange}
			isLoading={isLoadingVideTapsCreateOne}
			isError={isErrorVideTapsCreateOne}
			close={()=>setIsOpen(false)}
			allFieldsFilled={allFieldsFilled}
			endError={endError}
		/>
	</Modal>;
};

VideoTapForm.prototype = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};