import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {query} from '../../../actions/queries';
import {UserInfoRender} from './UserInfoRender';
import {usersMutations} from '../actions/Users.mutations';

export const UserInfoLoad = () => {
	const { id } = useParams();
	const [selectedTab, setSelectedTab] = useState('personal-information');
	const [userData, setUserData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		profilePicture: { url: '' },
		phone: '',
		position: '',
		location: '',
		website: ''
	});

	const [isChanged, setIsChanged] = useState(false);

	const { data, loading, error } = useQuery(query('UsersFindMany'), {
		variables: { filter: { _id: id }, sort: '_ID_DESC' },
	});

	const [ UpdateUser, { loading: isLoading } ] = useMutation(usersMutations('userUpdateOne'),{
		refetchQueries: [{ query: query('UsersFindMany') }],
		awaitRefetchQueries: true
	});

	useEffect(() => {
		const user = data?.UsersFindMany?.[0];
		if (user) {
			setUserData(user);
			setIsChanged(false);
		}
	}, [data]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setUserData({
			...userData,
			[name]: value,
		});
		setIsChanged(true);
	};

	const menu2 = [
		{
			to: 'personal-information',
			name: 'Personal Information',
			icon: 'PencilIcon',
			component: 'PersonalInfoRender'
		},
		{
			to: 'schools',
			name: 'Schools',
			icon: 'UserIcon',
			component: 'TeamRender'
		},
		{
			to: 'organizations',
			name: 'Organization',
			icon: 'LocationIcon',
			component: 'Organization'
		}
	];

	const handleSubmit = async ( e ) => {
		e.preventDefault();
		try {
			await UpdateUser({
				variables: {
					_id: id,
					record: {
						firstName: userData.firstName,
						lastName: userData.lastName,
						email: userData.email,
					}
				}
			});
			//history.push('/users/all');
			setIsChanged(false);
		} catch (error) {
			console.error('Error Updating User: ', error.message);
			console.error('Full Error Object: ', error);
		}
	};

	const allFieldsFilled = !!(userData.firstName || userData.email || userData.lastName);

	return (
		<UserInfoRender
			handleSubmit={handleSubmit}
			menu2={menu2}
			selectedTab={selectedTab}
			setSelectedTab={setSelectedTab}
			userData={userData}
			handleChange={handleChange}
			loading={isLoading}
			error={error}
			allFieldsFilled={allFieldsFilled}
			isChanged={isChanged}
		/>
	);
};