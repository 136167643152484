import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const CurriculumsFindManyByArrayIds = ({arrayOfCurriculums , children}) =>{
	const { data, loading, error } = useQuery(query('curriculumsFindMany'), {
		variables: {
			filter: { _operators: { _id: { in: arrayOfCurriculums } } },
			sort: '_ID_DESC',
		},
		fetchPolicy: 'network-only',
		skip: !arrayOfCurriculums || arrayOfCurriculums.length===0,
	});
	if (loading || error) return null;

	const curriculums = data?.CurriculumsFindMany || [];
	return children(curriculums);
};

CurriculumsFindManyByArrayIds.prototype = {
	arrayOfCurriculums: PropTypes.arrayOf(PropTypes.string),
	children: PropTypes.func.isRequired,
};

CurriculumsFindManyByArrayIds.defaultProps = {
	arrayOfCurriculums: [],
};