import {query} from '../../actions/queries';
import {useQuery} from '@apollo/client';
import {Loader} from '../../components';
import {PinRender} from './PinRender';

export const PinLoad = () => {
	const { loading, error, data, refetch } = useQuery(query('pinFindMany'),{
		variables: {},
		nextFetchPolicy: 'network-only'
	});

	if (loading) return <div><Loader/></div>;
	if (error) return <p className={'font-mona f-900 white p-3'}>Error</p>;
	if(!data || !data.PinFindMany) return <p className={'font-mona f-900 white p-3'}>No Data</p>;

	return <PinRender Pins={data ? data.PinFindMany : []} />;
};