import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import PropTypes from 'prop-types';

export const DistrictFindOneByOrganization = ({organization, children}) => {
	const { loading, error, data } = useQuery(query('districtFindOne'), {
		variables: { filter: { organization } },
	});

	if(loading || error || !data || !data.DistrictFindOne) return null;

	return children(data.DistrictFindOne);
};

DistrictFindOneByOrganization.propTypes = {
	organization: PropTypes.string.isRequired,
	children: PropTypes.func.isRequired,
};