import React, {useEffect, useRef, useState} from 'react';
import multiRangeStyle from'./MultiRange.module.css';

export const MultiRangeRender = ({
	name, value, className,
	height ='1rem',
	min=0,
	max= min+100,
	label,
	onChange,
	onLabel,
}) => {
	useEffect(() => {
		setCurrentValue(value);
		if (left.current){
			left.current.value = value[0];
		}
		if (right.current){
			right.current.value = value[1];
		}
	}, [value]);

	const left =useRef(null);
	const right =useRef(null);

	const [currentValue,setCurrentValue] =useState(value||[min,max]);
	const leftPercentage =  currentValue[0]/(max-min) *100;
	const rightPercentage =  currentValue[1]/(max-min) *100;
	const handleOnChange= ({target})=>{
		let newValueArray = [ ...currentValue ];
		let value = parseInt(target.value);
		if (target.name === 'left'){
			if (value >= currentValue[1]){
				value = currentValue[1]-1;
				left.current.value=value;
			}
			newValueArray[0]=value;
		}else {
			if (value <= currentValue[0]){
				value = currentValue[0]+1;
				right.current.value=value;
			}
			newValueArray[1]=value;
		}
		setCurrentValue(prev=>newValueArray );
		onChange && onChange({name,value:newValueArray});
	};

	const handleMouseUP= ({target})=>{
		console.log({value:target.value, name:target.name});
		if (target.name === 'left'){

		}
	};

	const maxLabel = onLabel ? onLabel(max) : max.toString();
	const leftLabel = onLabel ? onLabel(currentValue[0]) : currentValue[0].toString();
	const rightLabel = onLabel ? onLabel(currentValue[1]) : currentValue[1].toString();

	return (
		<div className={`w-full mb-2] ${className}`}
			style={{height}}
		>
			{label && <label>{label}</label>}

			<div className='w-full h-full'>
				<div className={'bg-midnight-lighter w-full h-full rounded overflow-x-hidden'}>
					<div className={' w-full h-full '} style={{transform : `translateX(${leftPercentage}%)`,}}>
						<div
							className={'w-500px h-full bg-green-300'}
							style={{

								width :`${rightPercentage-leftPercentage}%`
							}}
						/>
					</div>
				</div>
				{/* Limit values */}
				<div className={'w-full relative'}>
					<p className={'absolute w-full'} style={{transform:`translateX(${leftPercentage}%) translateX(-2.5rem)`}}>{leftLabel}</p>
					<p  className={'absolute w-full'} style={{transform:`translateX(${rightPercentage}%) translateX(0.5rem)`}}>{rightLabel}</p>
				</div>
				<div className='relative w-full h-0'>

					<input
						ref={left}

						className={`absolute w-full h-full bg-transparent appearance-none pointer-events-none ${multiRangeStyle.range} ${multiRangeStyle.rangeLeft}`}
						name='left'
						min={min}
						max={max}
						type='range'
						onChange={handleOnChange}
					/>
					<input
						ref={right}
						className={`absolute w-full h-full bg-transparent appearance-none ${multiRangeStyle.range} ${multiRangeStyle.rangeRight}`}
						name='right'
						min={min}
						max={max}
						type='range'
						onChange={handleOnChange}
					/>
				</div>

			</div>
		</div>
	);
};
