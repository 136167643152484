import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {DistrictFindOneByOrganization} from '../../../../hoc';
import PropTypes from 'prop-types';
import {DistrictForm} from '../../../District/forms';
import Modal from '../../../../components/ui/Modal/Modal';
import {StyledTableRow} from '../../../Users/Users.style';

export const DistrictArrow = ({ organization, index }) => {
	const { name, code, _id } = organization;
	const history = useHistory();
	const [showEditForm, setShowEditForm] = useState(false);

	return (
		<DistrictFindOneByOrganization organization={_id}>
			{(district) => (
				<>
					<Modal isOpen={showEditForm} setIsOpen={setShowEditForm}>
						<DistrictForm
							currentDistrict={organization}
							onSuccess={() => setShowEditForm(false)}
						/>
					</Modal>

					<StyledTableRow
						index={index}
						onClick={() => history.push(`/districts/${district._id}/organization/${_id}`)}
						className={'cursor-pointer'}
					>
						<td className={'p-2'}>
							<div className={'flex justify-start items-center p-1 gap-2'}>
								<span className={'font-mono text-text-light dark:text-text-dark text-xs'}>
									{name}
								</span>
							</div>
						</td>
						<td className={'justify-start items-center text-start p-2'}>
							<p className={'font-mono text-text-light dark:text-text-dark text-xs'}>
								{code || 'Not Available'}
							</p>
						</td>
						<td className={'justify-start items-center text-start p-2'}>
							<p className={'font-mono text-text-light dark:text-text-dark text-xs'}>
								{district ? district.name : 'No Districts'}
							</p>
						</td>
					</StyledTableRow>
				</>
			)}
		</DistrictFindOneByOrganization>
	);
};

DistrictArrow.propTypes = {
	organization: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired,
		_id: PropTypes.string.isRequired,
	}).isRequired,
	index: PropTypes.number.isRequired,
};