import PropTypes from 'prop-types';
import {LessonsButton} from './LessonsTab.style';
import {Link} from 'react-router-dom';

export const LessonsTab = ({ label, to , selected}) => {
	return (
		<Link to={to}>
			<LessonsButton
				type='button'
				className={`font-mono px-4 py-2 mt-4 font-medium rounded transition-colors ${
					selected ? 'bg-[rgba(203,60,255,1)] text-white' : 'bg-[rgba(10,19,48,1)] text-gray-200'
				}`}
			>
				{label}
			</LessonsButton>
		</Link>
	);
};

LessonsTab.propTypes = {
	label: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
};

LessonsTab.defaultProps = {
	label: null,
	to: '#',
	selected: false,
};