import PropTypes from 'prop-types';

//filter types:
export const districtTeacher = 'e4475192-3c69-11ee-be56-0242ac120002';
export const districtStudent = 'cae9c4f0-b804-11eb-87d9-ab38d9aa58aa';

//District Render Types
export const DistrictDetailsRenderTypes = {
	DistrictDataArrayTeacher: PropTypes.shape({
		UsersByOrganizationFindMany: PropTypes.shape({
			users: PropTypes.arrayOf(
				PropTypes.shape({
					_id: PropTypes.string.isRequired,
					firstName: PropTypes.string.isRequired,
					lastName: PropTypes.string.isRequired,
					userName: PropTypes.string.isRequired,
					email: PropTypes.string.isRequired,
					profilePicture: PropTypes.shape({
						url: PropTypes.string,
						title: PropTypes.string,
					}),
					points: PropTypes.number,
					type: PropTypes.string.isRequired,
					lastLogin: PropTypes.string.isRequired,
					bio: PropTypes.string,
				})
			).isRequired,
			total: PropTypes.number,
		}).isRequired,
	}).isRequired,
	DistrictDataArrayStudent: PropTypes.shape({
		UsersByOrganizationFindMany: PropTypes.shape({
			users: PropTypes.arrayOf(
				PropTypes.shape({
					_id: PropTypes.string.isRequired,
					firstName: PropTypes.string.isRequired,
					lastName: PropTypes.string.isRequired,
					userName: PropTypes.string.isRequired,
					email: PropTypes.string.isRequired,
					profilePicture: PropTypes.shape({
						url: PropTypes.string,
						title: PropTypes.string,
					}),
					points: PropTypes.number,
					type: PropTypes.string.isRequired,
					lastLogin: PropTypes.string.isRequired,
					bio: PropTypes.string,
				})
			).isRequired,
			total: PropTypes.number,
		}).isRequired,
	}).isRequired,
	District: PropTypes.shape({
		DistrictFindOne: PropTypes.shape({
			_id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string.isRequired,
			country: PropTypes.string.isRequired,
			organization: PropTypes.string.isRequired,
			createdAt: PropTypes.string.isRequired,
			updatedAt: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	formState: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		organization: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired,
	}),
	history: PropTypes.object.isRequired,
	menu: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			icon: PropTypes.string.isRequired,
			component: PropTypes.string.isRequired,
		})
	).isRequired,
	selectedTab: PropTypes.string.isRequired,
	setSelectedTab: PropTypes.func.isRequired,
	handleFilterChange: PropTypes.func.isRequired,
	selectedType: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
	allFieldsFilled: PropTypes.bool,
	isChanged: PropTypes.bool.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

//District Information Types
export const DistrictInformationTypes = {
	formState: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		organization: PropTypes.string.isRequired,
		createdAt: PropTypes.string.isRequired,
		updatedAt: PropTypes.string.isRequired,
	}),
	history: PropTypes.object.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	isChanged: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
};

//District List Types
export const DistrictListTypes = {
	DistrictDataArrayTeacher: DistrictDetailsRenderTypes.DistrictDataArrayTeacher,
	DistrictDataArrayStudent: DistrictDetailsRenderTypes.DistrictDataArrayStudent,
	District: PropTypes.shape({
		DistrictFindOne: PropTypes.shape({
			_id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			state: PropTypes.string.isRequired,
			country: PropTypes.string.isRequired,
			organization: PropTypes.string.isRequired,
			createdAt: PropTypes.string.isRequired,
			updatedAt: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	history: PropTypes.object.isRequired,
	handleFilterChange: PropTypes.func.isRequired,
	selectedType: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
	error: PropTypes.any,
};