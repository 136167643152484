import {LessonVideoTapsRender} from './LessonVideoTaps.render';
import {useMutation, useQuery} from '@apollo/client';
import {LessonQueries} from '../../pages/LessonsEdit/actions/Lessons.queries';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {LessonsMutations} from '../../pages/LessonsEdit/actions/Lessons.mutations';

export const LessonVideoTapsLoad = ({lesson})=>{

	const [currentTapId,setCurrentTapId] = useState('');

	const { data: videoTapsData, loading: videoTapsLoading, error: videoTapsError } =
		useQuery(LessonQueries('VideoTapsFindMany'), {
			variables: { filter: { lesson: lesson._id }, skip: 0, limit: 100, sort: '_ID_ASC' },
		});
	const videoTapArray = videoTapsData ?.VideoTapsFindMany || [];
	useEffect(() => {
		if (videoTapArray.length ){
			setCurrentTapId(videoTapArray[0]._id);
		}
	}, [videoTapsData]);
	
	const currentVideoTap = videoTapArray.find(vt=>vt._id === currentTapId);
	/// video tap update form :
	
	const [formState, setFormState] = useState({
		start: currentVideoTap?.start,
		end: currentVideoTap?.end,
		sparkLibrary: currentVideoTap?.sparkLibrary,
		tag: currentVideoTap?.tag,
	});

	const handleOnchange= (name,value)=>{
		setFormState(prev=>({...prev,[name]:value}));
	};

	const [VideoTapsUpdateOne, { loading: isUpdating, error: updateError }] =
		useMutation(LessonsMutations('VideoTapsUpdateOne'), {
			refetchQueries: [{ query: LessonQueries('VideoTapsFindMany') }],
			awaitRefetchQueries: true,
		});
	useEffect(() => {
		if (currentTapId){
			setFormState({
				start: currentVideoTap?.start,
				end: currentVideoTap?.end,
				sparkLibrary: currentVideoTap?.sparkLibrary,
				tag: currentVideoTap?.tag,
			});
		}
	}, [currentTapId]);

	const onSubmit = async (e) => {
		e.preventDefault();
		console.log({formState});
		try {
			await VideoTapsUpdateOne({
				variables: {
					_id: currentTapId,
					record: {
						start: parseFloat(formState.start),
						end: parseFloat(formState.end),
						sparkLibrary: formState.sparkLibrary,
						tag: formState.tag,
					},
				},
			});
		} catch (error) {
			console.log('Error updating VideoTaps: ', error.message);
		}
	};
	
	return (
		videoTapsData ? <LessonVideoTapsRender
			lesson={lesson}
			videoTaps={videoTapArray}
			currentVideoTap={currentVideoTap}
			setCurrentTapId={setCurrentTapId}
			formState={formState}
			isUpdating={isUpdating}
			updateError={updateError}
			handleOnchange={handleOnchange}
			onSubmit={onSubmit}
		/> : null
	);
};


LessonVideoTapsLoad.propTypes = {
	lesson: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		videos:PropTypes.shape({
			url:PropTypes.string.isRequired,
			type:PropTypes.string.isRequired,
			thumbnail:PropTypes.shape({
				url:PropTypes.string.isRequired,
				type:PropTypes.string.isRequired,
			}),
		})
	}).isRequired,
};