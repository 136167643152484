import {SchoolsListTypes, schoolStudent, schoolTeacher} from '../SchoolsDetails.types';
import {SchoolDetailsTableMainWrapper} from '../SchoolDetails.style';
import {UserTopContainerInfo} from '../../../Users/Users.style';
import {DotsThreeIcon, UserIcon} from '../../../../icons';
import React, {useState} from 'react';
import {SchoolHistory} from './SchoolHistory';
import {Link} from 'react-router-dom';
import Modal from '../../../../components/ui/Modal/Modal';
import {TeacherClasses} from './TeacherClasses';

const groupUsersBySchool = (users) => {
	return users.reduce((groups, user) => {
		const schoolName = user.school?.name || 'No School';
		if (!groups[schoolName]) {
			groups[schoolName] = [];
		}
		groups[schoolName].push(user);
		return groups;
	}, {});
};

const teacher = schoolTeacher;
const student = schoolStudent;

export const SchoolsList = ({ teachersData, studentsData, School,history, handleFilterChange, selectedType }) => {
	const students = studentsData?.UsersBySchoolFindMany?.users || [];
	const teachers = teachersData?.UsersBySchoolFindMany?.users || [];
	const [ showTeacherClasses, setShowTeacherClasses ] = useState(false);
	const [ currentTeacher, setCurrentTeacher ] = useState(null);
	const filteredUsers = selectedType === schoolStudent ? students : teachers;
	const usersBySchool = groupUsersBySchool(filteredUsers);

	const handleSelectTeacher=(id) => {
		setCurrentTeacher(id);
		setShowTeacherClasses(true);
	};

	return (
		<div className={'w-full h-full p-5 text-text-light dark:text-text-dark m-2 text-base font-medium'}>
			<Modal isOpen={showTeacherClasses} setIsOpen={setShowTeacherClasses}>
				<TeacherClasses teacherId={currentTeacher}/>
			</Modal>
			<div className={'mt-4'}>
				<h1 className={'text-3xl mb-4'}>{School.name ? School.name : null}</h1>
				<label htmlFor='user-type-filter' className={'mr-2'}>Filter by Type:</label>
				<select
					id='user-type-filter'
					style={{backgroundColor: 'rgba(10, 19, 48, 1)', border: '1px solid rgba(11, 23, 57, 1)'}}
					value={selectedType}
					onChange={(e) => handleFilterChange(e.target.value)}
					className={'font-sans f-500 border rounded p-2'}
				>
					<option value={student}>Students</option>
					<option value={teacher}>Teachers</option>
				</select>
			</div>
			<div className={'flex justify-between align-center py-4'}>
				<UserTopContainerInfo className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
									  onClick={() => handleFilterChange(student)}>
					<div className={'flex justify-start'}>
						<div className={'flex justify-center items-center mr-2'}
							 style={{backgroundColor: 'rgba(5, 193, 104, 0.5)'}}>
							<UserIcon background={'rgba(5, 193, 104, 1)'}/>
						</div>
						<div className={'grid'}>
							<span className={'font-sans'}>Students</span>
							<span
								className={'font-sans'}>{studentsData?.UsersBySchoolFindMany.total || 0}</span>
						</div>
					</div>
					<div>
						<DotsThreeIcon className={'ml-auto'}/>
					</div>
				</UserTopContainerInfo>
				<UserTopContainerInfo className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
									  onClick={() => handleFilterChange(teacher)}>
					<div className={'flex justify-start'}>
						<div className={'flex justify-center items-center mr-2'}
							 style={{backgroundColor: 'rgba(253, 181, 42, 0.5)'}}>
							<UserIcon background={'rgba(253, 181, 42, 1)'}/>
						</div>
						<div className={'grid'}>
							<span className={'font-sans'}>Teacher</span>
							<span
								className={'font-sans'}>{teachersData.UsersBySchoolFindMany.total || 0}</span>
						</div>
					</div>
					<div>
						<DotsThreeIcon className={'ml-auto'}/>
					</div>
				</UserTopContainerInfo>
			</div>
			<SchoolHistory
				type ={selectedType}
			/>
			<div className={'mt-4'}>
				{Object.keys(usersBySchool).length > 0 ? (
					Object.keys(usersBySchool).map((school) => (
						<div key={school} className='school-group'>
							<div className={'rounded-lg overflow-hidden mt-4'}
								 style={{border: '1px solid rgba(52, 59, 79, 1)'}}>
								<SchoolDetailsTableMainWrapper className='w-full rounded-lg'>
									<thead>
										<tr>
											<th className={'font-mono main-color-background p-2'}>
												<span className={'font-mona f-600 text-sm text-white'}>Name</span>
											</th>
											<th className={'font-mono main-color-background p-2'}>
												<span className={'font-mona f-600 text-sm text-white'}>Email</span>
											</th>
											<th className={'font-mono main-color-background p-2'}>
												<span className={'font-mona f-600 text-sm text-white'}>Type</span>
											</th>
											<th className={'font-mono main-color-background p-2'}>
												<span className={'font-mona f-600 text-sm text-white'}>Points</span>
											</th>
											
											<th className={'font-mono main-color-background p-2'}>
												<span className={'font-mona f-600 text-sm text-white'}>Actions</span>
											</th>
										
										</tr>
									</thead>
									<tbody>
										{usersBySchool[school].map((user) => (
											<tr key={user._id} className={'cursor-pointer'}>
												<td className={'p-2'}>
													{user.firstName} {user.lastName}
												</td>
												<td className={'p-2'}>
													{user.email}
												</td>
												<td className={'p-2'}>
													{user.type === schoolTeacher ? 'Teacher' : 'Student'}
												</td>
												<td className={'p-2'}>
													{user.points}
												</td>
												<td className={'p-2'}>
													<div className={'w-full flex justify-center items-center gap-4'}>
														<Link
															className={' border rounded px-1 border-cloudyBlue-dark text-cloudyBlue-dark bg-cloudyBlue-light text-xs font-bold '}
															to ={`/usersInfo/${user._id}`}
														>
															See Details
														</Link>
														{ selectedType === teacher ?
															<button
																type={'button'}
																className={' border rounded px-1 border-cloudyBlue-dark text-cloudyBlue-dark bg-cloudyBlue-light text-xs font-bold '}
																onClick={()=>handleSelectTeacher(user._id)}
															> See Classes </button>
															: null
														}
													</div>
												</td>
											</tr>
										))}
									</tbody>
								</SchoolDetailsTableMainWrapper>
							</div>
							<div className={'flex justify-between mt-4'}>
								<p>Total {selectedType === schoolStudent ? 'students' : 'teachers'}: <strong
									style={{color: 'rgba(203, 60, 255, 1)'}}>{usersBySchool[school].length}</strong></p>
							</div>
						</div>
					))
				) : (
					<div className={'flex flex-col gap-6 justify-start align-center'}>
						<span className={'text-center'}>No users found.</span>
						<div className={'cursor-pointer'} onClick={() => history.push('schools/')}>
							<span className={'text-center f-700'}>Back to Schools.</span>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

SchoolsList.propTypes = SchoolsListTypes;