import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {LessonsMutations} from '../../actions/Lessons.mutations';
import {LessonQueries} from '../../actions/Lessons.queries';
import PropTypes from 'prop-types';
import Modal from '../../../../components/ui/Modal/Modal';
import {CheckInQuestionCreateRender} from './CheckInQuestionCreate.render';

export const CheckInQuestionCreateFormLoad = ({ lessonId , lesson_curriculum, isOpen , setIsOpen , refetchCheckInQuestions}) => {
	const [formState, setFormState] = useState({
		class: lessonId,
		label: '',
		start: false,
	});

	const [ CheckInQuestionCreateOne, { loading: CheckInQuestionCreateOneLoading, error: CheckInCreateOneError } ] =
		useMutation(LessonsMutations('CheckInQuestionCreateOne'), {
			refetchQueries: [{query: LessonQueries('CheckInQuestionFindMany')}],
			awaitRefetchQueries: true
		});

	const onSubmit = async (e) => {
		e.preventDefault();
		if(CheckInCreateOneError) return;
		try {
			await CheckInQuestionCreateOne({
				variables: {
					record: {
						curriculum: lesson_curriculum ? lesson_curriculum : '',
						class: lessonId,
						label: formState.label,
						start: formState.start,
					},
				},
			});
			setFormState({ class: '', label: '', start: false });
			setIsOpen(false);
			refetchCheckInQuestions();
		} catch (e) {
			console.log('Error creating CheckInQuestion: ', e.message);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({ ...prev, [name]: value }));
	};

	const handleRadioChange = (e) => {
		const { value } = e.target;
		setFormState((prev) => ({ ...prev, start: value === 'true' }));
	};

	const allFieldsFilled =
		Object.values(formState).every((field) => field !== '');

	return <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
		<CheckInQuestionCreateRender
			formState={formState}
			onSubmit={onSubmit}
			handleChange={handleChange}
			handleRadioChange={handleRadioChange}
			isLoading={CheckInQuestionCreateOneLoading}
			isError={CheckInCreateOneError}
			allFieldsFilled={allFieldsFilled}
			close={() => setIsOpen(false)}
		/>
	</Modal>;
};

CheckInQuestionCreateFormLoad.proptype = {
	lessonId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	refetchCheckInQuestions: PropTypes.func.isRequired,
};