import PropTypes from 'prop-types';
import {Question, Response} from '../../components';
import {QuestionForm} from '../../components/QuestionForm';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {DraggableQuestions, Loader} from '../../../../components';

export const ExtraQuestionView = ({ addQuestionModal, questions,questionPoints, setAddQuestionModal,onDeleteQuestion,onUpdateQuestion,onCreateQuestion ,onResortQuestions,loadingQuestions}) => {

	return (
		<div className={'font-mono w-[600px] flex flex-col gap-3 text-white'}>

			<button
				type={'button'}
				className={'flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer rounded border border-b-cloudyBlue-default hover:bg-background-dark'}
				onClick={()=>setAddQuestionModal(true)}
			>
				<p className={'font-sans text-2xl'}>Add Question</p>
				<div className={'w-[40px] h-[40px] flex justify-center items-center rounded-3xl bg-brightLilac-default'}>
					<p className={'font-sans text-5xl'}>+</p>
				</div>

			</button>

			{!loadingQuestions?
				<DraggableQuestions
					questions={questions}
					questionPoints={questionPoints}
					onDeleteQuestion={onDeleteQuestion}
					onUpdateQuestion={onUpdateQuestion}
					onResortQuestions={onResortQuestions}
				/>
				:<Loader/>
			}

			<QuestionForm
				onCreate={onCreateQuestion}

				isOpen={addQuestionModal}
				setIsOpen={setAddQuestionModal}
			/>
		</div>
	);
};

ExtraQuestionView.propTypes = {
	addQuestionModal:PropTypes.bool.isRequired,

	questions: PropTypes.arrayOf(
		PropTypes.shape({
			question: PropTypes.string.isRequired,
			points: PropTypes.string.isRequired
		})
	).isRequired,
	questionPoints:PropTypes.shape({
		question:PropTypes.string,
		points:PropTypes.number,
	}).isRequired,

	setAddQuestionModal: PropTypes.func.isRequired,
	onUpdateQuestion:PropTypes.func.isRequired,
	onDeleteQuestion:PropTypes.func.isRequired,
	onCreateQuestion:PropTypes.func.isRequired,
};