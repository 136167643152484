import styled from 'styled-components';

export const DistrictDetailsTableMainWrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid transparent;
	th, tr, th , tbody, td 	{
		border: transparent;
	}
	tr{
        width: 100%;
        max-width: 1600px;
        height: 100%;
        border-radius: 0.0375rem;
        background-color: ${(props) =>
		props.index % 2 === 0 ? 'rgba(10, 19, 48, 1)' : 'rgba(11, 23, 57, 1)'};

        &:hover {
            background: rgb(18, 29, 61)
        }
	}
`;