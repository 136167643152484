import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';
import React from 'react';
import PropTypes from 'prop-types';
import {Loader} from '../components';
//User is referred to an existing user who is going to search in the schools' data.

export const SchoolUserFindOneByUser = ({ children, user, onAddSchool }) => {
	const SCHOOL_USER_FIND_ONE = query('schoolUserFindOne');

	const { data, loading, error } = useQuery(SCHOOL_USER_FIND_ONE, {
		variables: { filter: { user } },
	});

	if (loading) return <p className={'font-mona f-600 text-white p-3'}><Loader/></p>;
	if (error) return <p className={'font-mona f-600 text-white p-3'}>Error: {error.message}</p>;
	if (!data || !data.SchoolUserFindOne) {
		return (
			<div
				className={'font-mona f-600 button t-white text-xs br-quarter hover:grey900 br-quarter hover:grey900 f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default'}
				onClick={() => onAddSchool(user)}
			>
				+ School
			</div>
		);
	}
	return children(data.SchoolUserFindOne);
};

SchoolUserFindOneByUser.propTypes = {
	children: PropTypes.func.isRequired,
	user: PropTypes.string.isRequired,
	onAddSchool: PropTypes.func.isRequired
};