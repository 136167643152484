import React from 'react';
import PropTypes from 'prop-types';
import {ModalButton, ModalButtons} from '../../../../components/ui/Modal/Modal.style';
import {CurriculumFindMany} from '../../../../hoc/CurriculumFindMany';
import Select from '../../../../components/ui/Select';
import {TextArea} from '../../../../components';

export const CheckInQuestionEditFormRender = ({
	formState, onSubmit, handleChange, isLoading, isError, isChanged, close, allFieldsFilled, handleRadioChange, isDeletionModal, setIsDeletionModal, handleDelete
}) => {
	return (
		<div>
			{
				isDeletionModal ?
					<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
						<h1 className={'text-2xl'}>Edit your Check-In Question</h1>
						<div>
							<h2 className={'font-mono mr-4'}>Are you sure you want to delete this Check-In
								Question?</h2>
							<ModalButtons className={'font-sans'}>
								<ModalButton onClick={handleDelete}>Yes, delete</ModalButton>
								<ModalButton onClick={() => setIsDeletionModal(false)}>Cancel</ModalButton>
							</ModalButtons>
						</div>
					</form> :
					<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
						<h1 className={'text-2xl'}>Edit your Check-In Question</h1>
						<div className={'flex flex-col gap-2'}>
							<div className={'mb-4 mt-5'}>
								<div
									className={'w-100 flex mb-2 direction-column justify-start align-center self-start'}>
									<label className={'font-sans text-text-light dark:text-text-dark self-start'}>Set
										Start status:</label>
								</div>
								<div className={'flex justify-center align-center gap-5 mb-4'}>
									<label
										className={'font-sans ml-3 text-text-light dark:text-text-dark'}>Enabled</label>
									<input
										type='radio'
										name='donation'
										value='true'
										checked={formState.start === true}
										onChange={handleRadioChange}
										className={'font-mono p-5'}
									/>
									<label
										className={'font-sans mr-3 text-text-light dark:text-text-dark'}>Disabled</label>
									<input
										type='radio'
										name='donation'
										value='false'
										checked={formState.start === false}
										onChange={handleRadioChange}
										className={'font-mono p-5'}
									/>
								</div>
							</div>
							<TextArea
								label='Question'
								name='question'
								value={formState.label}
								onChange={handleChange}
							/>
							<div className={'flex justify-center flex-col gap-2 mt-4'}>
								<div className={'flex justify-around gap-2 mt-4'}>
									<button type='button' onClick={close}
										className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}>
										Cancel
									</button>
									<button
										disabled={!allFieldsFilled || isLoading || !isChanged}
										type='submit'
										className={'font-mono px-2 py-0.5 text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
									>
										{isLoading ? 'Updating...' : 'Update'}
									</button>
								</div>
								<div className={'flex justify-around gap-2 mt-4'}>
									<button
										type='button'
										onClick={() => setIsDeletionModal(true)}
										className={'font-mono px-2 py-0.5 text-red-600 border border-red-600 rounded hover:bg-red-600 hover:text-white'}>
										Delete
									</button>
								</div>
								{isError && <div className={'text-center text-red-600 mt-3'}>{isError.message}</div>}
							</div>
						</div>
					</form>
			}
		</div>
	);
};

CheckInQuestionEditFormRender.propTypes = {
	formState: PropTypes.shape({
		class: PropTypes.string.isRequired,
		curriculum: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		start: PropTypes.bool.isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleRadioChange: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isError: PropTypes.any,
	isChanged: PropTypes.bool.isRequired,
	allFieldsFilled: PropTypes.bool.isRequired,
	isDeletionModal: PropTypes.bool.isRequired,
	setIsDeletionModal: PropTypes.func.isRequired,
	handleDelete: PropTypes.func,
};