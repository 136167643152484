import gql from 'graphql-tag';

const queries = {
	UsersBySchoolFindMany: gql`
		query UsersBySchoolFindMany($school: String!, $type: String!){
			UsersBySchoolFindMany(school: $school, type: $type){
                users {
                    _id
                    id
                    firstName
                    lastName
                    userName
                    tag
                    bio
                    birthday
                    email
                    profilePicture{
                        type
                        url
                        title
                    }
                    points
                    type
                    lastLogin
                    organization
                    cleverId
                    cleverToken
                    classLinkId
                    cleverSync
                    classLinkSync
                    deedlyAuthenticationToken
                    deedlyUserId
                    createdAt
                    updatedAt
                }
                total
			}
		}
	`,
	SchoolUserHistoryFindMany: gql`
        query SchoolUserHistoryFindMany(
            $school: String!
            $type: String       # userType
            $dateField: String! # createdAt | lastLogin
            $startDate: String
            $endDate: String
        ){
            SchoolUserHistoryFindMany(
                school: $school
                type: $type
                dateField: $dateField
                startDate: $startDate
                endDate: $endDate
            ) {
                date
                count
            }
        }
    `,
	TeacherClassAndStudents: gql`
        query TeacherClassAndStudents($user:String){
            TeacherClassAndStudents(user: $user) {
                classes
                students
            }
        }
    `,
    
};

export const SchoolQueries = ( value ) => {
	return queries[ value ];
};