import {ArticleQuestions} from './ArticleQuestions';
import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {useQuestionsLayout} from '../../../../hooks';
import { sparkLibrariesMutations} from '../../actions/SparkLibraries.mutations';
import PropTypes from 'prop-types';

export const ArticleQuestionsLoad = ({setIsOpen, sparkL}) =>{
	const [ addQuestionModal, setAddQuestionModal ] = useState(false);

	const articleQuestions = sparkL.articleQuestion.map(item => ({
		question: item.question,
		points: item.points
	}))||[];

	console.log({sparkL,articleQuestions});
	const [updateSparkLibrary] = useMutation(sparkLibrariesMutations('updateOne'), {
		refetchQueries: ['sparkLibraryFindMany'],
		awaitRefetchQueries: true
	});

	const onChangeQuestionsArray =async (newQuestions)=>{
		await updateSparkLibrary({
			variables:{id:sparkL._id, record:{ articleQuestion: newQuestions }}
		})
		.then();
	};

	const { questions, loadingQuestions, onUpdateQuestion, onDeleteQuestion, onCreateQuestion, onResortQuestions }
		= useQuestionsLayout({questions:articleQuestions,onChangeQuestionsArray});

	return (
		
		<ArticleQuestions
			sparkL={sparkL}
			addQuestionModal={addQuestionModal}
			questions={questions}
			questionPoints={articleQuestions}
			loadingQuestions={loadingQuestions}

			setAddQuestionModal={setAddQuestionModal}
			onUpdateQuestion={onUpdateQuestion}
			onDeleteQuestion={onDeleteQuestion}
			onCreateQuestion={onCreateQuestion}
			onResortQuestions={onResortQuestions}
		/>
	
	);
};

ArticleQuestionsLoad.propTypes = {
	sparkL: PropTypes.shape({
		_id:PropTypes.string.isRequired,
		videoQuestions:PropTypes.arrayOf({
			question:PropTypes.string.isRequired,
			points:PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,

	setIsOpen:PropTypes.func.isRequired
};