import gql from 'graphql-tag';

const mutations = {
	SchoolCodeCreateOne: gql`
        mutation SchoolCodeCreateOne($record: CreateOneschoolcodeInput!){
            SchoolCodeCreateOne(record: $record){
                recordId
                record {
                    _id
                    code
                    curriculums
                    classes
                    district
                    school
                    limit
                    expirationDate
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	SchoolCodeUpdateOne: gql`
        mutation SchoolCodeUpdateOne($_id: String!, $record: UpdateByIdschoolcodeInput!){
            SchoolCodeUpdateOne(_id: $_id, record: $record) {
                recordId
                record {
                    _id
                    code
                    curriculums
                    classes
                    district
                    school
                    limit
                    expirationDate
                    deletedAt
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	SchoolCodeDeleteOne: gql`
        mutation SchoolCodeDeleteOne($schoolCodeId: String!){
            SchoolCodeDeleteOne(schoolCode: $schoolCodeId)
        }
    `
};

export const SchoolCodeMutations = ( value ) =>{
	return mutations[value];  
};