import {QuestionRender} from './Question.render';
import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../../../../actions/queries';
import {DangerousActionConfirmModal} from '../../../../components';
import {useState} from 'react';
import {QuestionForm} from '../QuestionForm';

export const QuestionLoad = ({question,onCreateQuestion, onDeleteQuestion,points,onEditQuestion, ...props})=>{
	const [ showDeleteModal, setShowDeleteModal ] = useState(false);
	const [ showFormModal, setShowFormModal ] = useState(false);

	const openDeleteModal = ()=>setShowDeleteModal(true);
	const openEditForm = ()=> setShowFormModal(true);

	// query
	const {data,loading, refetch:refetchResponses}= useQuery(query('responsesFindMany'),{
		variables: { filter: {_operators: {_id: {in: question.responses}}} }
	});

	const handleDelete = async() =>{
		const deletedId = question._id;
		onDeleteQuestion && onDeleteQuestion(deletedId);
	};

	const sortedResponses = data? question.responses.map(qId=> data.ResponsesFindMany.find(q=>q._id ===qId)) :[];

	return (
		<>
			<QuestionRender
				responses={sortedResponses}
				question={question}
				points={points}
				refetchResponses={refetchResponses}
				onDelete={openDeleteModal}
				onEdit={openEditForm}
				{...props}
			/>
			
			<QuestionForm
				question={question}
				points={points}
				onEdit={onEditQuestion}

				isOpen={showFormModal}
				setIsOpen={setShowFormModal}
			/>
			<DangerousActionConfirmModal
				title={'DeleteQuestion :'}
				label={question?.label}
				isOpen={showDeleteModal} setIsOpen={setShowDeleteModal}
				dangerousAction={handleDelete}
			/>
		</>
	);
};

QuestionLoad.propTypes = {
	question: PropTypes.object.isRequired,
	questionIndex:PropTypes.number,
	points:PropTypes.number,
	onDeleteQuestion:PropTypes.func,
	onEditQuestion:PropTypes.func,
};