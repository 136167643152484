import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {DistrictFindMany, UserTypeFindMany} from '../../../hoc';
import {ArrowIndicatorIcon} from '../../../icons';
import {SchoolFindManyByOrganization} from '../../../hoc';


export const UserCreateRender = ({
	formState,
	handleChange,
	handleSubmit,
	districtId,
	setDistrictId,
	allFieldsFilled,
	isLoading,
	error
}) => {
	const history = useHistory();
	const filterValidDistricts = (districts) => {
		return districts.filter(district =>
			district.organization &&
			district.name && district.name.trim() !== '' &&
			district.state && district.state.trim() !== '' &&
			district.country && district.country.trim() !== ''
		);
	};
	return (
		<div className={'w-full h-full'}>
			<div className={'flex gap-1 justify-start align-center'} onClick={() => history.goBack()}>
				<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'}/>
				<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back</h2>
			</div>
			<h1 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium'}>Create User</h1>
			<div className={'flex rounded gap-2 justify-center align-center'}
				 style={{ border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)' }}>
				<form className={'p-5 w-100 flex direction-column justify-center align-center'} onSubmit={handleSubmit}>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>First
							Name</label>
						<input
							type='text'
							name='firstName'
							value={formState.firstName}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Last
							Name</label>
						<input
							type='text'
							name='lastName'
							value={formState.lastName}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Email</label>
						<input
							type='email'
							name='email'
							value={formState.email}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Password</label>
						<input
							type='password'
							name='password'
							value={formState.password}
							onChange={handleChange}
							className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
						/>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
							Organization
						</label>
						<DistrictFindMany>
							{districts => {
								return (
									<select
										name='organization'
										value={formState.organization}
										onChange={(e) => {
											const selectedDistrict = districts.find(d => d.organization === e.target.value);
											handleChange(e);
											setDistrictId(selectedDistrict ? selectedDistrict._id : '');
										}}
										className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
									>
										<option value=''>Select an Organization</option>
										{districts.map(district => (
											<option key={district._id} value={district.organization}>
												{district.name} - {district.state}, {district.country}
											</option>
										))}
									</select>
								);
							}}
						</DistrictFindMany>
					</div>
					<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
						<label
							className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Type</label>
						<UserTypeFindMany>
							{userTypes => (
								<select
									name='type'
									value={formState.type}
									onChange={handleChange}
									className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
								>
									<option value=''>Select a Type</option>
									{userTypes.map(userType => (
										<option key={userType._id} value={userType._id}>
											{userType.identifier}
										</option>
									))}
								</select>
							)}
						</UserTypeFindMany>
					</div>
					{
						formState.type === 'e4475192-3c69-11ee-be56-0242ac120002' && (
							<div className={'mb-4 w-100 flex direction-column justify-start align-center self-start'}>
								<label className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>
									Select one School to assign:
								</label>
								{
									formState.organization ? (
										<SchoolFindManyByOrganization district={districtId}>
											{(schools) => (
												<select
													name='classLinkId'
													value={formState.classLinkId}
													onChange={handleChange}
													className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
													required
												>
													<option value=''>Select a School</option>
													{schools.map((school) => (
														<option key={school._id} value={school._id}>
															{school.name}, {school.state}
														</option>
													))}
												</select>
											)}
										</SchoolFindManyByOrganization>
									) : (
										<p className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark'}>Please select a District/Organization first, to select a School</p>
									)
								}
							</div>
						)
					}
					<div className={'flex align-middle justify-center center'}>
						<button
							onClick={handleSubmit}
							className={`max-w-2xl button t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 ${
								!allFieldsFilled || isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
							} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
							disabled={!allFieldsFilled || isLoading}
						>
							{isLoading ? 'Creating...' : 'Create'}
						</button>
					</div>
					{error ? <div className={'text-center text-red-600 mt-5'}>{error.message}</div> : null}
				</form>
			</div>
		</div>
	);
};

UserCreateRender.propTypes = {
	formState: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		organization: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		password: PropTypes.string.isRequired,
		classLinkId: PropTypes.string,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	districtId: PropTypes.string.isRequired,
	setDistrictId: PropTypes.func.isRequired,
	allFieldsFilled: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	error: PropTypes.any,
};