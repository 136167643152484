import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {SchoolUserFindOneByUser, UsersTypeFindOne} from '../../hoc';
import {Search, SelectDropDown} from '../../components';
import {AddOrganizationToUser, AddSchoolToUser} from './forms';
import {
	StyledTableRow, UserCheckBox,
	UserCounterTitleTable, UserPageWrapperElements,
	UserTableMainWrapper, UserTableTHSpan, UserTableUserInfo,
	UserTopContainerInfo
} from './Users.style';
import '../../css/table.css';
import {
	BriefcaseIcon, FilterDataIcon, PencilIcon, TrashIcon, UserIcon,
	CheckboxIcon, LessIcon, DotsThreeIcon, ArrowIndicatorIcon, BillingIcon
} from '../../icons';
import { UserPropTypes } from './User.types';

export const Users = ({ Users, skip, limit, setLimit, onSearch, onNextPage, onPreviousPage, allUsersCount,studentsCount, teachersCount}) => {
	const history = useHistory();
	const { tab: userType } = useParams();

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalOrganizationOpen, setIsModalOrganizationOpen] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);

	const handleAddSchoolClick = (user) => {
		setCurrentUser(user);
		setIsModalOpen(true);
	};

	const handleSelectionChange = (e) => {
		const selectedValue = e.target.value;
		history.push(`/users/${selectedValue}`);
	};

	return (
		<div className={'w-full h-full m-2'}>
			<div className={'flex justify-between items-center p-1 mb-3'}>
				<h1 className={'font-sans text-2xl text-white f-600'}>Users</h1>
				<div className={'rounded flex gap-2 justify-center align-center p-3 mb-3 cursor-pointer'}
					 style={{maxWidth: '350px', backgroundColor: 'rgba(11, 23, 57, 1)', border: '1px solid rgba(52, 59, 79, 1)'}}
					 onClick={() => history.push('/users/create')}>
					<div className={'flex gap-3 align-center justify-center'}>
						<span className={'font-sans text-base text-white'}>Create User</span>
					</div>
					<div className={'flex gap-3 align-center justify-center'}>
						<div className={'flex align-center justify-center rounded-full'}
							 style={{width: '2rem', height: '2rem', backgroundColor: 'rgba(203, 60, 255, 0.5)'}}>
							<span className={'font-sans text-4xl text-white'}>+</span>
						</div>
					</div>
				</div>
			</div>

			<div className={'flex justify-between items-center mb-3'}>
				<UserTopContainerInfo className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
									  onClick={() => history.push('/users/all')}>
					<div className={'flex justify-start'}>
						<div className={'flex justify-center items-center mr-2'}>
							<UserIcon isActive={true}/>
						</div>
						<div className={'grid'}>
							<span className={'font-sans'}>All Users</span>
							<span className={'font-sans'}>{allUsersCount}</span>
						</div>
					</div>
					<div>
						<DotsThreeIcon className={'ml-auto'}/>
					</div>
				</UserTopContainerInfo>

				<UserTopContainerInfo className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
									  onClick={() => history.push('/users/students')}>
					<div className={'flex justify-start'}>
						<div className={'flex justify-center items-center mr-2'} style={{backgroundColor: 'rgba(253, 181, 42, 0.5)'}}>
							<UserIcon background={'rgba(253, 181, 42, 1)'} />
						</div>
						<div className={'grid'}>
							<span className={'font-sans'}>Students</span>
							<span className={'font-sans'}>{studentsCount}</span>
						</div>
					</div>
					<div>
						<DotsThreeIcon className={'ml-auto'} />
					</div>
				</UserTopContainerInfo>

				<UserTopContainerInfo className={'flex justify-between items-center p-3 mb-3 cursor-pointer'}
									  onClick={() => history.push('/users/teachers')}>
					<div className={'flex justify-start'}>
						<div className={'flex justify-center items-center mr-2'} style={{backgroundColor: 'rgba(5, 193, 104, 0.5)'}}>
							<UserIcon background={'rgba(5, 193, 104, 1)'} />
						</div>
						<div className={'grid'}>
							<span className={'font-sans'}>Teachers</span>
							<span className={'font-sans'}>{teachersCount}</span>
						</div>
					</div>
					<div>
						<DotsThreeIcon className={'ml-auto'} />
					</div>
				</UserTopContainerInfo>
			</div>

			<UserPageWrapperElements className={''}>
				<div className={'flex justify-between items-center mb-2 mt-2 p-2.5 pr-4 pl-4'}>
					<Search label={'Search for Name, Last Name or Email...'} onSearch={onSearch} style={{maxWidth: '350px' }} />
				</div>
				<div className={'flex justify-between items-center mb-6 p-2.5 pr-4 pl-4'}>
					<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
						<span className={'font-sans mb-1'}>Filter By</span>
						<select
							className={'font-sans f-500 border rounded p-2'}
							style={{backgroundColor: 'rgba(10, 19, 48, 1)', border:'1px solid rgba(11, 23, 57, 1)'}}
							value={userType}
							onChange={handleSelectionChange}
						>
							<option value='all'>All Users</option>
							<option value='students'>Students</option>
							<option value='teachers'>Teachers</option>
						</select>
					</div>
					<UserCounterTitleTable className={'font-mono'}>
						<span>{skip + 1} - {Math.min(skip + limit, Users.length) +  skip } </span>
						<span>of {allUsersCount}</span>
					</UserCounterTitleTable>
				</div>
				<div className={'w-full'} style={{height: '1px', border: '1px solid rgba(52, 59, 79, 1)'}}></div>
				<UserTableMainWrapper className='mt rounded-lg p-2.5'>
					<thead>
						<tr>
							<th className={'main-color-background p-4'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<UserCheckBox className={'flex justify-center items-center'}>
										<CheckboxIcon />
									</UserCheckBox>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'} />
										<UserTableTHSpan className={'font-mona f-600 text-sm text-white'}>Name</UserTableTHSpan>
									</div>
									<div>
										<FilterDataIcon />
									</div>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex  justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'} />
										<span className={'font-mona f-600 text-sm text-white'}>Email</span>
									</div>
									<div>
										<FilterDataIcon />
									</div>
								</div>
							</th>
							{
								userType === 'teachers' ?
									<th className={'font-mono main-color-background p-2'}>
										<div className={'flex  justify-between items-center gap-1 cursor-pointer'}>
											<div className={'flex justify-start items-center gap-1'}>
												<BillingIcon width={'0.625rem'} height={'0.625rem'}/>
												<span className={'font-mona f-600 text-sm text-white'}>School</span>
											</div>
											<div>
												<FilterDataIcon/>
											</div>
										</div>
									</th> : null
							}
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex  justify-start items-center gap-1'}>
										<UserIcon width={'0.625rem'} height={'0.625rem'}/>
										<span className={'font-mona f-600 text-sm text-white'}>Bio</span>
									</div>
									<div>
										<FilterDataIcon/>
									</div>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-start items-center gap-1'}>
										<BriefcaseIcon width={'0.625rem'} height={'0.625rem'} />
										<span className={'font-mona f-600 text-sm text-white'}>Type</span>
									</div>
									<div>
										<FilterDataIcon />
									</div>
								</div>
							</th>
							<th className={'font-mono main-color-background p-2'}>
								<div className={'flex justify-stretch items-center gap-1 cursor-pointer'}>
									<div className={'flex justify-between items-center gap-1'}>
										<span className={'font-mona f-600 text-sm text-white'}>Edit</span>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{Users.map(
							({_id, firstName, lastName, email, bio, type, organization}, index) => {
								return (
									<StyledTableRow key={_id} id={_id}>
										<td className={'p-4 cursor-pointer'}>
											<div
												className={'flex justify-between items-center gap-1 cursor-pointer'}>
												<UserCheckBox className={'flex justify-center items-center'}>
													<LessIcon/>
												</UserCheckBox>
											</div>
										</td>
										<td className={'p-2 cursor-pointer'}>
											<UserTableUserInfo
												className={'flex justify-start items-center p-1 gap-2'}>
												<div>
													<div
														style={{backgroundColor: 'rgba(174, 185, 225, 0.3)'}}
														className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
														<UserIcon/>
													</div>
												</div>
												<div>
													<div className={'grid-row038'}>
														<span
															className={'font-mono f-600'}>{firstName} {lastName}</span>
													</div>
												</div>
											</UserTableUserInfo>
										</td>
										<td className={'p-2 cursor-pointer'}>
											<div
												className={'flex justify-between items-center gap-1 cursor-pointer'}>
												<span
													className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>{email}</span>
											</div>
										</td>
										{
											userType === 'teachers' ? (
												<td className={'p-2 cursor-pointer'}>
													<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
														<span className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
															<SchoolUserFindOneByUser user={_id} onAddSchool={handleAddSchoolClick}>
																{({ schoolObj }) => (
																	<div>
																		<p className={'text-sm f-600 text-start'}>School: {schoolObj?.name}</p>
																		<p className={'text-sm f-600 text-start'}>City: {schoolObj?.city}</p>
																	</div>
																)}
															</SchoolUserFindOneByUser>
														</span>
													</div>
												</td>
											) : null
										}
										{/*{
											tab === 'teachers' ? (
												<td className={'p-2 cursor-pointer'}>
													<div className={'flex justify-between items-center gap-1 cursor-pointer'}>
														<span className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
															<OrganizationFindOneByUser user={_id} organization={organization} onAddSchool={handleAddSchoolClick}>
																{({organizationObj}) => (
																	<div>
																		<p className={'text-sm f-600 text-start'}>Name: {organizationObj?.name}</p>
																		<p className={'text-sm f-600 text-start'}>Code: {organizationObj?.code}</p>
																	</div>
																)}
															</OrganizationFindOneByUser>
														</span>
													</div>
												</td>
											) : null
										}*/}
										<td className={'p-2 cursor-pointer'}>
											<div
												className={'flex justify-between items-center gap-1 cursor-pointer'}>
												<span
													className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>{bio ? bio : 'no-bio'}</span>
											</div>
										</td>
										<td className={'p-2 cursor-pointer'}>
											<div
												className={'flex justify-between items-center gap-1 cursor-pointer'}>
												<span
													className={'font-mona f-600 text-xs text-text-light dark:text-text-dark'}>
													<UsersTypeFindOne type={type}/>
												</span>
											</div>
										</td>
										<td className={'p-2'}>
											<div onClick={() => history.push(`/usersInfo/${_id}`)}
															 className={'flex justify-start items-center gap-1 cursor-pointer'}>
												<PencilIcon/>
												<TrashIcon/>
											</div>
										</td>
									</StyledTableRow>
								);
							}
						)}
					</tbody>
				</UserTableMainWrapper>
			</UserPageWrapperElements>
			<div className={'flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'}>
				<div className={'font-mono'}>
					<UserCounterTitleTable className={'font-mono'}>
						<span>{skip + 1} - {Math.min(skip + limit, Users.length)+ skip} </span>
						<span>of {allUsersCount}</span>
					</UserCounterTitleTable>
					<SelectDropDown minValue={limit} onChange={setLimit} />
				</div>
				<div className={'flex items-center gap-1'}>
					<div className={'flex justify-center items-center cursor-pointer rounded-lg'}
						 style={{width:'32px', height: '32px', backgroundColor: 'rgba(10, 19, 48, 1)', border: '1px solid rgba(11, 23, 57, 1)'}}
						 onClick={onPreviousPage}>
						<ArrowIndicatorIcon className={'transform rotate-180'} />
					</div>
					<div className={'flex ml-4 justify-center items-center cursor-pointer rounded-lg'}
						 style={{width:'32px', height: '32px', backgroundColor: 'rgba(10, 19, 48, 1)', border: '1px solid rgba(11, 23, 57, 1)'}}
						 onClick={onNextPage}>
						<ArrowIndicatorIcon />
					</div>
				</div>
			</div>
			{isModalOpen && currentUser && (
				<AddSchoolToUser
					isOpenState={[isModalOpen, setIsModalOpen]}
					currentUser={currentUser}
					refetch={() => history.go(0)}
					currentUserId={currentUser}
				/>
			)}
			{isModalOrganizationOpen && currentUser && (
				<AddOrganizationToUser
					isOpenState={[isModalOrganizationOpen, setIsModalOrganizationOpen()]}
					currentUser={currentUser}
					refetch={() => history.go(0)}
					currentUserId={currentUser}
				/>
			)}
		</div>
	);
};

Users.propTypes = UserPropTypes;