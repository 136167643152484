import { jwtDecode } from 'jwt-decode';
import PropTypes from 'prop-types';
export const addHeaders=(value)=>
	localStorage.getItem('accessToken') ?
		{...value,
			'access-token':localStorage.getItem('accessToken')
		}
		:
		value;

export const error=(value,message)=>{
	if(value && value.includes('E11000')){
		return message || 'The username or email already exists';
	}
	return value;
};

export const deleteTypeMutation=(data)=>{
	let aux=Object.assign({},data);

	for(let prop in aux){
		if(typeof aux[prop]==='object' && !Array.isArray(aux[prop])){
			aux[prop]=deleteTypeMutation(aux[prop]);
		}else{
			if(prop==='__typename')
				delete aux[prop];
		}
	}

	return aux;
};

export const verifyCode=(value)=>{
	return /^([a-zA-Z0-9]{6})$/g.test(value);
};

export const authResponse=(response)=>{
	if(!response.ok && response?.headers?.get('content-type').indexOf('application/json')=== -1){
		return response.text().then(res=>{
			throw new Error(res);
		});
	}
	return response.json().then(res=>{
		localStorage.setItem('accessToken',res['token']);
		return res;
	});
};

export const verifyDate=(value)=>{
	console.log(value);
	return /(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])/g.test(value);

};

export const removeTypename = (obj) => {
	if (Array.isArray(obj)) {
		return obj.map((item) => removeTypename(item));
	} else if (typeof obj === 'object' && obj !== null) {
		const newObj = {};
		for (let key in obj) {
			if (key !== '__typename') {
				newObj[key] = removeTypename(obj[key]);
			}
		}
		return newObj;
	}
	return obj;
};

export const languages=['spanish','english','french'];

export const isTokenExpired =  ()=>{
	const token = localStorage.getItem('accessToken');
	const decoded = jwtDecode(token);
	const exp = decoded.exp *1000;
	const now = Date.now();
	return  exp <= now;
};

// Verify if the URL is not empty & has a valid extension such is: jpeg|jpg|gif|png|webp
export const isValidImageUrl = (url) => {
	return url && /\.(jpeg|jpg|gif|png|webp)$/.test(url);
};

isValidImageUrl.propType = { url: PropTypes.string.isRequired };

export const formatDateString = (dateString) => {
	if (!dateString) return '';
	const date = new Date(dateString);
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = date.getFullYear();
	return `${day}/${month}/${year}`;
};

const dateStringPropType = (props, propName, componentName) => {
	if (!/^\d{4}-\d{2}-\d{2}$/.test(props[propName])) {
		return new Error(
			`Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`
		);
	}
};

formatDateString.propTypes = {
	dateString: dateStringPropType,
};

export const formatDate = (dateString) => {
	if (!dateString) return '';
	const date = new Date(dateString);
	return date.toISOString().split('T')[0];
};

formatDate.propTypes = { dateString:  PropTypes.string.isRequired };

export const formatDecimal = (PossibleDecimalValue) => {
	const numValue = parseFloat(PossibleDecimalValue);
	return isNaN(numValue) ? 'NaN' : numValue.toFixed(2);
};

formatDecimal.propTypes = { PossibleDecimalValue: PropTypes.number.isRequired };