import {Search} from '../../components';
import {StyledGrid} from './Pin.style';
import {ImageIcon, PencilIcon} from '../../icons';
import {ClassFindOneById} from '../../hoc';
import {useHistory} from 'react-router-dom';
import {SparkLibraryFindOne} from '../../hoc/SparkLibraryFindOne';



export const PinRender = ({ Pins }) => {
	const history = useHistory();

	return (
		<div className={'w-full h-full'}>
			<div className={'flex justify-stretch items-center p-1 mb-3'}>
				<h1 className={'font-sans text-base text-white f-500'}>Pins</h1>
				<Search onSearch={() => { }} style={{ marginLeft: '3rem', maxWidth: '350px' }} />
			</div>
			<StyledGrid $cols={3} $rows={2} $gap='10px' $rowGap='15px' $colGap='20px' $placeItems='center'>
				<button
					style={{maxWidth: '340px'}}
					onClick={() => history.push('/pin/create')}
					className={`flex self-center align-center justify-center button h-full t-white button-large w-100 br-quarter hover:grey900 f-uppercase f-900 bg-gradient-to-r from-brightLilac-default to-indigo-default hover:opacity-90`}
				>
					Create Pin
				</button>
				{Pins.map(({_id, label, identifier, cover, classId, sparkLibrary}) => {
					return <div key={_id}
						className={'w-full h-full rounded flex gap-2 justify-between align-center p-3 mb-3 cursor-pointer'}
						style={{
							backgroundColor: 'rgba(11, 23, 57, 1)',
							border: '1px solid rgba(52, 59, 79, 1)'
						}}>
						<div className={'flex justify-start align-start'}>
							<div className={'flex justify-center items-start mr-2'}>
								{
									cover?.url === undefined || cover?.url === null ?
										(
											<div
												className={'w-22 h-22 flex justify-center align-center p-3 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
												<ImageIcon width={'1rem'} height={'1rem'}
														   background={'rgba(174, 185, 225, 1)'}/>
											</div>
										) :
										(
											<div
												className={'w-22 h-22 flex justify-center align-center rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}
												style={{maxWidth: '40px', minHeight: '40px', maxHeight: '40px'}}>
												<img
													className={'rounded-full'}
													src={cover.url}
													alt={label}
												/>
											</div>
										)
								}
							</div>
						</div>
						<div className={'font-mono grid text-white'}>
							<span className={'font-sans f-600'}>{label}</span>
							<span className={'font-mona text-blue-400'}>{identifier}</span>
							{classId && (
								<ClassFindOneById id={classId}>
									{classData =>
										classData ? (
											<div className={'font-mono f-500 text-sm'}>
												<strong>Class:</strong> {classData.title}
											</div>
										) : null
									}
								</ClassFindOneById>
							)}
							{sparkLibrary && (
								<SparkLibraryFindOne _id={sparkLibrary}>
									{classData =>
										classData ? (
											<div className={'font-mona f-500 text-sm'}>
												<strong>Library:</strong> {classData.title}
											</div>
										) : null
									}
								</SparkLibraryFindOne>
							)}
						</div>
						<div className={'flex justify-center align-center'}>
							<div onClick={() => history.push(`/pin/${_id}`)}
								 className={'p-3 w-22 h-22 rounded-full hover:bg-button-hover-light dark:hover:bg-button-hover-dark  cursor-pointer'}>
								<PencilIcon/>
							</div>
						</div>
					</div>;
				})}
			</StyledGrid>
		</div>
	);
};
