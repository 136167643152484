import gql from 'graphql-tag';

export const mutations = {
	reportsCreateOne: gql`
        mutation ReportsCreateOne($record: CreateOnereportsInput!){
            ReportsCreateOne(record: $record){
                recordId
                record {
                    _id
                    body
                    scale
                    deleted
                    createdAt
                    updatedAt
                }
                error {
                    message
                }
            }
        }
    `,
	reportsUpdateOne: gql`
        mutation ReportsUpdateOne($_id: String! , $record: UpdateByIdreportsInput!){
            ReportsUpdateOne(_id: $_id, record: $record){
                recordId
                record {
                    _id
                    body
                    scale
                    deleted
                    createdAt
                    updatedAt
                }
                error {
                    message
                }   
            }
        }
    `,
	reportsDeleteOne: gql`
        mutation ReportsDeleteOne($reports: String! ){
            ReportsDeleteOne(report: $reports)
        }
    `
};

export const ReportsMutations = ( value ) => {
	return mutations[value];  
};