import PropTypes from 'prop-types';
import {
	StyledTableRow,
	UserCounterTitleTable,
	UserPageWrapperElements,
	UserTableMainWrapper
} from '../Users/Users.style';
import React from 'react';
import {format} from 'date-fns';
import Button from '../../components/ui/Button';
import {ClassesFindManyByArrayIds, CurriculumsFindManyByArrayIds, SchoolFindOneById} from '../../hoc';
import {SelectDropDown} from '../../components';
import {ArrowIndicatorIcon} from '../../icons';

export const SchoolCodeRender = ({
									 schoolCodes,
									 totalSchoolCodes,
									 skip,
									 limit,
									 setLimit,
									 onNextPage,
									 onPreviousPage,
									 allSchoolCodes,
									 totalPages,
									 currentPage,
									 handleEditClick,
									 handleCreateClick,
	setSort, sort
								 }) => {
	return (
		<div className='w-full h-full m-2'>
			<div className='w-full flex justify-between items-center p-1 mb-3 gap-6'>
				<h1 className='font-sans text-white f-500 text-2xl text-start'>School Codes</h1>
				<Button
					type={'button'}
					className={'font-bold text-sm border-2 bg-brightLilac-default'}
					onClick={handleCreateClick}
				> Add a new School Code +</Button>
			</div>
			<div className='flex justify-between items-center mb-6 p-2.5 pr-4 pl-4'>
				<UserCounterTitleTable className={'font-mono'}>
					<span>{skip + 1} - {Math.min(skip + limit, schoolCodes.length) + skip} </span>
					<span>of {totalSchoolCodes}</span>
				</UserCounterTitleTable>
				<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
					<div
						className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
						<span className={'font-sans mb-1'}>Sort by Date</span>
						<select
							className={'font-sans f-500 border rounded ml-4 p-2'}
							style={{
								backgroundColor: 'rgba(10, 19, 48, 1)',
								border: '1px solid rgba(11, 23, 57, 1)',
							}}
							value={sort}
							onChange={(e) => setSort(e.target.value)}
						>
							<option value='CREATEDAT_ASC'>Ascending</option>
							<option value='CREATEDAT_DESC'>Descending</option>
						</select>
					</div>
				</div>
			</div>

			<UserPageWrapperElements>
				{schoolCodes.length > 0 ? (
					<div className='overflow-auto rounded-lg shadow-md'>
						<UserTableMainWrapper className='w-full table-auto border-collapse border border-gray-800'>
							<thead>
								<tr>
									<th className='left font-mono main-color-background p-2 t-white'>School Code</th>
									<th className='left font-mono main-color-background p-2 t-white'>School</th>
									<th className='left font-mono main-color-background p-2 t-white'>Curriculums</th>
									<th className='left font-mono main-color-background p-2 t-white'>Classes</th>
									<th className='left font-mono main-color-background p-2 t-white'>Limit</th>
									<th className='left font-mono main-color-background p-2 t-white'>Expiration Date</th>
								</tr>
							</thead>
							<tbody>
								{schoolCodes.map((schoolCode) => (
									<StyledTableRow
										index={schoolCode._id}
										key={schoolCode._id} className={'cursor-pointer'}
										onClick={() => handleEditClick(schoolCode._id)}>
										<td className='justify-start items-center text-start p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{schoolCode?.code ? schoolCode.code : 'No code yet'}
											</span>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												<SchoolFindOneById _id={schoolCode.school}/>
											</span>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<div className='flex flex-wrap gap-1'>
												<CurriculumsFindManyByArrayIds
													arrayOfCurriculums={schoolCode?.curriculums || []}>
													{(curriculums) =>
														curriculums.length > 0 ? (
															curriculums.map((curriculum) => (
																<span
																	key={curriculum._id}
																	className='bg-green-700 text-white text-[0.625rem] font-medium px-2 py-1 rounded'
																>
																	{curriculum.title}
																</span>
															))
														) : (
															<span
																className='text-[0.625rem] font-medium px-4 py-2 bg-gray-300 text-black rounded'>All Courses</span>
														)
													}
												</CurriculumsFindManyByArrayIds>
											</div>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<div className='flex flex-wrap gap-1'>
												<ClassesFindManyByArrayIds arrayOfClasses={schoolCode?.classes || []}>
													{(classes) =>
														classes.length > 0 ? (
															classes.map((cls) => (
																<span
																	key={cls._id}
																	className='bg-green-500 text-white text-[0.625rem] font-medium px-2 py-1 rounded'
																>
																	{cls.title}
																</span>
															))
														) : (
															<span
																className='text-[0.625rem] font-medium px-4 py-2 bg-gray-300 text-black rounded'>All Lessons</span>
														)
													}
												</ClassesFindManyByArrayIds>
											</div>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{schoolCode.limit}
											</span>
										</td>
										<td className='justify-start items-center text-start p-4'>
											<span className='font-mono text-text-light dark:text-text-dark text-xs'>
												{schoolCode.createdAt ? format(new Date(schoolCode.expirationDate), 'dd/MM/yyyy') : 'Date not set'}
											</span>
										</td>
									</StyledTableRow>
								))}
							</tbody>
						</UserTableMainWrapper>
					</div>
				) : (
					<div className='flex justify-center items-center text-white font-mono text-lg p-4'>
						No Schools Codes Found.
					</div>
				)}
			</UserPageWrapperElements>
			<div className={'flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'}>
				<div className={'font-mono'}>
					<UserCounterTitleTable className={'font-mono'}>
						<span>{skip + 1} - {Math.min(skip + limit, schoolCodes.length) + skip} </span>
						<span>of {totalSchoolCodes}</span>
					</UserCounterTitleTable>
					<SelectDropDown minValue={limit} onChange={setLimit}/>
				</div>
				<div className={'flex items-center gap-4'}>
					<button
						className={`flex gap-2 py-2 rounded text-[0.625rem] font-medium px-2 ${
							skip === 0 ? 'bg-gray-700 cursor-not-allowed' : 'bg-brightLilac-default hover:bg-brightLilac-hover'
						}`}
						onClick={onPreviousPage}
						disabled={skip === 0}
					>
						<ArrowIndicatorIcon className={'transform rotate-180'}/> Previous
					</button>
					<span className='text-white'>
						Page {currentPage} of {totalPages}
					</span>
					<button
						className={`flex gap-2 py-2 rounded text-[0.625rem] font-medium px-2 ${
							skip + limit >= totalSchoolCodes ? 'bg-gray-700 cursor-not-allowed' : 'bg-brightLilac-default hover:bg-brightLilac-hover'
						}`}
						onClick={onNextPage}
						disabled={skip + limit >= totalSchoolCodes}
					>
						Next <ArrowIndicatorIcon/>
					</button>
				</div>
			</div>
		</div>
	);
};

SchoolCodeRender.propTypes = {
	schoolCodes: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			code: PropTypes.string.isRequired,
			curriculums: PropTypes.arrayOf(PropTypes.string),
			classes: PropTypes.arrayOf(PropTypes.string),
			school: PropTypes.string,
			limit: PropTypes.number,
			createdAt: PropTypes.string,
			updatedAt: PropTypes.string,
			expirationDate: PropTypes.string,
			deletedAt: PropTypes.string,
		})
	).isRequired,
	totalSchoolCodes: PropTypes.number.isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired,
	setLimit: PropTypes.func.isRequired,
	onNextPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	allSchoolCodes: PropTypes.number.isRequired,
	handleEditClick: PropTypes.func.isRequired,
	handleCreateClick: PropTypes.func.isRequired,
	setSort: PropTypes.func.isRequired,
	sort: PropTypes.string.isRequired,
};