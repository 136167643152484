import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const UserFindOneNameByID = ({_id}) => {
	const { data, loading, error } = useQuery(query('usersFindOne'), {
		variables: { id:_id }
	});
	if (loading) return null;
	if(error) return <span>User not found</span>;

	return <span>{data?.UsersFindOne?.firstName + ' ' + data?.UsersFindOne?.lastName || 'User not found'}</span> ;
};

UserFindOneNameByID.propTypes = {
	_id: PropTypes.string.isRequired,
};