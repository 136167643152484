import PropTypes from 'prop-types';
import {useQuery} from '@apollo/client';
import {CheckInQueries} from '../pages/CheckIn/actions/CheckIn.queries';

export const GroupFindOneById = ({_id}) => {
	if(!_id) return 'Group not found';

	const { data, loading, error } = useQuery(CheckInQueries('groupFindOne'),{
		variables: { filter: { _id }, skip: 0,sort: '_ID_ASC' },
		fetchPolicy: 'network-only',
	});
	if(loading ) return null;
	if( error || !data || !data.GroupFindOne) return 'Group not found';

	return `${data.GroupFindOne.name}`;
};

GroupFindOneById.propTypes = {
	_id: PropTypes.string.isRequired,
};