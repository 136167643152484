import Input from '../../../../components/ui/Input';
import PropTypes, {arrayOf} from 'prop-types';
import Select from '../../../../components/ui/Select';

export const  SchoolFormRender = ({formState,islaoding, handleChange,edit, handleSubmit, districtOptions})=>{
	return (
		<div className={'w-600px flex flex-col items-center w-full pb-2'}>
			<h2>{edit ? 'Edit School' :'Create School'}</h2>
			<form className={'p-5 w-100 grid grid-cols-2 direction-column justify-center align-center gap-x-4'} onSubmit={handleSubmit}>
				<Input
					label={'Name'}
					type='text'
					name='name'
					value={formState.name}
					onChange={handleChange}
				/>
		
				<Input
					label='State'
					type='text'
					name='state'
					value={formState.state}
					onChange={handleChange}
				/>
			
				<Input
					label={'City'}
					type='text'
					name='city'
					value={formState.city}
					onChange={handleChange}
				/>
			
				<Select
					label={'District'}
					type='text'
					name='district'
					value={formState.district}
					options={districtOptions.data}
					onChange={handleChange}
				/>
			
				
				<Input
					label={'Country'}
					type='text'
					name='country'
					value={formState.country}
					onChange={handleChange}
				/>
				
				<Input
					label={'slug'}
					type='text'
					name='slug'
					value={formState.slug}
					onChange={handleChange}
				/>
				
				<button
					type='submit'
					disabled={islaoding}
					className={`font-mono px-4 py-2 mt-4 font-medium text-white bg-button-background-light dark:bg-button-background-dark rounded ${islaoding? 'opacity-50':''}`}
				>
                    Save
				</button>
			</form>
		</div>
	);
};

SchoolFormRender.propTypes = {
	isLaoding: PropTypes.bool.isRequired,
	formState: PropTypes.shape({
		name: PropTypes.string.isRequired,
		state: PropTypes.string.isRequired,
		city: PropTypes.string.isRequired,
		district: PropTypes.string.isRequired,
		country: PropTypes.string.isRequired,
		slug: PropTypes.string.isRequired,
	}).isRequired,
	districtOptions : PropTypes.shape({
		data:arrayOf(PropTypes.shape({
			value:PropTypes.string.isRequired,
			label:PropTypes.string.isRequired,
		})).isRequired,
		isLoading:PropTypes.bool.isRequired,
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};