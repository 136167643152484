import {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {DistrictQueries} from '../../actions/district.queries';
import {Loader} from '../../../../components';
import {GroupListDetails} from './GroupListDetails';

export const GroupListDetailsLoad = () => {
	const { organization } = useParams();
	const [filterType, setFilterType] = useState('');
	const [loadingFilter, setLoadingFilter] = useState(false);

	const { loading: loadingGroups, error: errorGroups, data: groupsData } = useQuery(DistrictQueries('GroupFindMany'), {
		variables: { filter: { organization, deletedAt: null } },
	});

	const { loading: loadingUsers, error: errorUsers, data: usersData } = useQuery(DistrictQueries('UsersFindManyInDistrict'), {
		variables: {
			filter: { },
			limit: 100,
			skip: 0,
			sort: '_ID_DESC'
		},
	});

	const handleFilterChange = (value) => {
		setLoadingFilter(true);
		setFilterType(value);
		setLoadingFilter(false);
	};

	if (loadingGroups || loadingUsers || loadingFilter) return <Loader />;
	if (errorGroups || errorUsers) return <p className='font-mona f-900 text-white p-3'>Error</p>;

	const managerIds = groupsData.GroupFindMany.map(group => group.manager).filter(id => id);
	const filteredUsers = usersData.UsersFindMany.filter(user => managerIds.includes(user._id));

	const groupedUsers = {
		teachers: filteredUsers.filter(user => user.type === 'e4475192-3c69-11ee-be56-0242ac120002'), // Teacher ID
		students: filteredUsers.filter(user => user.type === 'cae9c4f0-b804-11eb-87d9-ab38d9aa58aa'), // Student ID
	};

	const filteredGroups = groupsData.GroupFindMany.filter((group) => {
		if (filterType === 'teachers') {
			return groupedUsers.teachers.some(user => user._id === group.manager);
		} else if (filterType === 'students') {
			return groupedUsers.students.some(user => user._id === group.manager);
		}
		return true;
	});

	return (
		<GroupListDetails
			groupData={filteredGroups}
			onFilterChange={handleFilterChange}
			selectedFilter={filterType}
		/>
	);
};