import React, {useState} from 'react';
import PropTypes  from 'prop-types';
import Input from '../../../../components/ui/Input';
import {FileInput} from '../../../../components/forms/FileInput';
import DeleteConfirmationModal from '../../../../components/ui/Modal/DeleteConfirmationModal/DeleteConfirmationModal';

export const DocumentForm=({isLoading,stateForm,onSubmit,handleChange,close, isEditing, isUpdated, onDelete})=>{
	const isImage = stateForm.file && stateForm.file.type?.startsWith('image/');
	const fileName = stateForm.file?.name || '';
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	return (
		<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
			<h1 className={'text-2xl'}>{ isEditing ? 'Updating Document' : 'Add Document' }</h1>
			{isImage && (
				<div className='my-2'>
					<p className='text-sm text-gray-500'>Preview:</p>
					<img
						src={URL.createObjectURL(stateForm.file)}
						alt='Preview'
						className='w-full max-h-40 object-contain border border-gray-300 rounded'
					/>
				</div>
			)}
			<FileInput name={'file'} label={isEditing ? 'Updating Document' : 'Select Document'}  onChange={handleChange} />
			{fileName && (
				<p className='text-sm text-gray-500'>
					Selected file: <span className='font-semibold'>{fileName}</span>
				</p>
			)}
			{isEditing && (
				<p className='text-sm text-gray-500'>
					This document already exists. You can update it.
				</p>
			)}

			<Input
				value={stateForm.title}
				name={'title'}
				onChange={handleChange}
				label='Document title'
			/>

			<div className={'flex gap-2 justify-between'}>
				<button
					type='button'
					onClick={close}
					className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}
				>
					cancel
				</button>

				<button
					disabled={isLoading && isUpdated}
					type='submit'
					className={'font-mono px-2 py-0.5  text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
				>
					{isLoading ? 'loading...' : isEditing ? 'update' : 'save'}
				</button>
			</div>
			<div className={'flex gap-2 justify-center align-middle'}>
				{
					isEditing && (
						<button
							disabled={isLoading && isUpdated && isEditing}
							type='button'
							onClick={()=>setIsDeleteModalOpen(true)}
							className={'font-mono px-2 py-0.5  text-white bg-red-500 rounded hover:bg-opacity-70 disabled:opacity-40'}
						>
							{isLoading ? 'loading...' : 'delete'}
						</button>
					)
				}
				{
					isEditing && (
						<DeleteConfirmationModal onDelete={onDelete} isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} itemName={stateForm.title}/>
					)
				}
			</div>

		</form>
	);
};

DocumentForm.prototype = {
	isLoading: PropTypes.bool.isRequired,
	stateForm: PropTypes.shape({
		label: PropTypes.string.isRequired,
		identifier: PropTypes.string.isRequired,
	}),
	handleChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	close:PropTypes.func.isRequired,
	isEditing:PropTypes.bool.isRequired,
	isUpdated:PropTypes.bool.isRequired,
};