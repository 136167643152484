import React from 'react';
import PropTypes from 'prop-types';
import { SparkLibraryFindMany } from '../../../../hoc/SparkLibraryFindMany';
import Input from '../../../../components/ui/Input';
import {ModalButton, ModalButtons} from '../../../../components/ui/Modal/Modal.style';

export const VideoTapEditFormRender = ({
	formState, onSubmit, handleChange, isLoading, isError, close, allFieldsFilled, startError, endError,
	handleDelete, isDeletionModal, setIsDeletionModal
}) => {
	return (
		<div>
			{
				isDeletionModal ?
					<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
						<h1 className={'text-2xl'}>Edit your VideoTap</h1>
						<div className={'flex flex-col gap-2'}>

						</div>
						<div>
							<h2 className={'font-mono mr-4'}>Are you sure you want to delete this VideoTap?</h2>
							<ModalButtons className={'font-sans'}>
								<ModalButton onClick={handleDelete}>Yes, delete</ModalButton>
								<ModalButton onClick={() => setIsDeletionModal(false)}>Cancel</ModalButton>
							</ModalButtons>
						</div>
					</form> :
					<form className={'w-400px flex flex-col gap-4'} onSubmit={onSubmit}>
						<h1 className={'text-2xl'}>Edit your VideoTap</h1>
						<div className={'flex flex-col gap-2'}>
							<div className={'w-full flex flex-col gap-2'}>
								<Input
									type='number'
									name='start'
									placeholder='Start Time'
									value={formState.start}
									onChange={handleChange}
								/>
								<Input
									type='number'
									name='end'
									placeholder='End Time'
									value={formState.end}
									onChange={handleChange}
								/>
								<div className={'w-100 flex direction-column justify-start align-center self-start'}>
									<label
										className={'font-sans ml-3 mb-4 text-text-light dark:text-text-dark self-start'}>Spark
										Library</label>
									<SparkLibraryFindMany>
										{({sparkLibraries}) => (
											<select
												name='sparkLibrary'
												value={formState.sparkLibrary}
												onChange={handleChange}
												className={'font-mono w-100 p-5 text-text-light dark:text-text-dark bg-background-light dark:bg-background-dark rounded-lg'}
											>
												<option value=''>Select a Spark Library</option>
												{sparkLibraries.map(sparkLibrary => (
													<option key={sparkLibrary._id} value={sparkLibrary._id}>
														{sparkLibrary.title}
													</option>
												))}
											</select>
										)}
									</SparkLibraryFindMany>
								</div>
								<Input
									type='text'
									name='tag'
									placeholder='Tag'
									value={formState.tag}
									onChange={handleChange}
								/>
							</div>
							<div className={'flex justify-center flex-col gap-2 mt-4'}>
								<div className={'flex justify-around gap-2 mt-4'}>
									<button type='button' onClick={close}
										className={'flex gap-3 border items-center w-fit px-2 py-0.5 rounded'}>
										Cancel
									</button>
									<button
										disabled={!allFieldsFilled || isLoading}
										type='submit'
										className={'font-mono px-2 py-0.5 text-white bg-button-background-dark rounded hover:bg-opacity-70 disabled:opacity-40'}
									>
										{isLoading ? 'Updating...' : 'Update'}
									</button>
								</div>
								<div className={'flex justify-around gap-2 mt-4'}>
									<button
										type='button'
										onClick={()=>setIsDeletionModal(true)}
										className={'font-mono px-2 py-0.5 text-red-600 border border-red-600 rounded hover:bg-red-600 hover:text-white'}
									>
										Delete
									</button>
								</div>
								{endError || startError && (
									<div className={'text-center text-red-600 mt-3'}>End date must be after start
										date</div>
								)}
								{isError && <div className={'text-center text-red-600 mt-3'}>{isError.message}</div>}
							</div>
						</div>
					</form>
			}
		</div>
	);
};

VideoTapEditFormRender.propTypes = {
	formState: PropTypes.shape({
		start: PropTypes.number,
		end: PropTypes.number,
		sparkLibrary: PropTypes.string,
		tag: PropTypes.string,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isError: PropTypes.any.isRequired,
	allFieldsFilled: PropTypes.bool.isRequired,
	handleDelete: PropTypes.func.isRequired,
	setIsDeletionModal: PropTypes.func.isRequired,
};