import React from 'react';
import {SchoolDetailsRenderTypes} from './SchoolsDetails.types';
import {Loader} from '../../../components';
import {ArrowIndicatorIcon, DropdownArrowIcon, PencilIcon, UserIcon} from '../../../icons';
import {SchoolsInformation} from './components/SchoolsInformation';
import {SchoolsList} from './components/SchoolsList';


export const SchoolDetailsRender = ({
	loading,
	formState,
	School,
	menu,
	history,
	selectedTab,
	setSelectedTab,
	handleFilterChange,
	selectedType,
	isChanged,
	handleChange,
	handleSubmit,
	teachersData,
	studentsData,
	error
}) => {

	if (loading) return <Loader/>;

	const iconComponents = {
		UserIcon: <UserIcon isActive={selectedTab === 'school-information'} />,
		PencilIcon: <PencilIcon isActive={selectedTab === 'school-list'} />,
	};

	const renderComponents = {
		SchoolsInformation : <SchoolsInformation
			formState={formState}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			history={history}
			isChanged={isChanged}
			loading={loading}
			error={error}
		/>,
		SchoolsList: <SchoolsList
			teachersData={teachersData}
			studentsData={studentsData}
			School={School}
			history={history}
			selectedType={selectedType}
			handleFilterChange={handleFilterChange}
			loading={loading}
			error={error}
		/>
	};

	return (
		<div className={'flex w-full h-full mt-2 text-xs text-text-light dark:text-text-dark'}>
			<div className={'grid gap-3 max-w-xs col-span-2 mr-2 p-2'}
				 style={{maxWidth: '320px', borderRight: '1px solid rgba(11, 23, 57, 1)'}}>
				<div>
					<div
						className={'flex justify-start align-center mb-4 rounded-lg hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}
						onClick={() => history.goBack()}>
						<ArrowIndicatorIcon className={'transform rotate-180 cursor-pointer'}/>
						<h2 className={'font-sans text-text-light dark:text-text-dark m-2 text-base font-medium cursor-pointer'}>Back
							to Schools List</h2>
					</div>
					<h2 className={'font-sans f-500 mb-4'}>
						District: {School?.name || 'N/A'}
					</h2>
					<h2 className={'font-sans f-500 mb-4'}>
						Country: {School?.country || 'N/A'}, {School?.state || 'N/A'}
					</h2>
					{menu.map(({to, name, icon, component}) => (
						<div
							key={to}
							className={`flex items-center p-2 my-2 rounded-lg text-text-light dark:text-text-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark ${selectedTab === to ? 'font-bold active-nav-item-font-color' : ''}`}
							onClick={() => setSelectedTab(to)}
						>
							{iconComponents[icon]}
							<span className='ml-2'>{name}</span>
							<DropdownArrowIcon className='ml-auto'/>
						</div>
					))}
				</div>
			</div>
			<div className='w-full tab-content'>
				{renderComponents[menu.find(menu => menu.to === selectedTab)?.component]}
			</div>
		</div>
	);
};

SchoolDetailsRender.propTypes = SchoolDetailsRenderTypes;