import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {query} from '../actions/queries';

export const ClassFindOneById = ({_id, children}) => {
	if (!_id) return 'class not found';
	const [retry, setRetry] = useState(false);
	const { data, loading, error, refetch } = useQuery(query('classesAdminFindOne'),{
		variables: { id: _id },
		fetchPolicy: 'network-only'
	});

	useEffect(() => {
		if (error && !retry) {
			setRetry(true);
			refetch()
			.then(({ data }) => {
				if (!data || !data.ClassesAdminFindOne) {
					setRetry(false);
				}
			})
			.catch(() => {});
		}
	}, [error, retry, refetch]);

	if (loading) return null;
	if (error) return null;

	console.log(data?.ClassesAdminFindOne);
	if (!data || !data.ClassesAdminFindOne) return null;

	return <span>{children(data.ClassesAdminFindOne)}</span>;
};