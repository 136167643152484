import { query } from '../actions/queries';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

export const QuestionFindOneByIDOnlyName = ({ id }) => {
	if (!id) return 'Question not found';

	const { data, loading, error } = useQuery(query('questionsFindOne'), {
		variables: { id },
		fetchPolicy: 'network-only'
	});

	if (loading) return null;

	if (
		error ||
		!data ||
		!data.QuestionsFindOne ||
		(error && error.message.includes('Question not found'))
	) {
		return 'Question not found';
	}

	return  <span
		dangerouslySetInnerHTML={{ __html: data.QuestionsFindOne.label }}
	/>;
};

QuestionFindOneByIDOnlyName.propTypes = {
	id: PropTypes.string.isRequired,
};