import {ClassesFindMany} from '../../../hoc/ClassesFindMany';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';

export const FIndClassesInSelector = ({ selectedClasses, onAdd, onRemove }) => {
	const [localSelectedClasses, setLocalSelectedClasses] = useState(selectedClasses);

	useEffect(() => {
		setLocalSelectedClasses(selectedClasses);
	}, [selectedClasses]);

	const handleAddClass = (classId) => {
		setLocalSelectedClasses((prev) => [...prev, classId]);
		onAdd(classId);
	};

	const handleRemoveClass = (classId) => {
		setLocalSelectedClasses((prev) => prev.filter((id) => id !== classId));
		onRemove(classId);
	};

	return (
		<ClassesFindMany>
			{({ classes }) => (
				<div className='flex flex-col w-full'>
					<h4 className='font-sans text-text-light dark:text-text-dark mb-2 text-base font-medium'>
						Available Lessons
					</h4>
					<div className='flex flex-wrap gap-2'>
						{classes.map((classItem) => {
							const isSelected = localSelectedClasses.includes(classItem._id);
							return (
								<button
									key={classItem._id}
									type={'button'}
									onClick={() =>
										isSelected ? handleRemoveClass(classItem._id) : handleAddClass(classItem._id)
									}
									className={`px-4 py-2 rounded ${isSelected ? 'bg-green-500 text-white' : 'bg-gray-200 text-black'}`}
								>
									{classItem.title}
								</button>
							);
						})}
					</div>
					<h4 className='font-sans text-text-light dark:text-text-dark mb-2 text-base font-medium'>
						Selected Lessons
					</h4>
					<div className='flex flex-wrap gap-2'>
						{localSelectedClasses.length === 0 ? (
							<span className='px-4 py-2 bg-gray-300 text-black rounded'>
								All Classes
							</span>
						) : (
							localSelectedClasses.map((id) => {
								const classItem = classes.find((c) => c._id === id);
								return (
									<span
										key={id}
										className='px-4 py-2 bg-blue-500 text-white rounded cursor-pointer'
										onClick={() => handleRemoveClass(id)}
									>
										{classItem?.title || 'Unknown'} ✕
									</span>
								);
							})
						)}
					</div>
				</div>
			)}
		</ClassesFindMany>
	);
};