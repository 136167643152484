import {SchoolsRender} from './SchoolsRender';
import {useQuery} from '@apollo/client';
import {query} from '../../actions/queries';
import {Loader} from '../../components';

export const SchoolsLoad = () => {

	const { loading, error, data, refetch } = useQuery(query('schoolFindMany'), {
		variables: { limit:500},
		nextFetchPolicy: 'network-only'
	});

	if (loading) return <p className={'font-mona f-900 p-3'}><Loader/></p>;
	if (error) return <p className={'font-mona f-900 p-3'}>Error</p>;
	if (!data || !data.SchoolFindMany) return <p className={'font-mona f-900 p-3'}>No Data in Schools</p>;

	return <SchoolsRender schools={data ? data.SchoolFindMany : []}/>;
};