import styled from 'styled-components';

export const LabelVideoContainer = styled.label`
    align-items: stretch;
    background-color: rgba(0, 0, 0, 0.00);
    border: 0 solid black;
    box-sizing: border-box;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    min-height: 0;
    min-width: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    z-index: 0;
`;

export const VideoWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	overflow: hidden;
`;

export const VideoElement = styled.video`
	width: 100%;
	height: auto;
	max-height: 35vw;
	object-fit: cover;
	cursor: default;
`;

export const StickerImgCover = styled.img`
    width: 42px;
    height: 42px;
	min-height: 42px;
	min-width: 42px;
    object-fit: cover;
    border-radius: 50%;
`;