import PropTypes from 'prop-types';
import Input from '../../components/ui/Input';
import {ImageIcon} from '../../icons';


export const AdminProfileRender = ({
	userData,
	handleSubmit, handleChange, handleFileChange,
	preview, isChanged,
	loading ,error }) => {
	return <div className={'w-full rounded-lg p-4 mt-4'}>
		<form
			className={'p-5 w-100 flex direction-column justify-center align-center'}
			onSubmit={handleSubmit}
		>
			<h2 className={'text-white font-sans mb-5 text-start'}>Personal Admin Information: </h2>
			<div className={'p-5 mb-12 rounded-lg'}
				 style={{border: '1px solid rgba(52, 59, 79, 1)', background: 'rgba(11, 23, 57, 1)'}}
			>
				<label htmlFor='file-upload'
					   className={'mb-4 flex direction-column gap-2 justify-center align-center cursor-pointer'}>
					<div
						className={'w-45 h-45 rounded-full bg-background-light dark:bg-background-dark hover:bg-button-hover-light dark:hover:bg-button-hover-dark'}>
						<div className={'flex justify-center align-center w-44 h-44'}>
							{preview ? (
								<img src={preview} alt='Preview' className={'rounded-full w-44 h-44 object-cover'}/>
							) : (
								<ImageIcon width={'3em'} height={'3em'} className={'cursor-pointer'}/>
							)}
						</div>
					</div>
					<span className={'font-sans text-text-light dark:text-text-dark active-nav-item-font-color'}>
						{preview ? 'Edit Image' : 'Upload Image'}
					</span>
					<input
						id='file-upload'
						type='file'
						accept='image/*'
						onChange={handleFileChange}
						className={'hidden'}
					/>
				</label>
				<Input
					className={'w-full gap-3'}
					label={'First Name'}
					type='text'
					name='firstName'
					value={userData.firstName}
					onChange={handleChange}
				/>
				<Input
					className={'w-full gap-3'}
					label={'Last Name'}
					type='text'
					name='lastName'
					value={userData.lastName}
					onChange={handleChange}
				/>
				<Input
					className={'w-full gap-3'}
					label={'Email'}
					type='text'
					name='email'
					value={userData.email}
					onChange={handleChange}
				/>
				<div className={'flex align-middle justify-center center mt-3'}>
					<button
						type='submit'
						className={` w-full max-w-2xl button t-white button-large br-quarter hover:grey900 f-uppercase f-900 ${
							loading || !isChanged ? 'opacity-50 cursor-not-allowed' : 'hover:bg-button-hover-light dark:hover:bg-button-hover-dark'
						} bg-gradient-to-r from-brightLilac-default to-indigo-default`}
						disabled={loading || !isChanged}
					>
						{loading ? 'Updating Admin...' : 'Update Admin'}
					</button>
				</div>
				{error ? <div className={'text-center text-red-600 mt-3'}>{error.message}</div> : null}
			</div>
		</form>
	</div>
	;
};

AdminProfileRender.propTypes = {
	userData: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		profilePicture: PropTypes.shape({
			url: PropTypes.string.isRequired,
		}),
	}).isRequired,
	handleChange: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	preview: PropTypes.string,
	loading: PropTypes.bool.isRequired,
	isChanged: PropTypes.bool.isRequired,
	error: PropTypes.any.isRequired,
};