import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {Question} from '../../../pages/LessonsEdit/components';
import {useMutation} from '@apollo/client';
import {mutations} from '../../../actions/mutations';
import { MdDragIndicator } from 'react-icons/md';
export const DraggableQuestions =({questionPoints,questions, droppableId='questions' ,onUpdateQuestion,onDeleteQuestion,onResortQuestions})=>{

	const [ questionUpdate, {loading:questionLoading} ] = useMutation(mutations.questionsUpdateOne, {
		refetchQueries: ['responsesFindMany'],
		awaitRefetchQueries: true,
	});

	const handleChangeAnswersOrder = (questionId,responsesArray)=>{
		questionUpdate({
			variables: {
				id:questionId,record:{responses:responsesArray}
			}
		})
		.catch((e)=>console.log({e}));
	};

	const handleDragQuestion =(destination,source)=>onResortQuestions(source.index,destination.index);

	const handleDragResponse = (destination,source) =>{
		if (source.droppableId === destination.droppableId) {
			// const newResponsesArray=[]
			const question = questions.find(q =>q._id === source.droppableId);
			const newResponses = [...question.responses];
			const [movedResponse] =newResponses.splice(source.index,1);
			newResponses.splice(destination.index,0,movedResponse);
			console.log({oldResponses:question.responses,newResponses});
			handleChangeAnswersOrder(question._id,newResponses);
		}
	};

	const handleOnDragEnd = (result) => {
		const destination = result.destination; // {droppable?Id : string, index : number}
		const source = result.source; // {droppable?Id : string, index : number}
		if (source.droppableId === droppableId){
			handleDragQuestion(destination,source);
		}else {
			handleDragResponse(destination,source);
		}
	};

	return(
		<DragDropContext onDragEnd={handleOnDragEnd}>
			<Droppable droppableId={droppableId} >
				{(provided) => (
					<div {...provided.droppableProps} ref={provided.innerRef}>
						{questions.map((question, index) => (
							<Draggable key={question._id} draggableId={question._id} index={index}>
								{(provided) => (
									<div
										key={question._id}
										ref={provided.innerRef}
										{...provided.draggableProps}

										className='pt-3 p-1 mb-2 bg-gray-800 rounded'
									>
										<div  className={'pb-2'} {...provided.dragHandleProps}>
											<MdDragIndicator />
										</div>
										<Question
											question={question}
											points={questionPoints.find(qp=>qp.question===question._id).points}
											questionIndex={index+1}
											onDeleteQuestion={onDeleteQuestion}
											onEditQuestion={onUpdateQuestion}

										/>

										{provided.placeholder}
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
};