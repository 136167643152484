import {useState} from 'react';
import {useMutation} from '@apollo/client';
import {ReportsQueries} from '../actions/reports.queries';
import {ReportsMutations} from '../actions/reports.mutations';
import {ReportsFormRender} from './Reports.render';
import PropTypes from 'prop-types';

export const ReportsFormLoad = ({onSuccess, currentReport, refetch}) => {
	const defaultValues = {
		body: currentReport?.body || '',
		scale: currentReport?.scale || 0,
		deleted: false,
	};
	const [formState, setFormState] = useState(defaultValues);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const [ createReports, { loading: createLoading } ] = useMutation(ReportsMutations('reportsCreateOne'),{
		refetchQueries: [{ query: ReportsQueries('ReportsFindMany') }],
		awaitRefetchQueries: true,
	});

	const [ updateReports, { loading: updateLoading } ] = useMutation(ReportsMutations('reportsUpdateOne'),{
		refetchQueries: [{ query: ReportsQueries('ReportsFindMany') }],
		awaitRefetchQueries: true,
	});

	const [ deleteReports, { loading: deleteLoading } ] = useMutation(ReportsMutations('reportsDeleteOne'),{
		refetchQueries: [{ query: ReportsQueries('ReportsFindMany') }],
		awaitRefetchQueries: true,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormState((prev) => ({
			...prev,
			[name]: name === 'scale' && !isNaN(value) ? parseFloat(value) : value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { name, ...filteredFormState } = formState;
			if (formState.scale < 0 || formState.scale > 6) {
				console.error('Scale must be between 0 and 6');
				return;
			}
			if (!!currentReport) {
				await updateReports({
					variables: {
						_id: currentReport._id,
						record: filteredFormState,
					},
				});
			} else {
				await createReports({ variables: { record: filteredFormState } });
			}
			await refetch();
			onSuccess();
		} catch (error) {
			console.error('Error creating/updating a Reports:', error);
		}
	};

	const handleDelete = async () => {
		try {
			await deleteReports({ variables: { reports: currentReport._id } });
			setIsDeleteModalOpen(false);
			onSuccess();
		} catch (error) {
			console.error('Error deleting report:', error);
		}
	};

	return (
		<ReportsFormRender
			isEdit={!!currentReport}
			isLoading={createLoading || updateLoading || deleteLoading}
			formState={formState}
			handleChange={handleChange}
			handleSubmit={handleSubmit}
			handleDelete={handleDelete}
			isDeleteModalOpen={isDeleteModalOpen}
			setIsDeleteModalOpen={setIsDeleteModalOpen}
		/>
	);
};

ReportsFormLoad.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	currentReport: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		body: PropTypes.string,
		scale: PropTypes.number,
		deleted: PropTypes.bool,
		createdAt: PropTypes.string,
		updatedAt: PropTypes.string,
	}),
	refetch: PropTypes.func.isRequired,
};