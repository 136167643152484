import React from 'react';
import {
	ClassesFindOneByCheckingQuestionId,
	GroupFindOneById,
	UserFindOneByIdOnlyName, CheckInQuestionFindOne, FindManagerByGroupId
} from '../../hoc';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {SelectDropDown} from '../../components';
import {ArrowIndicatorIcon} from '../../icons';
import {
	StyledTableRow,
	UserCounterTitleTable,
	UserPageWrapperElements,
	UserTableMainWrapper
} from '../Users/Users.style';

export const CheckInRender = ({ checkIns, skip, limit, setLimit, onNextPage, onPreviousPage, allCheckIns, sortByDate,
	setSortByDate }) => {

	return (
		<div className='w-full h-full m-2'>
			<div className='w-full flex justify-between items-center p-1 mb-3 gap-6'>
				<h1 className='font-sans text-white f-500 text-2xl'>Check-Ins</h1>
			</div>
			<div className={'flex justify-between items-center mb-6 p-2.5 pr-4 pl-4'}>
				<div className={'flex direction-column text-text-light dark:text-text-dark m-2 text-base font-medium'}>
					<span className={'font-sans mb-1'}>Sort by Date</span>
					<select
						className={'font-sans f-500 border rounded ml-4 p-2'}
						style={{
							backgroundColor: 'rgba(10, 19, 48, 1)',
							border: '1px solid rgba(11, 23, 57, 1)',
						}}
						value={sortByDate}
						onChange={(e) => setSortByDate(e.target.value)}
					>
						<option value='asc'>Ascending</option>
						<option value='desc'>Descending</option>
					</select>
				</div>
				<div className='font-mono f-500 text-sm text-white mb-3'>
					<span><strong
						style={{color: 'rgba(203, 60, 255, 1)'}}>{skip + 1} - {Math.min(skip + limit, checkIns.length) + skip}</strong></span>
					<span> of {allCheckIns}</span>
				</div>
			</div>
			<UserPageWrapperElements>
				{checkIns.length > 0 ? (
					<UserTableMainWrapper className='mt rounded-lg p-2.5'>
						<thead>
							<tr>
								<th className='left font-mono main-color-background p-2 t-white'>Question</th>
								<th className='left font-mono main-color-background p-2 t-white'>Answer</th>
								<th className='left font-mono main-color-background p-2 t-white'>Student</th>
								<th className='left font-mono main-color-background p-2 t-white'>Teacher</th>
								<th className='left font-mono main-color-background p-2 t-white'>Group</th>
								<th className='left font-mono main-color-background p-2 t-white'>Class</th>
								<th className='left font-mono main-color-background p-2 t-white'>Created At</th>
							</tr>
						</thead>
						<tbody>
							{checkIns.map((checkIn) => (
								<StyledTableRow key={checkIn._id}>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											<CheckInQuestionFindOne _id={checkIn.checkinQuestion}/>
										</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span
											className='font-mono text-text-light dark:text-text-dark text-xs'>{checkIn.answer || 'No answer set'}</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span
											className='font-mono text-text-light dark:text-text-dark text-xs'><UserFindOneByIdOnlyName
												id={checkIn?.user}/></span>
									</td>
									{/* Teacher / Manager */}
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											<FindManagerByGroupId groupId={checkIn.group}/>
										</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											{checkIn.group ? (
												<GroupFindOneById _id={checkIn.group}/>
											) : 'Group not found'}
										</span>
									</td>
									<td className='justify-start items-center text-start p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											<ClassesFindOneByCheckingQuestionId checkingQuestionId={checkIn?.checkinQuestion}/>
										</span>
									</td>
									{/* Created At */}
									<td className='justify-start items-center text-center max-w-3.5 p-2'>
										<span className='font-mono text-text-light dark:text-text-dark text-xs'>
											{checkIn.createdAt ? format(new Date(checkIn.createdAt), 'dd/MM/yyyy') : 'Date not set'}
										</span>
									</td>
								</StyledTableRow>
							))}
						</tbody>
					</UserTableMainWrapper>
				) : (
					<div className='flex justify-center items-center text-white font-mono text-lg p-4'>
						No Check-Ins Found.
					</div>
				)}
			</UserPageWrapperElements>
			<div className='flex justify-between items-center mb-6 mt-6 p-2.5 pr-4 pl-4'>
				<UserCounterTitleTable className='font-mono'>
					<span>{skip + 1} - {Math.min(skip + limit, checkIns.length) + skip}</span>
					<span> of {allCheckIns}</span>
				</UserCounterTitleTable>
				<SelectDropDown minValue={limit} onChange={setLimit}/>
				<div className='flex items-center gap-1'>
					<div className='cursor-pointer rounded-lg' onClick={onPreviousPage}>
						<ArrowIndicatorIcon className='transform rotate-180'/>
					</div>
					<div className='ml-4 cursor-pointer rounded-lg' onClick={onNextPage}>
						<ArrowIndicatorIcon/>
					</div>
				</div>
			</div>
		</div>
	);
};

CheckInRender.propTypes = {
	checkIns: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			checkinQuestion: PropTypes.string,
			answer: PropTypes.string,
			user: PropTypes.string,
			group: PropTypes.string,
			scale: PropTypes.string,
			class: PropTypes.string,
			createdAt: PropTypes.string.isRequired,
			updatedAt: PropTypes.string.isRequired,
			deletedAt: PropTypes.string,
		})
	).isRequired,
	skip: PropTypes.number.isRequired,
	limit: PropTypes.number,
	setLimit: PropTypes.func.isRequired,
	onNextPage: PropTypes.func.isRequired,
	onPreviousPage: PropTypes.func.isRequired,
	totalPages: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	allCheckIns: PropTypes.number.isRequired,
	sortByDate: PropTypes.string.isRequired,
	setSortByDate: PropTypes.func.isRequired
};