import {MultiRange} from '../index';
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import videoTapStyles from  './VideoTap.module.css';
import {secondsToMinutes} from '../../tools/transform';
import { FaPlay, FaStop } from 'react-icons/fa';


// value = {start: number, end :number}
export const VideoTap = ({video, value, onChange})=>{
	const [isPlaying,setIsPlaying] = useState(false);
	const [duration,setDuration] = useState(0);
	const [played, setPlayed] = useState(0);

	const [tapInterval,setTapInterVal]= useState({start:value?.start||0,end:value?.end||0});

	useEffect(() => {
		setTapInterVal({
			start: value.start,
			end: value.end,
		});
	}, [value]);

	const videoPlayerRef = useRef(null);
	const handlePlay = ()=> setIsPlaying(prev=>!prev);

	const handleProgressChange = ({target})=>{
		videoPlayerRef.current.seekTo(target.value);
	};

	// value =[number,number]
	const handleRangeChange = (value)=>{
		setTapInterVal({
			start: value[0],
			end : value[1],
		});
		onChange && onChange('start',value[0]);
		onChange && onChange('end',value[1]);
	};
	return(
		<div className={'mb-16'}>
			<div className={'relative'}>
				<ReactPlayer
					url={video.url}
					playing={isPlaying}
					ref={videoPlayerRef}
					onDuration={(d)=>setDuration(d)}
					onProgress={(p)=>setPlayed(p.playedSeconds)}
				/>
				<div className={'absolute top-0 flex justify-center items-center w-full h-full'}>
				    <button
						className={'text-4xl flex justify-center items-center w-[10%] aspect-square rounded-full  opacity-80 bg-cloudyBlue-light'}
						onClick={handlePlay}
					>
						{!isPlaying? <FaPlay/> : <FaStop/>}
					</button>
				</div>
			</div>
			<div className={'flex justify-between'}>
				<p> {secondsToMinutes(played)} </p>
				<p> {secondsToMinutes(duration)||'--:--'} </p>
			</div>
			<div className={'relative w-full h-[30px] rounded'}>
		
				{value ?
					<MultiRange
						className={'absolute'}
						height={'100%'}
						value={[tapInterval.start,tapInterval.end]}
						min={0}
						max={duration}
						onChange={({value})=>handleRangeChange(value)}
						onLabel={secondsToMinutes}
					/>
					:null
				}
				{video?
					<input
						className={`w-full absolute top-[10px] ${videoTapStyles.progress}`}
						type={'range'}
						min={0}
						max={duration}
						value={played}
						onChange={handleProgressChange}
					/>
					:null
				}

			</div>
		</div>
	);
    
};

VideoTap.propTypes = {
	videoTap: PropTypes.shape({
		start:PropTypes.number,
		end:PropTypes.number,
	}),
	video:PropTypes.shape({
		url:PropTypes.string.isRequired,
		type:PropTypes.string.isRequired,
		thumbnail:PropTypes.shape({
			url:PropTypes.string.isRequired,
			type:PropTypes.string.isRequired,
		}).isRequired,
	}),

	onChange:PropTypes.func.isRequired,
};