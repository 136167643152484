import {ReportsQueries} from './actions/reports.queries';
import {useQuery} from '@apollo/client';
import {ReportsRender} from './Reports.render';
import {Loader} from '../../components';
import React, {useEffect, useState} from 'react';

export const ReportsLoad = () => {
	const [limit, setLimit] = useState(100);
	const [skip, setSkip] = useState(0);
	const [sort, setSort] = useState('DATE_DESC');
	const [allElements, setAllElements] = useState([]);
	const [showUpdateReportForm, setShowUpdateReportForm] = useState(false);
	const [showCreateReportForm, setShowCreateReportForm] = useState(false);
	const [currentReport, setCurrentReport] = useState(null);

	const { loading, error, data, refetch } = useQuery(ReportsQueries('reportsFindMany'),{
		variables: {
			filter: { deleted: false },
			skip: 0,
			limit: 8000
		},
		nextFetchPolicy: 'network-only'
	});

	const sortElements = (elements, sort) => {
		return [...elements].sort((a, b) => {
			const dateA = a.createdAt || a.updatedAt ? new Date(a.createdAt || a.updatedAt).getTime() : null;
			const dateB = b.createdAt || b.updatedAt ? new Date(b.createdAt || b.updatedAt).getTime() : null;

			if (dateA === null && dateB === null) return 0;
			if (dateA === null) return 1;
			if (dateB === null) return -1;

			return sort === 'DATE_ASC' ? dateA - dateB : dateB - dateA;
		});
	};

	useEffect(() => {
		if (data) {
			const initialData = data?.ReportsFindMany || [];
			const sortedData = sortElements([...initialData], sort);
			setAllElements(sortedData);
		}
	}, [data]);

	useEffect(() => {
		const sortedData = sortElements([...allElements], sort);
		setAllElements(sortedData);
	}, [sort]);


	const paginatedData = allElements.slice(skip, skip + limit);
	const currentPage = skip / limit + 1;
	const totalPages = Math.ceil(allElements.length / limit);

	const handleNextPage = () => {
		if ((skip + limit) < allElements.length) {
			setSkip(skip + limit);
		}
	};

	const handlePreviousPage = () => {
		if (skip > 0) {
			setSkip(skip - limit);
		}
	};

	const handleLimitChange = (newLimit) => {
		setLimit(newLimit);
		setSkip(0);
	};

	const handleCreateClick = () => {
		setShowCreateReportForm(true);
	};

	const handleEditClick = (report) => {
		setCurrentReport(report);
		setShowUpdateReportForm(true);
	};

	return (
		<>
			<ReportsRender
				reportsData={paginatedData}
				totalReports={allElements.length}
			    skip={skip}
				limit={limit}
				setLimit={setLimit}
				sort={sort}
				setSort={setSort}
				handleCreateClick={handleCreateClick}
				handleEditClick={handleEditClick}
				showCreateReportForm={showCreateReportForm}
				setShowCreateReportForm={setShowCreateReportForm}
				showUpdateReportForm={showUpdateReportForm}
				setShowUpdateReportForm={setShowUpdateReportForm}
				currentReport={currentReport}
			 	onNextPage={handleNextPage}
			 	onPreviousPage={handlePreviousPage}
				onLimitChange={handleLimitChange}
				currentPage={currentPage}
				totalPages={totalPages}
				refetch={refetch}
			/>
			{loading && (
				<div className={'font-mona f-600 text-white p-3'}>
					<Loader />
				</div>
			)}
			{error && (
				<p className={'font-mona f-600 text-center text-white p-3'}>
					{error?.message}
				</p>
			)}
		</>
	);
};